import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'IsTonnageExcludedFromOption'
})

export class IsTonnageExcludedFromOption implements PipeTransform {

  transform(optionId, tonnage, discount): boolean {

    if (!discount || !discount?.compatibleOptionTonnages?.length || !tonnage) return false
    
    const targetOption = discount.compatibleOptionTonnages.find(option => option.id == optionId)
    const isInList = targetOption?.excludedTonnages.indexOf(tonnage)

    if(isInList >= 0) {
      return true
    } else {
      return false
    }
  }

}
