import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueToggleService {

  public isQueueOpen$: BehaviorSubject<boolean>;

  constructor() {
    this.isQueueOpen$ = new BehaviorSubject(true);
  }

  public toggleQueueState(state?: boolean) {
    if(!this.isQueueOpen$.value) {
      this.isQueueOpen$.next(!this.isQueueOpen$.value)
    } else {
      this.isQueueOpen$.next(state);
    }
  }

  public closeQueueIfMobile() {
    if (window.innerWidth <= 800) {
      this.isQueueOpen$.next(false)
    }
  }

}
