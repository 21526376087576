import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-selected-system',
  templateUrl: './selected-system.component.html',
  styleUrls: ['./selected-system.component.scss']
})
export class SelectedSystemComponent implements OnInit {

  @Input() system;

  constructor() { }

  ngOnInit(): void {
  }

}
