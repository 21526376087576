import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection } from "@angular/fire/firestore";
import { Observable, Subscription, of } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { tap , shareReplay } from "rxjs/operators";

export interface Settings {
  brands: [],
  types: []
}

export interface Enhancement {
  name: string;
  price: number;
  description? : string
  image? : string
}

@Injectable({
  providedIn: 'root'
})
export class ProductsService implements OnDestroy {
  productsSub: Subscription;
  productsCollection: AngularFirestoreCollection<Settings>;
  products$: Observable<any>;
  brands$: Observable<any[]>;
  // brandsAlphabetical$: Observable<any[]>;
  types$: Observable<any[]>;
  enhancements$: Observable<Enhancement[]>;

  availableTonnages = [0.75,1,1.25,1.5,2,2.5,3,3.5,4,4.5,5,6,6.5,7,7.5,10];

  constructor(
    public database: FirestoreService
  ) {

    this.products$ = this.database.doc$('products/settings').pipe(
      tap(), shareReplay({bufferSize: 1, refCount: true})
    );


    this.productsSub = this.products$.subscribe(
      settings => (
        this.brands$ = of(settings.brands),
        this.types$ = of(settings.types),
        this.enhancements$ = of(settings.enhancements)
        // this.brandsAlphabetical$ = of(settings.brands.sort())
      )
    )

  }

  ngOnDestroy() {
    this.productsSub.unsubscribe()
  }

}
