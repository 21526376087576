import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobsService } from '@shared/services/jobs.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-cutsheet-view',
  templateUrl: './cutsheet-view.component.html',
  styleUrls: ['./cutsheet-view.component.scss'],
  host: {
    class: 'page-content-main'
  }
})
export class CutsheetViewComponent implements OnInit {

  job$: Observable<any>;
  routeSub: Subscription;
  currentJobId: string;
  
  constructor(
    private route: ActivatedRoute,
    private jobsService : JobsService
  ) { }

  ngOnInit(): void {
      this.routeSub = this.route.params.subscribe(params => {
        console.log(params)

       if (params.id != this.currentJobId) {
         this.job$ = this.jobsService.watchJob(params.id);
       }
      }
    )
  }

  trackByFn(index, item) { return index; }

}
