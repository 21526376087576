import { take } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { shareReplay } from 'rxjs/operators';
import { ProductsService } from '@shared/services/products.service';

@Component({
  selector: 'app-system-proposal-modal',
  templateUrl: './system-proposal-modal.component.html',
  styleUrls: ['./system-proposal-modal.component.scss']
})
export class SystemProposalModalComponent implements OnInit {

  @Input() job;
  @Output() customSystemProposalDone = new EventEmitter<Boolean>();

  systemProposalForm: FormGroup;
  proposalGoodForDays : number;
  accounting;

  loading: boolean = true;

  c : number;
  h : number;
  p : number;

  constructor(
    private formBuilder : FormBuilder,
    public adminPricingService : AdminPricingService,
    public productsService : ProductsService
    
  ) { 

      this.adminPricingService.accounting$.pipe(
        take(1),
        shareReplay({bufferSize: 1, refCount: true})
      ).subscribe( data => {
        this.proposalGoodForDays = data.proposalGoodForDays;
        this.accounting = {
          feeModel : data.creditFeeSetting.applyAs,
          creditFee : data.creditPriceAdminCosts,
          sharedFee : data.creditFeeSetting.percentage,
          salesTax : data.proposalSalesTax
        };
        this.createForm()
        this.loading = false;
      })

  }

  createForm() {
        const date = new Date();

     this.systemProposalForm = this.formBuilder.group({
      meta : {
        accounting : this.accounting,
        createdAt : Date.now(),
        expiresAt: date.setDate(date.getDate() + this.proposalGoodForDays),
        proposalGoodForDays : this.proposalGoodForDays,
        template: '0000',
        // owner: job?.meta?.createdBy,
        // job: job?.meta?.id,
        // accounting: this.accounting
      },
      brands: this.formBuilder.array([
        this.formBuilder.group( {
          brand : null,
          formattedOptions: this.formBuilder.array([
            this.formBuilder.group({
              option : 'custom',
              label : null,
              brand : null,
              indoorUnit : null,
              indoorUnitBrochure : null,
              outdoorUnit : null,
              outdoorUnitBrochure : null,
              thermostat : null,
              thermostatBrochure : null,
              cashPrice : null,
              tonnage : null,
              warranty : null,
              seer : null,
              features: this.formBuilder.array([])
            })
          ]),
          positions: this.formBuilder.array([
            'custom'
          ])
        })
      ]),
      enhancements: this.formBuilder.array([])
    })
  }

  ngOnInit(): void {
    if(this.job?.template == '0000') {
      if (this.job?.psis?.brands[0]?.formattedOptions[0]?.features.length > 0) {
        this.job?.psis?.brands[0]?.formattedOptions[0]?.features.forEach(field => {
          this.addFeature()
        })
        this.systemProposalForm.patchValue(this.job.psis)
      } else {
        this.systemProposalForm.patchValue(this.job.psis)
      }
    }
  }

  get option() {
    return <FormGroup>this.systemProposalForm.get("brands.0.formattedOptions.0");
  }

  get features() {
    return <FormArray>this.systemProposalForm.get("brands.0.formattedOptions.0.features");
  }

  async getSetAddOnPrice() {
    const target = this.option as FormGroup;
    const recommendedPrice = await this.adminPricingService.getRecommendedItemPrice(this.c,this.h,this.p)
    target.patchValue( {
      cashPrice: recommendedPrice,
    })
  }

  addFeature() {
    const target = <FormArray>this.systemProposalForm.get("brands.0.formattedOptions.0.features");
    target.push(this.formBuilder.control("", Validators.required));
  }

  removeFeature(i) {
    const target = <FormArray>this.systemProposalForm.get("brands.0.formattedOptions.0.features");
    target.removeAt(i);
  }

  done() {
    this.customSystemProposalDone.emit(this.systemProposalForm.value)
  }

  cancel() {
    this.customSystemProposalDone.emit()
  }

}
