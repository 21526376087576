import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader-list',
  templateUrl: './loader-list.component.html',
  styleUrls: ['./loader-list.component.scss']
})
export class LoaderListComponent implements OnInit {

  @Input() loading: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
