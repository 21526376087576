import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';

import { BrandingService } from "@shared/services/branding.service";
import { Observable, Subscription } from "rxjs";
import { finalize , take } from 'rxjs/operators';
import { AngularFireFunctions } from "@angular/fire/functions";

@Component({
  selector: "app-branding",
  templateUrl: "./branding.component.html",
  styleUrls: ["./branding.component.scss"]
})
export class BrandingComponent implements OnInit, OnDestroy {

  brandingSub: Subscription;

  brandingForm: FormGroup;

  loading: boolean = true;
  success: boolean = false;
  previewing: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(
    private formBuilder: FormBuilder,
    private afs: AngularFirestore,
    public brandingService: BrandingService,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
  ) { }

  ngOnInit() {
    this.brandingForm = this.formBuilder.group({
      colors: this.formBuilder.group({
        primary: ["000000", Validators.required],
        primaryContrast: ["FFFFFF", Validators.required],
        secondary: ["#DDDDDD", Validators.required],
        secondaryContrast: ["#111", Validators.required]
      }),
      company: this.formBuilder.group({
        name: [ null, Validators.required],
        email: [ null, Validators.required],
        phone: [ null , Validators.required],
        adminCell: [ null , Validators.required],
        additionalCells: this.formBuilder.array([])
      }),
      images: this.formBuilder.group({
        email: ["", Validators.required]
      }),
      messages: this.formBuilder.group({
        thanks: ["", Validators.required],
        proposalDisclaimer: ["", Validators.required],
        unsigned: ["", Validators.required]
      })
    });

    this.brandingSub = this.brandingService.branding$.subscribe(data =>
      {
        this.brandingForm.patchValue(data);

        if (data?.company?.additionalCells?.length) {
          // If there are additional
          this.additionalCells.clear(),
          data.company?.additionalCells.map( cell => {
            this.additionalCells.push( new FormControl(cell))
          })
        }

        this.loading = false
      }
    );

  }

  ngOnDestroy() {
    this.brandingSub.unsubscribe()
  }

  patchStoredColors() {
    this.brandingForm.get("colors").patchValue(this.brandingService.colors)
  }

  toggleIsPreviewing() {
    this.previewing = !this.previewing;
  }

  onFileSelected(event) {
    const file = event.target.files[0];
    if (file) {
      this.uploadLogo(file)
    }
  }

  resetUploadLogo() {
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadURL = null;
  }

  uploadLogo(file: File) {
    const path = 'branding/logo';
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.brandingForm.patchValue(
          {
            images: {
              email: this.downloadURL
            }
          }
        )
        this.brandingForm.updateValueAndValidity()
        this.brandingForm.markAsDirty();
        this.submitHandler();
      }),
    );
  }

   get additionalCells() {
    return this.brandingForm.get("company.additionalCells") as FormArray;
  }

    addCell() {
    this.additionalCells.push(
      new FormControl( null, Validators.required )
    )
  }

  deleteCell(i:number) {
    this.additionalCells.removeAt(i)
    this.additionalCells.markAsDirty()
  }

  onSubmit() {
    this.submitHandler();
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.brandingForm.value;
    try {
      await this.afs
        .collection("admin")
        .doc("branding")
        .update(formValue);
      this.success = true;
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
    this.brandingForm.markAsPristine();
    this.resetUploadLogo()
  }
}
