import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'option-savings-amount',
  templateUrl: './option-savings.component.html',
  styleUrls: ['./option-savings.component.scss']
})
export class OptionSavingsComponent implements OnInit {

  @Input() seer;
  savingsPercentage: number;

  constructor() {}

  ngOnInit(): void {
   this.savingsPercentage = this.getSavingsAsPercentage();
  }

  getSavingsAsPercentage() {
    const seer = this.seer;
    const number = 10 / parseFloat(seer) - 1;
    const percentage = (number * -100).toFixed(2)
    return parseFloat(percentage);
  }

}
