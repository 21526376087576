import { NgModule } from '@angular/core';
import { PhoneMaskDirective } from './phone-mask.directive';

@NgModule({
  declarations:[
    PhoneMaskDirective
  ],
  imports:[],
  exports:[
    PhoneMaskDirective
  ],
  providers: [
    PhoneMaskDirective
  ]
})

export class DirectivesModule { }
