import { Injectable, OnDestroy  } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { FirestoreService } from '@shared/services/firestore.service';

export interface User {
  displayName: string;
  email: string;
  firstName: string;
  isOnline: boolean;
  lastName: string;
  phone: string;
  photoUrl: string;
  tier: string;
  title: string;
  uid: string;
}

@Injectable({
  providedIn: 'root'
})


export class UserService implements OnDestroy {
  usersSub: Subscription;

  users$: Observable<User[]>;
  users: User[];
  userNames$ = new BehaviorSubject({});

  constructor(
    private database : FirestoreService,
  ) {
    this.users$ = this.database.col$('users');
    this.usersSub = this.users$.subscribe(
      users => {
        this.users = users;
        users.forEach((user : User) => {
          this.userNames$.next({...this.userNames$.value, [user.uid] : user?.displayName ? user.displayName : user.firstName })
        })
      }
    ),
    shareReplay({bufferSize: 1, refCount: true})

  }

  ngOnDestroy() {
    this.usersSub.unsubscribe()
  }

}
