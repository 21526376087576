import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-kpis',
  templateUrl: './kpis.component.html',
  styleUrls: ['./kpis.component.scss'],
  host: {
    class: ''
  }
})
export class KpisComponent implements OnInit {

  @Input() jobs;
  @Input() numberSold: number;
  @Input() numberLost: number;
  @Input() numberPending: number;
  @Input() soldJobs: any[] = []

  totalSales
  totalEnhancementsSold
  averageTimeToClose

  constructor(
    public adminPricingService : AdminPricingService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    const jobs = changes['jobs']?.currentValue
    if (jobs != changes['jobs']?.previousValue) {
      this.totalEnhancementsSold = this.getEnhancementsTotal()
      this.totalSales = this.getJobsTotal()
      this.averageTimeToClose = this.getAverageTimeToClose()
    }
    
  }

  ngOnInit(): void {
  }

  getAverageTimeToClose() {
    const processedGroups = new Set();
    let totalTimespan = 0;
    let groupCount = 0;

    if (this.soldJobs.length === 0) return 0;

    this.soldJobs.forEach(job => {
      const { jobsGroupId } = job?.meta || {};

      if (jobsGroupId && processedGroups.has(jobsGroupId)) {
        return; // Skip if group already processed
      }

      processedGroups.add(jobsGroupId);
      groupCount++;

      const createdTime = new Date(job.meta.created).valueOf();
      const signedTime = job.confirmation.signedAt ? new Date(job.confirmation.signedAt).valueOf() : null;
      if (signedTime) {
        const timespan = signedTime - createdTime;
        totalTimespan += timespan;
      }
    });

    // Calculate the average timespan in milliseconds
    const averageTimespan = totalTimespan / groupCount;

    // Convert the average timespan from milliseconds to days (optional)
    const averageTimespanInDays = averageTimespan / (1000 * 60 * 60 * 24);
    return averageTimespanInDays;
  }

  getJobsTotal() {
    const processedGroups = new Set();
    const total = this.soldJobs.reduce((total, job) => {
      if (!job?.confirmation) {
        return total;
      }

      const { jobsGroupId } = job?.meta || {};
      if (jobsGroupId && processedGroups.has(jobsGroupId)) {
        return total; // Skip if group already processed
      }

      if (job?.confirmation?.prices?.total) {
        total += job?.confirmation?.prices?.total;
        processedGroups.add(jobsGroupId); // Mark group as processed
      }

      return total;
    }, 0);
    return total;
  }

  getEnhancementsTotal() {
    const processedGroups = new Set();
    const totalEnhancements = this.soldJobs.reduce((total, job) => {
      if (!job?.confirmation) {
        return total;
      }

      const { jobsGroupId } = job?.meta || {};
      if (jobsGroupId && processedGroups.has(jobsGroupId)) {
        return total; // Skip if group already processed
      }

      if (job?.confirmation?.prices?.e) {
        total += job?.confirmation?.prices?.e;
        processedGroups.add(jobsGroupId); // Mark group as processed
      }

      return total;
    }, 0);
    return totalEnhancements;
  }

}
