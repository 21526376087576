import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-grid-cell',
  templateUrl: './checkbox-grid-cell.component.html',
  styleUrls: ['./checkbox-grid-cell.component.scss']
})
export class CheckboxGridCellComponent implements OnInit {


  @Input() value;

  constructor() { 
  }

  ngOnInit(): void {
  }

}
