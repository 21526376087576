import { Component, Input } from '@angular/core';
import { ProposalsService, Proposal } from "@shared/services/proposals.service";

@Component({
  selector: 'app-proposal-condensed-expandable',
  templateUrl: './proposal-condensed-expandable.component.html',
  styleUrls: ['./proposal-condensed-expandable.component.scss']
})
export class ProposalCondensedExpandableComponent {
  @Input() proposal : Proposal;

  constructor(
    public proposalsService : ProposalsService
  ) {}

}
