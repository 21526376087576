import { Component, OnInit, Input,OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-proposal-preview',
  templateUrl: './proposal-preview.component.html',
  styleUrls: ['./proposal-preview.component.scss']
})
export class ProposalPreviewComponent implements OnInit, OnChanges {
  @Input() proposal;
  imageUrl : string;

  constructor() { 
  }

  ngOnInit(): void {
    this.imageUrl = './assets/logos/' + this.proposal.description.brand.replace(/\s/g, "").toLowerCase() + '.png'
  }

   ngOnChanges(changes: SimpleChanges) {
    this.imageUrl = './assets/logos/' + this.proposal.description.brand.replace(/\s/g, "").toLowerCase() + '.png'
  }

}
