import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'addTax'
})
export class AddTaxPipe implements PipeTransform {

  // @TODO - Remove this?

  constructor( private adminPricingService: AdminPricingService ) { }

  transform(number : number): number {
    return number * (1 + this.adminPricingService.taxRate / 100);
  }

}
