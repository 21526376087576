import { Component , OnDestroy, OnInit}  from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { RebatesService } from "@shared/services/rebates.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { QueueToggleService } from '@shared/services/queue-toggle.service';

@Component({
  selector: "app-promotions",
  templateUrl: "./promotions.component.html",
  styleUrls: ["./promotions.component.scss"]
})

export class PromotionsComponent implements OnInit, OnDestroy {

  todaysDate;

  rebatesSub: Subscription;

  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;
  confirm: boolean = false;

  promotionsForm : FormGroup;
  activePromo : number;

  isAddingNewPromo: boolean = false;
  isShowDateRange: boolean = false;

  constructor(
    private fb: FormBuilder,
    public rebatesService: RebatesService,
    public queueToggleService: QueueToggleService,
    private afs: AngularFirestore,
  ) {
    this.todaysDate = new Date();
    this.promotionsForm = this.fb.group({
      promotions: this.fb.array([])
    })
    this.getPromotions()
  }

  ngOnDestroy() {
    this.rebatesSub.unsubscribe()
  }

  ngOnInit(): void {
    this.setActivePromo(0)
  }

  getPromotions() {
    this.rebatesSub = this.rebatesService.promos$.pipe(take(1)).subscribe(
      promos => (
        this.promotions.clear(),
        promos.forEach( promotion =>
          {
            this.promotions.push(
              new FormGroup({
                amount: new FormControl( promotion['amount'] ),
                description: new FormControl( promotion['description'] ),
                expirationDate: new FormControl( promotion['expirationDate']),
                enabledDate: new FormControl( promotion['enabledDate'] ),
                link: new FormControl( promotion['link'] ),
                name: new FormControl( promotion['name'] ),
                value: new FormControl( promotion['value'] ),
                isPercentage: new FormControl( promotion['isPercentage'] ),
                isOptional: new FormControl( promotion['isOptional'] != null ? promotion['isOptional'] : false ),
                isRebate: new FormControl( promotion['isRebate'] != null ? promotion['isRebate'] : false ),
                isDateRange: new FormControl( promotion['isDateRange'] != null ? promotion['isDateRange'] : true ),
                // isNeverExpires: new FormControl( promotion['isNeverExpires'] != null ? promotion['isNeverExpires'] : true ),
              })
            )
          }
        )
      )
    )
  }

  setActivePromo(index:number) {
    const promo = this.promotionsForm?.get('promotions.' + index).value
    this.activePromo = index;
    this.isAddingNewPromo = false;
    this.queueToggleService.closeQueueIfMobile()
  }

  addPromotion() {
    this.queueToggleService.closeQueueIfMobile();
    this.isAddingNewPromo = true;
    this.toggleShowDateRange(false)
    this.activePromo = this.promotionsForm.value.promotions.length;
    this.promotions.push(
      new FormGroup({
        amount: new FormControl(null, Validators.required),
        description: new FormControl( null),
        expirationDate: new FormControl(null),
        enabledDate: new FormControl(null),
        link: new FormControl(null),
        name: new FormControl(null, Validators.required),
        value: new FormControl( null, Validators.required ),
        isPercentage: new FormControl( false, Validators.required ),
        isOptional: new FormControl( false, Validators.required ),
        isRebate: new FormControl( false, Validators.required ),
        isDateRange: new FormControl( false ),
        // isNeverExpires: new FormControl( false ),
      })
    )
  }

  get promotions() {
    return this.promotionsForm.get("promotions") as FormArray;
  }

  autoFillValueFromAmount(i) {
    const target = this.promotionsForm.get('promotions.' + i) as FormGroup;
    const currentAmount = target.get('amount').value;
    const isValuePristine = target.get('value').pristine;

    if (isValuePristine) {
      // if it hasnt been manually touched, we autofill
      target.patchValue({
        'value' : currentAmount
      });
      target.markAsTouched()
    }

  }

  toggleShowDateRange(state?:boolean) {
    state != null ? this.isShowDateRange = state : this.isShowDateRange = !this.isShowDateRange
  }

  onHandleIsRebate(i:number) {
    const target = this.promotionsForm.get('promotions.' + i) as FormGroup;
    if (target.value.isRebate) {
      target.patchValue({
          'isPercentage' : false
      });
    }
  }

  resetDateRange(i:number) {
    const target = this.promotionsForm.get('promotions.' + i) as FormGroup;
    target.patchValue({
        'enabledDate' : null,
        'expirationDate' : null
      });
  }

  clearDateRange(i:number) {
    const target = this.promotionsForm.get('promotions.' + i) as FormGroup;
    target.patchValue({
        'enabledDate' : null,
        'expirationDate' : null
      });
  }

  onSubmit() {
    if(this.promotionsForm.valid) {
      this.submitHandler();
    } else {
      this.promotionsForm.markAsTouched()
      console.error('There is an error with one or more promotions. Please check the form and try again')
    }
  }

  confirmDelete() {
    this.confirm = true;
  }

  onCancelConfirm() {
    this.confirm = false;
  }

  deletePromotion() {
    this.promotions.removeAt(this.activePromo)
    this.confirm = false;
    this.onSubmit()
  }

  cancel() {
    if(this.isAddingNewPromo) {
      this.promotions.removeAt(this.activePromo);
    }
    this.promotionsForm.reset();
    this.getPromotions();
    this.promotionsForm.markAsPristine()
  }

  async submitHandler() {
    const formValue = this.promotionsForm.value;
    this.loading = true;
    try {
      await this.afs.collection("promotions").doc("all").update(formValue);
      this.showSuccessMessage();
      this.loading = false;
    } catch (err) {
      console.log(err)
      this.error = true
    }
    this.loading = false;
    this.promotionsForm.markAsPristine();
    this.promotionsForm.markAsUntouched()
  }

  showSuccessMessage() {
    this.success = true;
    setTimeout(()=>{
      this.success = false;
    }, 3000);
  }
  


}
