import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirestoreService } from "@shared/services/firestore.service";;
import { BrandingService } from "@shared/services/branding.service";
import { finalize, tap, map, take, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-unsign',
  templateUrl: './unsign.component.html',
  styleUrls: ['./unsign.component.scss']
})
export class UnsignComponent implements OnInit, OnDestroy {

  @Input() job;
  @Input() user;
  @Input() isOpen;
  @Output() unsignedDone = new EventEmitter<Boolean>();

  brandingSub: Subscription;
  adminCell: string;

  loading: boolean = true;
  gettingData: boolean = true;
  messageType: string = "text";

  currentJobId : string;
  to: string;
  subject: string;
  cc: string;
  body: string;
  bodyPrefill: string;

  constructor(
    private database: FirestoreService,
    private functions: AngularFireFunctions,
    public brandingService : BrandingService
  ) {
    this.setUp();
  }

  ngOnInit(): void {
    this.to = this.formatPhoneNumber(this.job.info.phone)
    this.currentJobId = this.job.meta.id;
  }

  async setUp() {
    this.brandingSub = this.brandingService.branding$.subscribe(data =>
      (
        this.adminCell = data.company.adminCell,
        this.bodyPrefill = data.messages.unsigned,
        this.setMessages(),
        this.loading = false,
        this.gettingData = false
      )
    );
  }

  ngOnDestroy() {
    this.brandingSub?.unsubscribe()
  }

  formatPhoneNumber(number:string) {
    let primary = number.replace(/[-()' ']/g,"");
    return `+1${primary}`
  }

  async setMessages() {
    this.loading = true;
    await this.brandingService.unsignText;
    this.subject = 'UPDATE FROM DARTBID';
    this.body = `${this.bodyPrefill}

Previous Proposal Info:
Total: $${this.job?.price?.toFixed(2)}, ${ this.job?.depositsTotal ? 'Deposits: $' + this.job?.depositsTotal?.toFixed(2) + ',' : '' } Balance: $${this.job?.balance?.toFixed(2)}
    `
  }

  async markAsUnsigned() {
    if (this.job?.meta?.jobsGroupId) {
      await this.markAllLinkedJobsUnsigned()
      return
    } else {
      this.loading = true;
      try {
        await this.database.doc(`jobs/${this.currentJobId}`).update({
          "status": 'pending',
          "price": null,
          "balance": null,
          'confirmation': null,
          'meta.proposalUnsigned': {
            time: Date.now(),
            unsignedBy: this.user,
            meta: {
              price: this.job.price,
              balance: this.job?.balance,
              depositsTotal: this.job?.depositsTotal ? this.job.depositsTotal : null
            }
          }
        });
        this.loading = false;
      } catch (err) {
        alert(err)
      }
    }
  }

  async markAllLinkedJobsUnsigned() {
    this.database.col$('jobs', ref => ref
      .where('meta.jobsGroupId', '==', this.job?.meta?.jobsGroupId)
    ).pipe(take(1)).subscribe( async (linkedJobs : any) => {
      for await (const job of linkedJobs) {
        try {
          await this.database.doc(`jobs/${job.meta.id}`).update({
          "status": 'pending',
          "price": null,
          "balance": null,
          'confirmation': null,
          'meta.proposalUnsigned': {
            time: Date.now(),
            unsignedBy: this.user,
            meta: {
              price: this.job.price,
              balance: this.job?.balance,
              depositsTotal: this.job?.depositsTotal ? this.job.depositsTotal : null
            }
          }
        });
          // if (this.job?.confirmation?.paymentMethod == 'financing') {
          //   await this.updateFinancingAfterDeposit(this.deposit.balance);
          // }
        } catch (err) {
          console.log(err)
        }
      }
    })
  }

  cancel() {
    this.isOpen = false;
    this.unsignedDone.emit(false)
  }

  async done(unsigned:boolean=false) {
    this.loading = true;
    await this.markAsUnsigned()
    this.isOpen = false;
    this.loading = false;
    this.unsignedDone.emit(unsigned)
  }

  async unsign() {
    try {
      this.loading = true;
      await this.notifyCustomerOfUnsign()
      await this.notifyAdminOfUnsign()
      await this.done(true)
      this.loading = false;
    }
    catch (error) {
      console.log(error)
    }
  }

  async notifyCustomerOfUnsign() {
    const callable = this.functions.httpsCallable('textMessage');
    try {
      await callable(
        {
          to : this.to, //this.formatPhoneNumber(this.job.info.phone)
          body: this.body
        }
      ).toPromise()
      return true
    }
    catch (error) {
      console.log(error)
      return false
    }
  }

  async notifyAdminOfUnsign() {
    const callable = this.functions.httpsCallable('textMessage');
    this.loading = true;
    try {
      await callable(
        {
          to : this.formatPhoneNumber(this.adminCell),
          body: `UPDATE FROM DARTBID: ${this.user.firstName} unsigned proposal for '${this.job.info.firstName} ${this.job.info.lastName}'.

Previous Proposal Info:
Total: $${this.job?.price.toFixed(2)}, ${ this.job?.deposits?.length ? 'Deposits: $' + this.job?.depositsTotal.toFixed(2) + ',' : '' } Balance: $${this.job?.balance.toFixed(2)}
          `
        }
      ).toPromise()
      return true
    } catch(err) {
      console.log(`error: ${err}`)
      this.loading = false;
      return false
    }

  }

}
