import { FirestoreService } from '@shared/services/firestore.service';
import { UserService } from '@shared/services/user.service';
import { LeadsService } from '@shared/services/leads.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'app-lead-modal',
  templateUrl: './lead-modal.component.html',
  styleUrls: ['./lead-modal.component.scss'],
  host: {
    class: 'modal u-max-width--largest modal--default'
  }
})
export class LeadModalComponent implements OnInit {

  assignedConsultant : string;
  leadNotes : string;
  expectedCompletion: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public leadsService : LeadsService,
    public userService : UserService,
    public authService : AuthService,
    private database : FirestoreService
    ) { }

  ngOnInit(): void {
    this.assignedConsultant = this.data.lead.consultant;
    this.leadNotes = this.data.lead?.notes;
    this.expectedCompletion = this.data.lead?.expectedCompletion;
  }

  async updateConsultant() {
    this.updateLeadField('consultant', this.assignedConsultant)
  }

  async updateNotes() {
    this.updateLeadField('notes', this.leadNotes)
  }

  async updateExpectedCompletion() {
    if (this.expectedCompletion) {
      this.updateLeadField('expectedCompletion', this.expectedCompletion)
    }
  }

  async updateLeadField(field,newValue) {
    try {
      await this.database.col("leads").doc(this.data.lead.meta.id).update({
        [field] : newValue
      });
      return true
    } catch (err) {
      console.log(err)
    }
  }

}
