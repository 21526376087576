import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'hasDepositDiscrepancy'
})
export class DepositDiscrepancyFeesPipe implements PipeTransform {

  constructor() { }

  round(num) {
    return parseFloat(num?.toFixed(2))
  }

  transform(job : any): boolean {
    if ( 
      (this.round((job?.price - job?.depositsTotal)) != this.round(job?.balance) && job?.depositsTotal != null) || 
      (this.round(job?.price) != this.round(job?.balance)) && !job?.deposits && job.status == 'sold' ) {
        return true
    } else {
      return false
    }
  }

}
