import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { map } from "rxjs/operators";
import { shareReplay} from 'rxjs/operators';import {
  AngularFirestore
} from "@angular/fire/firestore";

export interface AddOns {
  allAddOns: AddOn[]
  utilities: AddOn[]
  ductwork: AddOn[]
  equipmentInstallation: AddOn[]
  structuralAndMisc: AddOn[]
}

export interface AddOn {
  name: string,
  price: number,
  quantity?
}

@Injectable({
  providedIn: 'root'
})
export class AddOnService {

  addOns$ : Observable<any>
  utilities$: Observable<AddOn[]>;
  ductwork$: Observable<AddOn[]>;
  equipmentInstallation$: Observable<AddOn[]>;
  structuralAndMisc$: Observable<AddOn[]>;

  constructor(
    public database: FirestoreService,
    public afs: AngularFirestore
  ) {
      this.addOns$ = this.database.doc$('addOns/all').pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );

      this.utilities$ = this.addOns$.pipe(
        map(
          data => data.utilities
        )
      );

      this.ductwork$ = this.addOns$.pipe(
        map(
          data => data.ductWork
        )
      );

      this.equipmentInstallation$ = this.addOns$.pipe(
        map(
          data => data.equipmentInstallation
        )
      );

      this.structuralAndMisc$ = this.addOns$.pipe(
        map(
          data => data.structuralAndMisc
        )
      );

  }
}
