import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui',
  templateUrl: './ui.component.html',
  styleUrls: ['./ui.component.scss']
})
export class UiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  showModalDemo: boolean = false;

  toggleModalDemo() {
    this.showModalDemo = !this.showModalDemo;
  }

}
