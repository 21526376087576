import { Component, OnInit, OnDestroy } from '@angular/core';

import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { MiscFilesService } from "@shared/services/misc-files.service";
import { tap , shareReplay, take, finalize} from "rxjs/operators";

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit, OnDestroy {
  filesSub: Subscription;

  isAddNewFile: boolean = false;

  viewing: number = 0;

  fileForm: FormGroup;
  loading: boolean = false;
  success: boolean = false;
  confirm: boolean = false;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadUrl: string;

  constructor(
    public queueToggleService: QueueToggleService,
    public filesService: MiscFilesService,
    private formBuilder: FormBuilder,
    public afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    // this.setBlankFileForm()
  }

  ngOnInit(): void {
  }

  setBlankFileForm() {
    this.fileForm = this.formBuilder.group({
      name: [ null, Validators.required],
      downloadUrl: [ null, Validators.required],
      type: [ null ],
      id: this.afs.createId(),
      uploaded: Date.now()
    })
  }

  toggleAddNewFile(boolean? : boolean) {
    this.setBlankFileForm()
    if (boolean) {
      this.isAddNewFile = boolean
    } else {
      this.isAddNewFile = !this.isAddNewFile
    }
  }

  showSuccessMessage() {
    this.success = true;
    setTimeout(()=>{
      this.success = false;
    }, 3000);
  }

  confirmDelete() {
    this.confirm = true;
  }

  onCancelConfirm() {
    this.confirm = false;
  }

  async deleteFile(i, file) {

    let fileArray;

    this.filesSub = this.filesService.sharedFiles$.pipe(take(1)).subscribe(
      files =>  {
          fileArray = files;
          fileArray.splice(i, 1);
          this.loading = true;
          try {
            this.afs
              .collection("files")
              .doc(file.id)
              .delete()
              try {
                this.afs
                  .collection("files")
                  .doc(file.id)
                  .delete()
                if (file?.type) this.storage.refFromURL(file['downloadUrl']).delete();
                this.showSuccessMessage()
                this.loading = false;
                this.setViewing(0)
              } catch (err) {
                console.log(err)
              }
            this.showSuccessMessage()
            this.loading = false;
            this.setViewing(0)
          } catch (err) {
            console.log(err)
          }

          this.loading = false;
          this.confirm = false;
      },
      shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  setViewing(i) {
    this.viewing = i;
    this.queueToggleService.closeQueueIfMobile()
  }

  resetUpload() {
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadUrl = null;
  }

  waitThenResetUpload() {
    setTimeout(() => {
      this.resetUpload()
    }, 1500);
  }

  async uploadFile(event) {
    this.resetUpload();
    const id = this.afs.createId()
    const file = event.target.files[0];
    const path = `files/${this.fileForm.value.id}/${id}`;
    const ref = this.storage.ref(path);

    if(file) {
      try {
        this.task = this.storage.upload(path, file);
        this.percentage = this.task.percentageChanges();

        this.snapshot = this.task.snapshotChanges().pipe(
          // The file's download URL
          finalize( async() =>  {
            this.downloadUrl = await ref.getDownloadURL().toPromise();
            this.fileForm.patchValue({
              downloadUrl: this.downloadUrl,
              type: file.type,
              uploaded: Date.now(),
            });
            this.fileForm.updateValueAndValidity()
            this.fileForm.markAsDirty();

            // this.onSubmit()
          }),
        );
        // this.resetUpload()
        // this.toggleAddNewFile()
      } catch (err) {
        console.log(err)
      }
    }
  }

  async onSubmit() {
    if (this.fileForm.valid)  this.saveFile()
  }

  async saveFile() {
    try {
      const formValue = this.fileForm.value;
      await this.afs
        .collection("files")
        .doc(formValue.id)
        .set(formValue);
      this.showSuccessMessage()
      this.loading = false;
      this.setBlankFileForm()
      this.fileForm.updateValueAndValidity()
      this.resetUpload();
      this.toggleAddNewFile()
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }

  ngOnDestroy() {
    if(this.filesSub) {
      this.filesSub.unsubscribe();
    }
  }

}
