import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { map } from "rxjs/operators";


@Pipe({
  name: 'getUser$'
})

export class UserFromIdPipe implements PipeTransform {

  constructor( private userService: UserService ) {
  }

  transform(uid: string) {
    return this.userService.users$.pipe(
      map( users => users.find(user => user.uid == uid) )
    )
  }

}
