import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
@Component({
  selector: 'app-job-status-totals-donut',
  templateUrl: './job-status-totals.component.html',
  styleUrls: ['./job-status-totals.component.scss'],
  
})
export class JobStatusTotalsComponent implements OnInit {
@ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input() sold;
  @Input() lost;
  @Input() pending;

 radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'left'
    }
  };
  radarChartLabels: Label[] = ['Pending', `Sold`, 'Lost'];
  radarChartData: ChartDataSets[] = [
    { data: [0, 0, 0,], label: 'Job Totals' },
  ];
  colorScheme =[
    { 
      backgroundColor:["#c5d2d6", "#6abb42","#c70d0d" ] 
    }
  ];
  radarChartType: ChartType = 'doughnut';

  constructor() { }

  ngOnInit(): void {
    this.setChartData()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sold || changes.lost || changes.pending) {
      this.setChartData();
    }
  }
  
  setChartData() {
    this.radarChartData = [{
      data: [
        this.pending,
        this.sold,
        this.lost
      ], label: 'Job Totals'
    }]

  }


}
