import { DecimalPipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-linked-jobs',
  templateUrl: './linked-jobs.component.html',
  styleUrls: ['./linked-jobs.component.scss']
})
export class LinkedJobsComponent implements OnInit , OnChanges {
  @Input() job;
  linkedJobsSub : Subscription;
  linkedJobs$ : any;
  linkedJobs : any[] = [];

  constructor(
    private database : FirestoreService, 
    private decimalPipe : DecimalPipe
  ) { }

  ngOnInit(): void {
    this.getSetLinkedJobs()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['job'].firstChange) {
      if (changes['job'].currentValue.meta.id !== changes['job'].previousValue.meta.id) {
        this.getSetLinkedJobs()
      }
    }
  }

  getSetLinkedJobs() {
    this.linkedJobs$ = this.database.col$('jobs', ref => ref
      .where('meta.jobsGroupId', '==', this.job?.meta?.jobsGroupId)
      .where('meta.jobsGroupId', '!=', null)
    ).pipe(
      tap(
        jobs => {
          this.linkedJobs = jobs
        }
      )
    )
  }

  async unlink(job) {
    try {
      await this.database.doc(`jobs/${job.meta.id}`).update({
        'meta.jobsGroupId': null
      });
    } catch (err) {
      console.log(err)
    }
  }

  async removeDepositOnAllLinkedJobs(i) {

    const job = {...this.job}
    if (!job.deposits) return

    let mergedUpdates;
    const updates = {
      "deposits": null,
      "balance" : job.price,
      "depositsTotal": null
    }

    if (job.deposits.length == 1) {
      if (job?.confirmation?.paymentMethod == 'financing') {
        await this.updateFinancingAfterRemovingDepositOnAllLinkedJobs(job.price);
        mergedUpdates = {...updates, 'confirmation.selectedFinancingDetails' : {...job.confirmation?.selectedFinancingDetails}}
      } else {
        mergedUpdates = {...updates}
      }
      for await (const linkedJob of this.linkedJobs) {
        try {
          await this.database.doc(`jobs/${linkedJob.meta.id}`).update(updates);
        } catch (err) {
          console.log(err)
        }
      }
    } else {
      // Remove the deposit and update DB
      job.deposits.splice(i,1)

      const depositsTotalAfterRemoval = job.deposits.reduce(
        (total : number, deposit) => {
          return total += deposit.adjustedAmount
        }, 0
      );
      await depositsTotalAfterRemoval;

      const balanceAfterRemovingDeposit = job.price - depositsTotalAfterRemoval;
      await balanceAfterRemovingDeposit;

      const updates = {
        "deposits": job?.deposits || null,
        "balance" : balanceAfterRemovingDeposit,
        "depositsTotal" : depositsTotalAfterRemoval
      }

      let mergedUpdates;

      if (job?.confirmation?.paymentMethod == 'financing') {
        await this.updateFinancingAfterRemovingDepositOnAllLinkedJobs(balanceAfterRemovingDeposit);
        mergedUpdates = {...updates, 'confirmation.selectedFinancingDetails' : {...job.confirmation.selectedFinancingDetails}}
      } else {
        mergedUpdates = {...updates}
      }

      for await (const linkedJob of this.linkedJobs) {
        try {
          await this.database.doc(`jobs/${linkedJob.meta.id}`).update(mergedUpdates);
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  async updateFinancingAfterRemovingDepositOnAllLinkedJobs(balanceAfterRemovingDeposit) {
    const job = {...this.job}

    let pmt;
    let fin = job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption;
    let b = balanceAfterRemovingDeposit;
    let p = balanceAfterRemovingDeposit;

    let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    if (fin.apr == 0 && !fin.min) {
      pmt = p / fin.months;
    } else if (!fin.min) {
      pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
    } else if (fin.min) {
      pmt = p * fin.min / 100;
    }

    const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
    let financePaymentTotal = pmtFormatted * fin.months;
    let financedAmount = p;

    let updatedSelectedFinancingDetails = {
      'financingBasisPrice' : b,
      'financePayment' : pmt,
      'financePaymentsTotalAtTermEnd': financePaymentTotal,
      'financedAmount': financedAmount,
      'selectedFinancingOption': {...fin}
    }

    job.confirmation.selectedFinancingDetails = updatedSelectedFinancingDetails;

  }

}
