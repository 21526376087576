import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-edit-discount',
  templateUrl: './add-edit-discount.component.html',
  styleUrls: ['./add-edit-discount.component.scss']
})
export class AddEditDiscountComponent implements OnInit {
  form: FormGroup;
  @Output() cancel = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();
  @Input() template;

  constructor(
    private formBuilder: FormBuilder
  ) {

    this.form = this.formBuilder.group({
      amount: [ null, Validators.required],
      description: [ null, Validators.required],
      reason: [ null, Validators.required],
      appliesToOptions: this.formBuilder.array([]),
      isCustomAdded: true
    });
   }

  ngOnInit(): void {
    // if (this.discount) this.form.patchValue(this.discount)
  }

  toggleSelectedDiscountAppliesToOptions(event, option) {
    const isChecked: boolean = event.target.checked;
    const appliesToOptionsArray = this.form.get('appliesToOptions') as FormArray;
    if (isChecked) {
      appliesToOptionsArray.push(new FormControl(option))
      // Add
    } else {
      // Remove
      const index = appliesToOptionsArray.value.indexOf(option);
      if (index !== -1) appliesToOptionsArray.removeAt(index);
    }
  }

  onSubmit() {
    if (this.form.valid) this.handleSave()
  }

  handleSave() {
    this.save.emit(this.form.value)
  }


  close() {
  }

  onCancel() {
    this.cancel.emit(true)
  }

}
