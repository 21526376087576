import { Subscription } from 'rxjs';
import { Component, OnInit, Output, ViewChild, EventEmitter, OnDestroy, Input } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { TemplatePricingUpdates } from '@shared/services/template-pricing-updates.service';
import { TemplatesService } from '@shared/services/templates.service';
import { map, shareReplay, take } from 'rxjs/operators';
import { FirestoreService } from '@shared/services/firestore.service';
@Component({
  selector: 'app-pricing-tool',
  templateUrl: './pricing-tool.component.html',
  styleUrls: ['./pricing-tool.component.scss']
})

export class PricingToolComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Output() pricingToolDone = new EventEmitter<Boolean>();
  @Input() templates;
  templatesForm = [];

  pricingSub: Subscription;
  templateSub: Subscription;

  accordionView: boolean = true;
  isConfirmSave: boolean = false;
  accounting;

  constructor(
    public templateService : TemplatesService,
    public templatePricingUpdateService : TemplatePricingUpdates,
    private adminPricingService : AdminPricingService,
    private firestoreService : FirestoreService
  ) { 

    this.pricingSub = this.adminPricingService.accounting$.subscribe(
      accounting => {
        this.accounting = accounting
      }
    )

    this.templateSub = this.firestoreService.col$('templates').pipe(
      take(1),
      shareReplay({bufferSize: 1, refCount: true}),
      map( 
        (templates : any) => templates.filter(template => template.status == 'active')
      )
    ).subscribe(
      templates => {
        this.templatesForm = [...templates];
        this.updateFormPrices();
      }
    );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.templatesForm = [];
    this.templateSub?.unsubscribe()
    this.pricingSub?.unsubscribe()
  }

  toggleConfirmSave() {
    this.isConfirmSave = !this.isConfirmSave
  }

  async saveUpdates() {
    await this.templatePricingUpdateService.savePricingToolChanges(this.templatesForm);
    this.pricingToolDone.emit(true)
  }

  cancel(){
    this.templatesForm = [];
    this.pricingToolDone.emit(false)
  }

  async updateFormPrices() {
    for (const template of this.templatesForm) {
      for (const brand of template.brands) {
        for (const option of brand.options) {
          for (const size of option.sizes) {
            const newPrice = await this.getUpdatedPrice(option,size);
            // const newPrice = this.adminPricingService.getRecommendedPriceOldFormula(option,size)
            size.ref = {
              recommendedCashPrice : newPrice,
              prevCashPrice : size.cashPrice,
              lastChecked: Date.now()
            }
            size.cashPrice = newPrice
          }
        }
      }
    };
  }

  async getUpdatedPrice(option,size) {
    return this.adminPricingService.getRecommendedPrice(option,size)
  }

  toggleUpdateAll(event) {
    const isChecked: boolean = event.target.checked;

    if (!isChecked) {
      this.templatesForm.forEach((template,t) => {
        this.toggleCheckboxById(`checkbox--${t}`,false)
        this.toggleUpdateTemplate(event,t)
      })
      
    } else {
      this.templatesForm.forEach((template,t) => {
        this.toggleCheckboxById(`checkbox--${t}`,true)
        this.toggleUpdateTemplate(event,t)
      })
    }
  }

  toggleUpdateTemplate(event,t) {
    const isChecked: boolean = event.target.checked;

    if (!isChecked) {
      this.templatesForm[t].brands.forEach((brand,b) => {
        this.toggleUpdateBrand(event,t,b)
      })
    } else {
      this.templatesForm[t].brands.forEach((brand,b) => {
        this.toggleUpdateBrand(event,t,b)
      })
    }
  }

  toggleUpdateBrand(event,t,b) {
    const isChecked: boolean = event.target.checked;
    if (!isChecked) {
      this.toggleCheckboxById(`checkbox--${t}--${b}`,false)
      this.templatesForm[t].brands[b].options.forEach((option,o) => {
        this.toggleCheckboxById(`checkbox--${t}--${b}--${o}`,false)
        this.toggleUpdateOption(event,t,b,o,option)
      })
    } else {
      this.toggleCheckboxById(`checkbox--${t}`,true)
      this.toggleCheckboxById(`checkbox--${t}--${b}`,true)
      this.templatesForm[t].brands[b].options.forEach((option,o) => {
      this.toggleCheckboxById(`checkbox--${t}--${b}--${o}`,true)
        this.toggleUpdateOption(event,t,b,o,option)
      })
    }
  }

  toggleUpdateOption(event,t,b,o,option) {
    const isChecked: boolean = event.target.checked;
    if (!isChecked) {
      this.templatesForm[t].brands[b].options[o].sizes.forEach((size,s) => {
        this.toggleCheckboxById(`checkbox--${t}--${b}--${o}--${s}`,false)
        size.cashPrice = size.ref.prevCashPrice
      })
    } else {
      this.templatesForm[t].brands[b].options[o].sizes.forEach((size,s) => {
        this.toggleCheckboxById(`checkbox--${t}--${b}--${o}--${s}`,true)
        size.cashPrice = size.ref.recommendedCashPrice
      })
    }
  }

  toggleUpdateSize(event,t,b,o,s,size) {
    const isChecked: boolean = event.target.checked;

    if (!isChecked) {
      size.cashPrice = size.ref.prevCashPrice
    } else {
      this.toggleCheckboxById(`checkbox--${t}`,true)
      this.toggleCheckboxById(`checkbox--${t}--${b}`,true)
      this.toggleCheckboxById(`checkbox--${t}--${b}--${o}`,true)
      this.toggleCheckboxById(`checkbox--${t}--${b}--${o}--${s}`,true)
      size.cashPrice = size.ref.recommendedCashPrice
    }
  }

  toggleCheckboxById(id:string,boolean?) {
    let target = document.getElementById(id) as HTMLInputElement | null;
    if (target) {
      target.checked = (boolean == true || boolean == false) ? boolean : !target.checked
    }
  }

  setSizeValue(value,size) {
    size.cashPrice = value
  }

  onManualUpdateCashPrice(t,b,o,s,size) {
    if (size.cashPrice == size.ref.recommendedCashPrice) return
    this.toggleCheckboxById(`checkbox--${t}`,true)
    this.toggleCheckboxById(`checkbox--${t}--${b}`,true)
    this.toggleCheckboxById(`checkbox--${t}--${b}--${o}`,true)
    this.toggleCheckboxById(`checkbox--${t}--${b}--${o}--${s}`,true);
  }

  // setSizeChangedTimestamp(t,b,o,s,size) {
  //   size.ref.updatedAt = Date.now()
  // }

}
