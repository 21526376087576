import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface ProposalPricesTotals {

  // Reference Values (not displayed in UI)
    dpad: number, // discounts total percentage 1 - (1-dp1)(1-dp2)... (1-dpn)
    totalDiscountsAsCash: number // discountedItemsTotal * dpad / (1 - dpad) + d
    totalPromosAsCash: number // (discountedItemsTotal - itemsTotal - d) - d - r
    prePercentagesDiscountedItemsTotal: number, // (itemsTotal - d)
    pretaxTotal: number // discountedItemsTotal - cfa

  // Displayed Values in UI in order

    b : number, // base price (as displayed)
    a : number, // addons/updates total (as displayed)
    e : number, // selected enhancements total (as displayed)
  //////////////////////////////////////////////////////////
  itemsTotal : number, // b + a + e
    d : number, // discounts total cash (without percentages)

  //////////////////////////////////////////////////////////
  discountedItemsTotal: number, // prePercentagesDiscountedItemsTotal * (1-dpad)
    cfa: number, // taxed total (differs slightly on surcharge model) * (creditFee / 100) 
    t: number // pretaxTotal * (taxRate / 100)

  //////////////////////////////////////////////////////////
  netTotal: number // pretaxTotal + t
    r : number, // rebates total cash
  //////////////////////////////////////////////////////////
  total: number // pretaxTotal + t - r 
}


@Injectable({
  providedIn: 'root'
})
export class ProposalChoicesService {

  public prices$: BehaviorSubject<any>;
  public currentlyAvailableRebates$: BehaviorSubject<any[]>; // Rebate total $ being applied (after tax)
  public currentlyAvailableDiscounts$: BehaviorSubject<any[]>; // Discounts total $ being applied (pre-tax)

  constructor() {
    this.prices$ = new BehaviorSubject({});
    this.currentlyAvailableDiscounts$ = new BehaviorSubject([]);
    this.currentlyAvailableRebates$ = new BehaviorSubject([]);
  }

}
