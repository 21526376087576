import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { shareReplay } from "rxjs/operators";

export interface Survey {
  questions: SurveyQuestion[]
}

export interface SurveyQuestion {
  questions: string,
  answer: string
}

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  surveyQuestions$: Observable<any>;

  constructor(
    public database: FirestoreService
  ) {
    this.surveyQuestions$ = this.database.doc$('admin/survey').pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );
  }
}
