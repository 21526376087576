import { Injectable } from '@angular/core';
import { Subscription } from "rxjs";
import { map,take } from "rxjs/operators";
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserService } from './user.service';
import { BrandingService } from './branding.service';
@Injectable({
  providedIn: 'root'
})
export class ProposalNotificationsService {

  dataSub : Subscription;

  constructor(
    private functions: AngularFireFunctions,
    private userService : UserService,
    private brandingService : BrandingService
  ) {
  }

  notifyOfSign(proposal) {
    const callable = this.functions.httpsCallable('textMessage');
    this.userService.users$.pipe(
        take(1),
        map( users => users.find(user => user.uid == proposal?.meta?.createdBy))
      ).subscribe( async consultant =>  {
      this.notifyAdminsOfProposalSign(proposal, consultant)
      if (consultant?.phone) {
        const ownerNumber = this.formatPhoneNumber(consultant.phone)
        try {
          await callable(
            {
              to : ownerNumber,
              body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
              
${proposal?.price ? 'Total: ' + proposal?.price.toFixed(2) : ''}
              `
            }
          ).toPromise()
        } catch(err) {
          console.log(`error: ${err}`)
          return false
        }
      }
    })
  }

  notifyOfSignLinked(proposal, total) {
    const callable = this.functions.httpsCallable('textMessage');
    this.userService.users$.pipe(
        take(1),
        map( users => users.find(user => user.uid == proposal?.meta?.createdBy))
      ).subscribe( async consultant =>  {
      this.notifyAdminsOfLinkedProposalSign(proposal, consultant, total)
      if (consultant?.phone) {
        const ownerNumber = this.formatPhoneNumber(consultant.phone)
        try {
          await callable(
            {
              to : ownerNumber,
              body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
              
${total ? 'Total: ' + total.toFixed(2) : ''}
              `
            }
          ).toPromise()
        } catch(err) {
          console.log(`error: ${err}`)
          return false
        }
      }
    })
  }

  notifyAdminsOfProposalSign(proposal, consultant) {
    const callable = this.functions.httpsCallable('textMessage');

    this.brandingService.branding$.pipe(take(1)).subscribe( async branding => {
      if (branding.company?.adminCell) {
        try {
          await callable(
            {
              to : this.formatPhoneNumber(branding.company.adminCell),
              body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
                
Consultant: ${consultant?.displayName || consultant?.firstName + ' ' + consultant?.lastName}
${proposal?.price ? 'Total: ' + proposal?.price.toFixed(2) : ''}
                `
            }
          ).toPromise()
        } catch(err) {
          console.log(`error: ${err}`)
          return false
        }
      }
      if (branding?.company?.additionalCells?.length) {
        branding.company?.additionalCells.forEach(async number => {
          try {
            await callable(
              {
                to : this.formatPhoneNumber(number),
                body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
                
Consultant: ${consultant?.displayName || consultant?.firstName + ' ' + consultant?.lastName}
${proposal?.price ? 'Total: ' + proposal?.price.toFixed(2) : ''}
                `
              }
            ).toPromise()
          } catch(err) {
            console.log(`error: ${err}`)
            return false
          }
        });
      }
    })
  }

  notifyAdminsOfLinkedProposalSign(proposal, consultant, total) {
    const callable = this.functions.httpsCallable('textMessage');
    this.brandingService.branding$.pipe(take(1)).subscribe( async branding => {
      if (branding.company?.adminCell) {
        try {
          await callable(
            {
              to : this.formatPhoneNumber(branding.company.adminCell),
              body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
                
Consultant: ${consultant?.displayName || consultant?.firstName + ' ' + consultant?.lastName}
${total ? 'Total: ' + total.toFixed(2) : ''}
                `
            }
          ).toPromise()
        } catch(err) {
          console.log(`error: ${err}`)
          return false
        }
      }
      if (branding?.company?.additionalCells?.length) {
        branding.company?.additionalCells.forEach(async number => {
          try {
            await callable(
              {
                to : this.formatPhoneNumber(number),
                body: `UPDATE FROM DARTBID: Customer '${proposal?.info?.firstName || proposal?.info?.lastName ? proposal?.info?.firstName + ' ' + proposal?.info?.lastName : ''}' has signed their proposal.
                
Consultant: ${consultant?.displayName || consultant?.firstName + ' ' + consultant?.lastName}
${total ? 'Total: ' + total.toFixed(2) : ''}
                `
              }
            ).toPromise()
          } catch(err) {
            console.log(`error: ${err}`)
            return false
          }
        });
      }
    })
  }

  formatPhoneNumber(number:string) {
    let primary = number.replace(/[-()' ']/g,"");
    return `+1${primary}`
  }
}
