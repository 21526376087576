import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { shareReplay, map, switchMap, take } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class CutsheetService {

  cutsheetDoc$: Observable<any>;
  cutsheet$: Observable<any>;

  cutsheetFieldObjectMap = {};
  cutsheetGroupOrderMap = {};
  cutsheetGroupOrderMap$ = of({});

  constructor(
    public database: FirestoreService
  ) {

    this.cutsheetDoc$ = this.database.doc$('admin/cutsheet').pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.cutsheet$ = this.cutsheetDoc$.pipe(
      map(
        data => {
          data?.groups.map( (group, i) => {
            this.cutsheetGroupOrderMap[group.groupName.toLowerCase()] = i;
            group?.fields.forEach( field => {
              this.cutsheetFieldObjectMap[field.label] = {
                'type' : field.type
              }
              this.cutsheetGroupOrderMap$ = of(this.cutsheetGroupOrderMap)
            });
          })
          return data.groups
        }
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );

  }
}
