import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dartboard",
  templateUrl: "./dartboard.component.html",
  styleUrls: ["./dartboard.component.scss"],
  host: {
    class: "page-content column"
  }
})
export class DartboardComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }


}
