import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { SurveyService } from "@shared/services/survey.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.scss']
})
export class SurveysComponent implements OnInit, OnDestroy {

  surveySub: Subscription;

  loading: boolean = true;
  success: boolean = false;
  surveyForm : FormGroup;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private surveyService : SurveyService,
    public router : Router
  ) {
    this.surveyForm = this.fb.group({
      questions: this.fb.array([])
    })

    this.getSetSurveyQuestions()

  }

  ngOnInit() {
  }

  getSetSurveyQuestions() {
    this.surveySub = this.surveyService.surveyQuestions$.pipe(take(1)).subscribe(
      survey => (
        this.questions.clear(),
          survey.questions.map( obj =>
          this.questions.push(
            new FormGroup({
              question: new FormControl( obj['question'] ),
              answer: new FormControl( obj['answer'] ),
            })
          )
        ),
        this.loading = false
      )
    )
  }

  get questions() {
    return this.surveyForm.get("questions") as FormArray;
  }

  addQuestion() {
    const newQuestion = new FormGroup({
      question: new FormControl( null, Validators.required ),
      answer: new FormControl( null ),
    });
    this.questions.push(newQuestion)
    this.surveyForm.markAsDirty()
  }

  removeQuestion(i:number) {
    this.questions.removeAt(i)
    this.surveyForm.markAsDirty()
  }

  reset() {
    this.getSetSurveyQuestions()
    this.surveyForm.markAsPristine();
  }

  // Submit

  onSubmit() {
    this.submitHandler()
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.surveyForm.value;
    try {
      await this.afs.doc("admin/survey").update(formValue);
      this.onSuccess()
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  onSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
      this.surveyForm.markAsPristine()
    }, 3000);
  }

  ngOnDestroy() {
    this.surveySub.unsubscribe();
  }


}
