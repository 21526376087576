import { Pipe, PipeTransform } from "@angular/core";
import { CutsheetService } from "@shared/services/cutsheet.service";

@Pipe({
  name: 'getSectionOrder'
})
export class CutsheetSectionOrderPipe implements PipeTransform {


  constructor( private cutsheetService: CutsheetService ) { }

  transform(sectionName: any): any {
    return this.cutsheetService.cutsheetGroupOrderMap[sectionName.toLowerCase()]
  }
  
}