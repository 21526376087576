import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { BrandingService } from "@shared/services/branding.service";
import { JobsService } from "@shared/services/jobs.service";
import { ProposalsService, SelectedSystem, Proposal } from "@shared/services/proposals.service";
import { AdminPricingService, FinancingOption } from "@shared/services/admin-pricing.service";
import { AddOn } from "@shared/services/add-on.service";
import { Observable, Subscription} from "rxjs";
import { Router } from "@angular/router";
import { FirestoreService } from "@shared/services/firestore.service";
import{ DateCalculationsService } from "@shared/services/date-calculations.service";
import { DatePipe,DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-choose-proposal',
  templateUrl: './choose-proposal.component.html',
  styleUrls: ['./choose-proposal.component.scss']
})
export class ChooseProposalComponent implements OnInit, OnDestroy {

  @Input() tonnage : string;
  @Input() proposal : Proposal;
  @Input() job;
  @Input() selectedSystem;
  @Input() isCustomer;

  pricingSub : Subscription;
  awaitingData: boolean = true;
  loading: boolean = true;
  calculating: boolean = true;
  isConsultantBoxOpen: boolean = true;
  isAddingDeposit: boolean = false;

  isSplitPayment: boolean = false;

  currentlySelectedSystem: SelectedSystem;
  currentlySelectedEnhancements = [];

  paymentOption: string = "credit";

  s : number; // system price
  a : number; // add-ons
  e : number; // enhancements
  d : number; // rebates
  cd: number; // custom discount
  cr: number; // cash rebate
  dp : number; // deposit

  dsp : number; // displayed system price

  f: number; // credit fee
  r: number; // tax rate
  taxRate: number; // tax rate

  wereLegacyTaxesIncluded: boolean = false;

  cashPreTax: number;
  creditPreTax: number;

  cashTotal: number;
  creditTotal: number;
  financedTotal: number = 0;

  tonnageMatchedFormattedOptions : SelectedSystem[] = [];

  financingOptions : any[] = [];
  financingOptionIndex = 0;
  selectedFinancingOption : FinancingOption;
  selectedFinancingDetails : any;
  financePayment : number = 0;
  financePaymentTotal : number = 0;
  financedAmount : number = 0;
  financedAmountWithFee : number = 0;
  // financedAmountFee : number = 0;
  financingApplicationURL : string;

  appliedDiscounts : any[] = [];

  isCostBreakdownOpen: boolean = false;
  isFinancingBreakdownOpen: boolean = true;

  isConfirmSignOpen: boolean = false;
  isSold: boolean;
  isConfirmedRead: boolean = false;
  customerSignature: string;

  customerMessage: string;
  isCustomerMessageSaved: boolean = false;

  isConsultantConfirmSaveOpen: boolean = false;

  deposit = {
    cr: 0,
    convenienceFee: 0,
    balance: null,
    amount: null,
    adjustedAmount: null,
    due: null,
    type: 'cash',
    previousBalance: null,
    time: Date.now(),
    notes: null
  }

  @Output() optionChose = new EventEmitter<object>();
  @Output() enhancementsChose = new EventEmitter<any[]>();
  @Output() optionTotal = new EventEmitter<number>();
  template$: Observable<any>

  rebuiltOptions: SelectedSystem[] = [];

  constructor(
    private database: FirestoreService,
    public jobsService: JobsService,
    public brandingService: BrandingService,
    public adminPricingService : AdminPricingService,
    private router: Router,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private dateCalculationsService : DateCalculationsService
  ) {
  }

  ngOnInit(): void {
    this.pricingSub = this.adminPricingService.accounting$.subscribe( data => {
      this.f = data.creditPriceAdminCosts;
      this.dp = data.depositPercentage;
      // this.r = data.taxRate;
      this.taxRate = data.taxRate;
      this.r = 0; // @TODO - its 0 for now in Florida
      this.financingOptions = data.financingOptions;
      this.financingApplicationURL = data.financingApplicationURL;
      this.setUp();
      this.customerMessage = this.job?.customerMessage ? this.job.customerMessage : null
    });

    if (!this.isCustomer) {
      this.toggleCollapseConsultantBox()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.loading) {
      this.setUp()
    }
  }


  async setUp() {
    const job = this.job;
    await job;
    await this.taxCheck();
    await this.prepareOptions(this.proposal.options);
    await this.setInitialData();
    await this.discountCheck();
    await this.setPrices();
    this.loading = false
    this.awaitingData = false;
  }

  async taxCheck() {
    const signedAt = this.job?.confirmation?.signedAt;
    await signedAt;

    if (this.job?.status == 'sold' && signedAt) {
      let dateSigned = this.datePipe.transform(signedAt,"yyyy-MM-dd");
      let taxCutoff = '2021-09-08';
      const wereLegacyTaxesIncluded = this.dateCalculationsService.isDateLessThan(dateSigned, taxCutoff);
      this.wereLegacyTaxesIncluded = wereLegacyTaxesIncluded;
      if (wereLegacyTaxesIncluded) {
        this.r = this.taxRate
      } else {
        this.r = 0;
      }
    } else {
      this.wereLegacyTaxesIncluded = false;
      this.r = 0;
    }

  }

  async prepareOptions(options) {
    this.tonnageMatchedFormattedOptions = []; // reset

    for (let [index, option] of options.entries()) {
      const rebates = this.proposal.discounts.filter(
        discount => discount.appliesToOptions.includes(option.details.option)
      );

      let isExactTonnageInTemplate = this.proposal.tonnages.includes(parseFloat(this.tonnage))

      if ((option.details.option == 0 || option.details.option == 1 || option.details.option == 2) && parseFloat(this.tonnage) % 1 != 0) {
        let rounded = parseInt(this.tonnage) + 1;
        let tonnage = rounded.toString();
        for (let size of option.sizes.filter(size => size.tons == tonnage)) {
          let item = {
            option: option.details.option,
            brand: this.proposal.description.brand.toLowerCase(),
            hoursToInstall: option.details.hoursToInstall,
            indoorUnit:  size.override ? size.override.indoorUnit : option.details.indoorUnit,
            indoorUnitBrochure:  size.override ? size.override.indoorUnitBrochure : option.details.indoorUnitBrochure,
            materialCosts: option.details.materialCosts,
            // netProfit: option.details.netProfit,
            outdoorUnit: size.override ? size.override.outdoorUnit : option.details.outdoorUnit,
            outdoorUnitBrochure: size.override ? size.override.outdoorUnitBrochure : option.details.outdoorUnitBrochure,
            thermostat:  size.override ? size.override.thermostat : option.details.thermostat,
            thermostatBrochure:  size.override ? size.override.thermostatBrochure : option.details.thermostatBrochure,
            warranty: option.details.warranty,
            features: option.features,
            tonnage: tonnage,
            cashPrice: size.cashPrice,
            seer: size.seer,
            cost: size.cost,
            label: this.getLabels(option.details.option.toString()) ? this.getLabels(option.details.option.toString()) : `Option ${index + 1}`,
            rebates: rebates,
            rebatesTotal: rebates.reduce(
              (total : number, discount: any) => {
                let creditPrice = this.addCreditFees(size.cashPrice)
                if (this.isDiscountCurrentlyAvailable(discount)) {
                  if (discount.isPercentage) {
                    return total += this.getPercentageOfNumber(creditPrice,discount.value)
                  } else {
                    return total += discount.value
                  }
                } else {
                  return total
                }
              }, 0
            )
          }
          this.tonnageMatchedFormattedOptions.push(item)
        }
      } else {
        // Go through each applicapable size where tonnage matches exact
        for (let size of option.sizes.filter(size => size.tons == this.tonnage)) {
          let item = {
            option: option.details.option,
            // option: option.details.option.toString(),
            brand: this.proposal.description.brand.toLowerCase(),
            hoursToInstall: option.details.hoursToInstall,
            indoorUnit:  size.override ? size.override.indoorUnit : option.details.indoorUnit,
            indoorUnitBrochure:  size.override ? size.override.indoorUnitBrochure : option.details.indoorUnitBrochure,
            materialCosts: option.details.materialCosts,
            // netProfit: option.details.netProfit,
            outdoorUnit: size.override ? size.override.outdoorUnit : option.details.outdoorUnit,
            outdoorUnitBrochure: size.override ? size.override.outdoorUnitBrochure : option.details.outdoorUnitBrochure,
            thermostat:  size.override ? size.override.thermostat : option.details.thermostat,
            thermostatBrochure:  size.override ? size.override.thermostatBrochure : option.details.thermostatBrochure,
            warranty: option.details.warranty,
            features: option.features,
            tonnage: this.tonnage,
            cashPrice: size.cashPrice,
            seer: size.seer,
            cost: size.cost,
            label: this.getLabels(option.details.option.toString()) ? this.getLabels(option.details.option.toString()) : `Option ${index + 1}`,
            rebates: rebates,
            rebatesTotal: rebates.reduce(
              (total : number, discount: any) => {
                let creditPrice = this.addCreditFees(size.cashPrice)
                if (this.isDiscountCurrentlyAvailable(discount)) {
                  if (discount.isPercentage) {
                    return total += this.getPercentageOfNumber(creditPrice,discount.value)
                  } else {
                    return total += discount.value
                  }
                } else {
                  return total
                }
              }, 0
            )
          }
          this.tonnageMatchedFormattedOptions.push(item)
        }
      }
    }
  }

  async setInitialData() {
    ////////////////////////////////////////////////////////////////////////////
    // Set any input specific values
    if (this.job.status == 'sold') {
      this.isSold = true;
      this.paymentOption = this.job.confirmation?.paymentMethod ? this.job.confirmation.paymentMethod : null
    } else {
      this.isSold = false
    }

    ////////////////////////////////////////////////////////////////////////////
    // If a selected system was input, set it. Else, default to the first item in the options

    if (this.selectedSystem) {
      this.currentlySelectedSystem = this.selectedSystem;
    } else {
      this.currentlySelectedSystem = this.tonnageMatchedFormattedOptions[0]
    }

    ////////////////////////////////////////////////////////////////////////////
    // Apply any previously selected enhancements input
    this.currentlySelectedEnhancements  = this.job.enhancements.filter(enhancement => this.isEnhancementApplicableToSelectedSystem(enhancement))

  }

  toggleConfirmSignOpen() {
    this.isConfirmSignOpen = !this.isConfirmSignOpen
  }

  async sign() {

    if(this.isEnhancementsUnchanged(this.job.enhancements, this.currentlySelectedEnhancements)) {
    } else {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'enhancements': this.currentlySelectedEnhancements
      });
    }

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'status': 'sold',
        'system': this.currentlySelectedSystem,
        // 'enhancements': this.currentlySelectedEnhancements,
        'confirmation': {
          'signedAt': Date.now(),
          'paymentMethod': this.paymentOption,
          'customerSignature': this.customerSignature,
          'appliedPromotions': this.appliedDiscounts,
          'appliedPromotionsTotalAmount': this.d
        }
      });
      if (this.paymentOption == 'financing') {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          'confirmation.selectedFinancingDetails': this.selectedFinancingDetails
        });
      }
      await this.done();
      this.router.navigateByUrl(`thanks?proposal=${this.job.meta.id}`)
    } catch (err) {
      console.log(err)
    }
    this.router.navigateByUrl(`thanks?proposal=${this.job.meta.id}`)
  }

  toggleCollapseConsultantBox() {
    this.isConsultantBoxOpen = !this.isConsultantBoxOpen
  }

  toggleConsultantVerify(state?:boolean) {
    if (state) {
      this.isConsultantConfirmSaveOpen = state
    } else {
      this.isConsultantConfirmSaveOpen = !this.isConsultantConfirmSaveOpen
    }
  }

  async done() {
    if(this.paymentOption == 'credit') {
      this.optionTotal.emit(this.creditTotal)
    } else if (this.paymentOption == 'cash') {
      this.optionTotal.emit(this.cashTotal)
    } else if (this.paymentOption == 'financing') {
      // this.optionTotal.emit(this.cashTotal)
      this.optionTotal.emit(this.cashTotal)
    }

    if(this.isEnhancementsUnchanged(this.job.enhancements, this.currentlySelectedEnhancements)) {
    } else {
      this.enhancementsChose.emit(this.currentlySelectedEnhancements)
    }

    this.optionChose.emit(this.currentlySelectedSystem)
    return;
  }

  close() {
    this.optionChose.emit()
  }

  async customerDone() {
    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'customerSelectedSystem': 'true'
      });
      this.done();
      this.router.navigateByUrl('thanks')
    } catch (err) {
      console.log(err)
    }
  }

  selectSystem(system,i) {
    if(system.option != this.currentlySelectedSystem?.option) {
      this.currentlySelectedSystem = system;
      setTimeout( () => {this.setPrices(); this.discountCheck()}, 50)
    }
  }

  async discountCheck() {
    this.appliedDiscounts = [];
    if (this.proposal?.discounts && !this.job?.confirmation) {
      this.proposal?.discounts.forEach( discount => {
        const available = this.isDiscountCurrentlyAvailable(discount);
        const applicable = this.isDiscountApplicable(discount);
        if (available && applicable) {
          this.appliedDiscounts.push(discount)
        }
      })
    }
  }

  isDiscountApplicable(discount) {
    if(discount.appliesToOptions.some(option => option == this.currentlySelectedSystem?.option)) {
      return true
    } else {
      return false
    }
  }

   isDiscountCurrentlyAvailable(discount) {
    const today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    if (discount.enabledDate && discount.expirationDate) {
      return this.dateCalculationsService.isDateWithinRange(today,discount.enabledDate,discount.expirationDate)
    } else if (discount.enabledDate && !discount.expirationDate){
      return this.dateCalculationsService.isDateGreaterThan(today,discount.enabledDate)
    } else if (discount.expirationDate && !discount.enabledDate ){
      return this.dateCalculationsService.isDateLessThan(today,discount.expirationDate)
    } else {
      return true
    }
  }

  isEnhancementsUnchanged(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

  toggleEnhancement(e,i) {
    if (this.currentlySelectedEnhancements.some(enhancement => enhancement.name === e.name)) {
      this.currentlySelectedEnhancements = this.currentlySelectedEnhancements.filter(enhancement => enhancement.name !== e.name)
    } else {
      this.currentlySelectedEnhancements.push(e)
    }
    setTimeout(() => {
      this.setPrices();
      // this.e = this.getEnhancementsTotal();
    }, 50);
  }

  isEnhancementSelected(e) {
    if (this.currentlySelectedEnhancements.some(enhancement => enhancement.name === e.name)) {
      return true
    } else {
      return false
    }
  }

  isEnhancementApplicableToSelectedSystem(e) {
    if (this.proposal.options.length == e.worksWithOptions.length) {
      // If works with options is equal to available systems, it must be globally compatible
      return true
    } else if (e.worksWithOptions.some(option => option == this.currentlySelectedSystem?.option)) {
      // If the worksWithOptions array contains the current system
      return true
    } else {
      return false
    }
  }

  getLabels(n) {
    const option = parseInt(n);
    if (option == 0) {
       if(this.proposal.labels?.r1 && this.proposal.labels?.a) {
         return this.proposal.labels?.r1  + " | " +  this.proposal.labels?.a
       } else if (this.proposal.labels?.r1) {
         return this.proposal.labels?.r1
       } else if (this.proposal.labels?.a) {
         return this.proposal.labels?.a
       }
    }
    if (option == 1) {
      if(this.proposal.labels?.r1 && this.proposal.labels?.b) {
        return this.proposal.labels?.r1  + " | " +  this.proposal.labels?.b
      } else if (this.proposal.labels?.r1) {
        return this.proposal.labels?.r1
      } else if (this.proposal.labels?.b) {
        return this.proposal.labels?.b
      }
    }
    if (option == 2) {
      if(this.proposal.labels?.r1 && this.proposal.labels?.c) {
        return this.proposal.labels?.r1 + " | " + this.proposal.labels?.c
      } else if (this.proposal.labels?.r1) {
        return this.proposal.labels?.r1
      } else if (this.proposal.labels?.c) {
        return this.proposal.labels?.c
      }
    }
    if (option == 3) {
      if(this.proposal.labels?.r2 && this.proposal.labels?.a) {
        return this.proposal.labels?.r2  + " | " + this.proposal.labels?.a
      } else if (this.proposal.labels?.r2) {
        return this.proposal.labels?.r2
      } else if (this.proposal.labels?.a) {
        return this.proposal.labels?.a
      }
    }
    if (option == 4) {
      if(this.proposal.labels?.r2 && this.proposal.labels?.b) {
        return this.proposal.labels?.r2  + " | " +  this.proposal.labels?.b
      } else if (this.proposal.labels?.r2) {
        return this.proposal.labels?.r2
      } else if (this.proposal.labels?.b) {
        return this.proposal.labels?.b
      }
    }
    if (option == 5) {
      if(this.proposal.labels?.r2 && this.proposal.labels?.c) {
        return this.proposal.labels?.r2  + " | " +  this.proposal.labels?.c
      } else if (this.proposal.labels?.r2) {
        return this.proposal.labels?.r2
      } else if (this.proposal.labels?.c) {
        return this.proposal.labels?.c
      }
    }
  }

  async setPrices() {
    this.s = this.currentlySelectedSystem?.cashPrice;
    this.e = this.getE()
    this.d = this.getD()
    this.a = this.getA()
    this.dsp = this.getDSP()
    this.cr = this.getCR()
    this.cd = this.job.customDiscount?.amount;
    this.cashPreTax = this.getP();
    this.creditPreTax = this.getCreditPrice();
    this.cashTotal = this.getCashTotal();
    this.creditTotal = this.getCreditTotal();

    if(this.paymentOption == 'financing') {

      if (!this.job?.confirmation?.selectedFinancingDetails) {
        // applies financing option, since that is stored as the index
        this.setFinancingOption(this.financingOptions[this.financingOptionIndex])
      } else {
      }

    }

  }

  // Add credit fees, including the portion that will cover tax
  addCreditFees(number) {
    return number / ( 1 - this.f / 100 * (1 - this.r / 100));
  }

  // Remove credit fees
  removeCreditFees(number) {
    if (number) {
      return number * ( 1 - this.f / 100 * (1 - this.r / 100));
    } else {
      return 0
    }
  }

  // Add tax
  addTax(number) {
    return number * (1 + this.r / 100);
  }

  // Remove tax
  removeTax(number) {
    return number / (1 + this.r / 100);
  }

  // Get the cash price (pre-tax)
  getP() {
    return this.s + this.a + this.e - this.d - this.removeCreditFees(this.job.customDiscount?.amount ? this.job.customDiscount.amount : 0);
  }

  // Get credit price (pre-tax)
  getCreditPrice() {
    return this.addCreditFees( this.getP() );
  }

  // Get the final total if paying cash
  getCashTotal() {
    return this.addTax( this.getP() );
  }

  // Get the final total if paying credit
  getCreditTotal() {
    return this.addTax( this.getCreditPrice() );
  }

  // Get the cash rebate
  getCR() {
    return this.getCreditPrice() - this.getP();
  }

  // Get the display price for the system
  getDSP() {
    return this.addCreditFees(this.s - this.d * this.f / 100 * (1 - this.r / 100));
  }

  getA() {
    return this.job.addOns?.reduce(
      (total : number, addOn : AddOn) => {
        if ( addOn.quantity ) {
          return total += addOn.price * addOn.quantity
        }
        else {
          return total += addOn.price
        }
      }, 0
    );
  }

  getE() {
    return this.currentlySelectedEnhancements?.reduce(
      (total : number, enhancement) => {
        return total += enhancement.price
      }, 0
    );
  }

  getD(){
    return this.proposal?.discounts.reduce(
      (total : number, discount: any) => {
        if (this.isDiscountCurrentlyAvailable(discount) && discount.appliesToOptions?.some(option => option == this.currentlySelectedSystem?.option)) {
          if (discount.isPercentage) {
        		let creditPrice = this.addCreditFees(this.currentlySelectedSystem.cashPrice)
            return total += this.getPercentageOfNumber(creditPrice,discount.amount)
          } else {
            return total += discount.amount
          }
        } else {
          return total
        }
      }, 0
    );
  }

  getFeePercentage(amount) {
    return (this.f / 100) * amount;
  }

  getConvenienceFee(amount) {
    return (this.f / 100) * amount;
  }

  getCashRebate(amount) {
    return (this.f / 100) * amount;
  }

  // Deposit
  async calculateDeposit(depositType) {
    this.deposit.type = this.job.confirmation?.paymentMethod;
    const previousBalance = this.job?.balance ? this.job.balance : this.job.price;
    let due;

    if (this.job.confirmation?.paymentMethod != 'financing') {
      due = Math.round( (this.dp * previousBalance / 100) * 100) / (this.dp * 10);
    } else  {
      let dueWithoutCreditFees = Math.round(((this.dp * previousBalance / 100) * 100) / (this.dp * 10) );
      due = Math.round(this.addCreditFees(dueWithoutCreditFees))
    }

    await due;

    const fee = this.getConvenienceFee(this.deposit.amount);
    const cr = this.getCashRebate(this.deposit.amount);

    await cr;

    this.deposit.due = due;
    this.deposit.amount = due;
    this.deposit.cr = cr;
    this.deposit.previousBalance = previousBalance;
    let amountApplied;

    if (this.job.confirmation?.paymentMethod == 'cash' && depositType == 'credit') {
      // If its a credit deposit on cash job, we need to subtract the fee from the deposit
      amountApplied = this.deposit.amount - fee;
    } else if (this.job.confirmation?.paymentMethod == 'credit' && depositType == 'cash') {
      // If its a cash deposit on a credit job, we need to apply a cash rebate
      amountApplied = this.deposit.amount + cr;
    } else if (this.job.confirmation?.paymentMethod == 'financing' && depositType == 'credit') {
      // If its a credit deposit on a financing (cash) job, we need to subtract the fee from the deposite
      amountApplied = this.deposit.amount - fee;
    } else {
      amountApplied = this.deposit.amount
    }

    await amountApplied;

    this.deposit.balance = this.deposit.previousBalance - this.deposit.amount;
    this.deposit.adjustedAmount = amountApplied;

  }

  async updateDeposit(depositType) {
    const fee = this.getConvenienceFee(this.deposit.amount);
    const cr = this.getCashRebate(this.deposit.amount);

    let amountApplied;

    if (this.job.confirmation?.paymentMethod == 'cash' && depositType == 'credit') {
      // If its a credit deposit on cash job, we need to subtract the fee from the deposit
      amountApplied = this.deposit.amount - fee;
    } else if (this.job.confirmation?.paymentMethod == 'credit' && depositType == 'cash') {
      // If its a cash deposit on a credit job, we need to apply a cash rebate
      amountApplied = this.deposit.amount + cr;
    } else if (this.job.confirmation?.paymentMethod == 'financing' && depositType == 'credit') {
      // If its a credit deposit on a financing (cash) job, we need to subtract the fee from the deposite
      amountApplied = this.deposit.amount - fee;
    } else {
      amountApplied = this.deposit.amount
    }

    this.deposit.adjustedAmount = amountApplied;
    this.deposit.convenienceFee = fee;
    this.deposit.balance = this.deposit.previousBalance - amountApplied;
    this.deposit.cr = cr;
  }

  async applyDeposit() {
    this.loading = true;
    let deposits = this.job?.deposits ? this.job.deposits : [];
    this.deposit.previousBalance = this.job?.balance ? this.job.balance : this.job.price;
    this.deposit.time = Date.now();
    deposits.push(this.deposit)
    let depositsTotal = this.job?.depositsTotal ? this.job?.depositsTotal : 0;

    try {
      await this.database.doc(`jobs/${this.job.meta.id}`).update({
        'deposits': deposits, // add deposit to deposit array
        'balance': this.deposit.balance, // update price to this new balance
        'depositsTotal' : depositsTotal += this.deposit.adjustedAmount
      });
      this.toggleAddDeposit(false);

      if (this.job?.confirmation?.paymentMethod == 'financing') {
        this.updateFinancingAfterDeposit(this.deposit.balance);
      }
      this.loading = false;

    } catch (err) {
      console.log(err)
      this.loading = false;
    }

  }

  async updateFinancingAfterDeposit(balanceAfterDeposit) {

    if (this.job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption) {


      let cashTotal = this.job?.confirmation?.selectedFinancingDetails?.cashTotal ;//this.getCashTotal();
      let creditTotal =  this.job?.confirmation?.selectedFinancingDetails?.creditTotal ;//this.getCreditTotal();
      let pmt;
      let fin = this.job?.confirmation?.selectedFinancingDetails?.selectedFinancingOption;
      let p = balanceAfterDeposit;
      let b = balanceAfterDeposit;
      // let p = balanceAfterDeposit / (1 - (fin.fee / 100));

      let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

      if (fin.apr == 0 && !fin.min) {
        pmt = p / fin.months;
      } else if (!fin.min) {
        pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
      } else if (fin.min) {
        pmt = p * fin.min / 100;
      }

      this.financePayment = pmt;


      const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
      this.financePaymentTotal = pmtFormatted * fin.months;
      this.financedAmount = p;

      let updatedSelectedFinancingDetails = {
        'financingBasisPrice' : b,
        'financePayment' : pmt,
        'financePaymentsTotalAtTermEnd': this.financePaymentTotal,
        'financedAmount': this.financedAmount,
      }

      try {
        await this.database.doc(`jobs/${this.job.meta.id}`).set({
          'confirmation': {
            'selectedFinancingDetails' : updatedSelectedFinancingDetails
          }
        }, { merge: true });
      } catch (err) {
        console.log(err)
      }
    }



  }

  async saveCustomerMessage() {
    if (this.customerMessage != this.job?.customerMessage) {
      try {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          'customerMessage': this.customerMessage,
        });
        this.showCustomerMessageSuccessMessage()
      } catch (err) {
        console.log(err)
      }
    }
  }

  showCustomerMessageSuccessMessage() {
    this.isCustomerMessageSaved = true;
    setTimeout(()=>{
      this.isCustomerMessageSaved = false;
    }, 3000);
  }

  toggleAddDeposit(state?:boolean) {
    this.calculateDeposit(this.job.confirmation?.paymentMethod)
    if(state) {
      this.isAddingDeposit = state
    } else {
      this.isAddingDeposit = !this.isAddingDeposit
    }
  }

  // Add a discount percentage
  getPercentageOfNumber(number: number, percentage: number) {
    return percentage * ( number / 100 );
  }

  // Calculate the monthly payment for a financing option
  async setFinancingOption(financingOption) {
    let pmt;
    let fin = financingOption;
    let cashTotal = this.getCashTotal();
    let creditTotal = this.getCreditTotal();
    // let t = this.getCashTotal() / (1 - (fin.fee / 100));
    let b = this.getCashTotal();
    let p = b;
    // let p = b / (1 - (fin.fee / 100));
    let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    // 0% APR and equal payments
    if (fin.apr == 0 && !fin.min) {
      pmt = p / fin.months;
      // this.financedTotal = pmt * fin.months;
    // Not 0% APR but equal payments
    } else if (!fin.min) {
      pmt = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);
      // this.financedTotal = pmt * fin.months;
    // Non-equal payments (ability to make smaller minimum payments)
    } else if (fin.min) {
      pmt = p * fin.min / 100;
      // this.financedTotal = pmt * fin.months;
    }
    const option = financingOption;
    await option;
    this.selectedFinancingOption = option;
    this.financePayment = pmt;

    const pmtFormatted = parseFloat(this.decimalPipe.transform(pmt, '0.2-2'))
    this.financePaymentTotal = pmtFormatted * fin.months;
    this.financedAmount = p;
    // this.financedAmountWithFee = (p * (1 + (fin.fee / 100)));
    // this.financedAmountFee = this.financedAmountWithFee - this.financedAmount;

    this.selectedFinancingDetails = {
      'financingBasisPrice' : b,
      'selectedFinancingOption' : option,
      'cashTotal': cashTotal,
      'creditTotal': creditTotal,
      'financePayment' : pmt,
      'financePaymentsTotalAtTermEnd': this.financePaymentTotal,
      'financedAmount': this.financedAmount,
      'financedOriginalTotal': this.financedAmount, // differs because amount can change after deposits
      // 'financedAmountWithFee' : fin.fee ? this.financedAmountWithFee : this.financedAmount,
      // 'financedAmountFee' : this.financedAmountFee
    }

  }

  getFinancingTotalPaid(payment,months) {
    const p = parseFloat(this.decimalPipe.transform(payment, '0.2-2'))
    return (p  * months)
  }

  ngOnDestroy() {
    this.pricingSub.unsubscribe()
  }

}
