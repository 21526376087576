import { Injectable, OnDestroy } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore.service';
import { Observable , Subscription, of, Subject} from 'rxjs';
import { map, shareReplay, tap} from 'rxjs/operators';

export interface Feature {
  name: string;
  description: string;
  shortname?: string;
  isCheckbox: boolean;
}

export interface FeatureDoc {
  all: Feature[];
}

@Injectable({
  providedIn: 'root'
})
export class FeatureService implements OnDestroy{

  features$ : Observable<Feature[]>;
  featuresDoc$ : Observable<any>;
  featuresIdTable : Observable<any[]>;
  optionComparisonFeaturesFormObject$ : Observable<any> = of({});
  addOptionComparisonFeaturesFormArray$ : Observable<any[]> = of([]);
  featuresListObject = {};
  // Static features that happen in the table for each tonnage, not entered once for the entire option
  // currently seer, db, and hspf
  staticOptionFeatures = [
    '0kCzyBO4NxeLu0PRTN5h',
    '6GBCUV8kgXTQFOVsJudK',
    'YAx3JwhES7HXg7up2VCJ',
    'Q85agnLmFI8Z09zGQBbu'
  ];

  FeaturesSub : Subscription;

  constructor(private database : FirestoreService) {

    this.featuresDoc$ = this.database.doc$('admin/features').pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.features$ = this.featuresDoc$.pipe(
      map(
        data => {
          return data.all
        }
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.optionComparisonFeaturesFormObject$ = this.featuresDoc$.pipe(
      map(
        data => {
          let newObject = {}
          data.all.forEach( feature => {
            if (!this.staticOptionFeatures.includes(feature.id)) {
              newObject[feature.id] = '';
            } else {
            }
          })
          return newObject
        }
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );

     this.addOptionComparisonFeaturesFormArray$ = this.featuresDoc$.pipe(
      map(
        data => {
          console.log(`data.all.forEach ==`)
          let newArray = []
          data.all.forEach( feature => {
            if (!this.staticOptionFeatures.includes(feature.id)) {
              newArray.push(feature)
            } else {
            }
          })
          return newArray
        }
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );

  }


  ngOnDestroy() {
    // this.FeaturesSub?.unsubscribe()
  }

}
