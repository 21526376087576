import { Component, OnInit, Input } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Component({
  selector: 'app-customer-option',
  templateUrl: './customer-option.component.html',
  styleUrls: ['./customer-option.component.scss'],
  host: {
    class: "card padding"
  }
})

export class CustomerOptionComponent implements OnInit {
  @Input() public system;
  @Input() public proposal;
  @Input() i;

  rebatesTotal: number;
  dsp: number;
  fee: number;

  constructor( private adminPricingService: AdminPricingService ) {
    this.fee = this.adminPricingService.creditPriceAdminCosts;
  }

  // Get the display price for the system
  getDSP() {
    let r = 0; // @TODO - its 0 for now in Florida
    return (this.system.cashPrice - this.system.rebatesTotal * this.adminPricingService.creditPriceAdminCosts / 100 * (1 - r / 100)) / (1 - (this.adminPricingService.creditPriceAdminCosts / 100) * (1 - (r / 100)))
  }

  ngOnInit(): void {
    this.dsp = this.getDSP()
  }

}
