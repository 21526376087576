
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDisplayPriceFromSettings'
})
export class GetDisplayPriceFromBaseAndSettings implements PipeTransform {


    transform( cashPrice : number, model : any, fee : number, tax : number ) {
    const t = tax / 100;
    let f = fee;
    const b = cashPrice;

    if (cashPrice == 0) {
      return 0
    } else {
      if (model == 1) {
        // Model 1 = shared fee
        // let f = psis.meta.accounting.sharedFee;
        return this.addPercentageToTotal(b,f)

      } else if (model == 2) {
        // Model 2 = cash rebate
        let f = fee / 100;
        return b / (1 - f * (t + 1))

      } else if (model == 3) {
        // Model 3 = surcharge
        return b
      }
    }
  }

  addPercentageToTotal(base, percentage) {
    return base * (1 + percentage / 100);
  }

  // transform(cashPrice : number, model : any, creditFee, sharedPercentage) {

  //   if (model == 1) {
  //     // Model 1 = shared fee
  //     return this.addPercentage(cashPrice,sharedPercentage)
  //   } else if (model == 2) {
  //     // Model 2 = cash rebate
  //     return this.addPercentage(cashPrice,creditFee)
  //   } else if (model == 3) {
  //     // Model 3 = surcharge
  //     return cashPrice
  //   }
  // }

  // addPercentage(base, percentage) {
  //   return (base / ( 1 - ( percentage / 100) )).toFixed(2);
  // }

  

}
