import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { CutsheetService } from '@shared/services/cutsheet.service';
import { QueueToggleService } from '@shared/services/queue-toggle.service';
import { FirestoreService } from '@shared/services/firestore.service';

@Component({
  selector: 'app-cutsheet',
  templateUrl: './cutsheet.component.html',
  styleUrls: ['./cutsheet.component.scss']
})
export class CutsheetComponent implements OnInit {

  activeView: number = 0;
  loading: boolean = true;
  success: boolean = false;
  cutsheetForm : FormGroup;
  testForm : FormArray;

  cutsheetSub : Subscription;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    public database: FirestoreService,
    public queueToggleService: QueueToggleService,
    public cutsheetService : CutsheetService
  ) { 

      this.cutsheetForm = this.fb.group({
        groups: this.fb.array([])
      })

  }

  ngOnInit(): void {

    this.cutsheetSub = this.cutsheetService.cutsheet$.subscribe(
      data => (
        this.groups.clear(),
        data.map( obj => {

          let cutsheetGroup = this.fb.group({
              groupName: new FormControl( obj['groupName'] ),
              fields: this.fb.array([])
          });

          obj.fields.map( field => {
            let target = cutsheetGroup.get('fields') as FormArray;
            target.push(
              this.fb.group({
                label: new FormControl( field['label'] ),
                type: new FormControl( field['type'] ),
              })
            )
          })

            this.groups.push(cutsheetGroup )

          // if (obj['groupName'] == 'Unit') {
          //   this.groups.insert(0, cutsheetGroup)
          // } else if (obj['groupName'] == 'Condensor') {
          //   this.groups.insert(1, cutsheetGroup)
          // } else {
          //   this.groups.push(cutsheetGroup )
          // }
          
          }
        ),
        this.loading = false
      )
    )
  }

  setActiveView(view:number) {
    this.activeView = view;
    this.queueToggleService.closeQueueIfMobile()
  }

  addNewCutsheetGroup() {
    let newCutsheetGroup = this.fb.group({
        groupName: [ null, Validators.required ],
        fields: this.fb.array([],[
          Validators.minLength(1),
          Validators.required
        ])
    });
    this.groups.push(newCutsheetGroup);
    this.cutsheetForm.markAsDirty()
  }

  deleteCutsheetGroup(g) {
    const target = this.cutsheetForm.get("groups") as FormArray;
    target.removeAt(g);
    this.cutsheetForm.markAsDirty()
  }

  addNewCutsheetField(g) {
    const target = this.cutsheetForm.get("groups." + g + '.fields') as FormArray;
    let newCutsheetField = this.fb.group({
        label: [ null, Validators.required ],
        type: [ 0, Validators.required ],
    });
    target.push(newCutsheetField);
    this.cutsheetForm.markAsDirty()
  }

  deleteCutsheetField(g,f) {
    const target = this.cutsheetForm.get("groups." + g + '.fields') as FormArray;
    target.removeAt(f);
    this.cutsheetForm.markAsDirty()
  }

  onSubmit() {
    this.submitHandler()
  }

  reset() {
  }

  onSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
      this.cutsheetForm.markAsPristine()
    }, 3000);
  }

  get groups() {
    return this.cutsheetForm.get("groups") as FormArray;
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.cutsheetForm.value;
    try {
      await this.afs.doc("admin/cutsheet").update(formValue);
      this.onSuccess()
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

}
