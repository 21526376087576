import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SharedModule } from "@shared/shared.module";

import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HomeComponent } from "./core/home/home.component";
import { UiComponent } from "./core/ui/ui.component";
import { LoginComponent } from "./core/login/login.component";
import { BannerModule } from "./core/banner/banner.module";
import { AdminModule } from "./core/admin/admin.module";
import { DartboardModule } from "./core/dartboard/dartboard.module";
import { DirectivesModule } from "@shared/directives/directives.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatExpansionModule } from "@angular/material/expansion";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { OverlayModule } from "@angular/cdk/overlay";
import { FirestoreService } from "@shared/services/firestore.service";
import { DatePipe, DecimalPipe } from '@angular/common';
import firebase_config from './firebase_config';
import { ActionLinkComponent } from './core/action-link/action-link.component';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UiComponent,
    LoginComponent,
    ActionLinkComponent
  ],
  imports: [
    ScheduleModule,
    BrowserModule,
    SharedModule,
    OverlayModule,
    AppRoutingModule,
    BannerModule,
    AdminModule,
    DartboardModule,
    DirectivesModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    BrowserAnimationsModule,
    MatExpansionModule,
    AngularFireModule.initializeApp(firebase_config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
  ],
  providers: [FirestoreService,DatePipe,DecimalPipe],
  bootstrap: [AppComponent]
})
export class AppModule {}

