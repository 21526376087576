import { CalendarService } from '@shared/services/calendar.service';
import { UserService } from '@shared/services/user.service';
import { LeadsService } from '@shared/services/leads.service';
import { Component, OnInit, OnDestroy, Input, SimpleChanges  } from '@angular/core';
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { JobsService } from "@shared/services/jobs.service";
import { AuthService } from "@shared/services/auth.service";
import { DartboardFilterService } from "@shared/services/dartboard-filter.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadModalComponent } from '@shared/components/lead-modal/lead-modal.component';
@Component({
  selector: 'app-jobs-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy  {

  @Input() jobsList;

  routeSub: Subscription;
  filterSub: Subscription;
  isFiltersOpen : boolean = false;
  activeFilter: string = 'pending';
  activeJobId : string;

  constructor(
    public queueToggleService: QueueToggleService,
    private route: ActivatedRoute,
    public jobsService: JobsService,
    public authService: AuthService,
    public dartboardFilterService : DartboardFilterService,
    public dialog: MatDialog,
    public leadsService : LeadsService,
    public userService : UserService,
    public calendarService : CalendarService
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      this.activeJobId = params.id;
      if(this.route.snapshot.queryParamMap.get('filter')||0) {
        let filterParam = this.route.snapshot.queryParamMap.get('filter').toLowerCase();
        if ( filterParam != 'pending') {
          this.isFiltersOpen = true;
        }
        this.activeFilter = filterParam;
        this.dartboardFilterService.setDartboardFilter(filterParam)
      }
    });
    this.filterSub = this.dartboardFilterService.dartboardFilter$.subscribe( state => {
      if (this.activeFilter != state) {
        this.activeFilter = state;
        this.isFiltersOpen = true;
      }
    })

  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['jobsList']) {
      if(changes['jobsList']?.currentValue?.length != changes['jobsList']?.previousValue?.length) {
        if (this.activeJobId) {
          setTimeout(()=>{
            this.scrollSelectedJobIntoView(this.activeJobId)
          }, 1000);
        }

      }

    }
    
  }

  toggleIsFiltersOpen() {
    this.isFiltersOpen = !this.isFiltersOpen
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe()
    this.filterSub.unsubscribe()
  }

  openLead(lead) {
    this.dialog.open( LeadModalComponent, {
      data: {
        lead: lead
      }
    });
  }

  scrollSelectedJobIntoView(id) {
    let target = document.getElementById(id);
    if(target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }

}
