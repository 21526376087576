import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'addCreditFeesWithTaxes'
})
export class AddCreditFeesWithTaxes implements PipeTransform {

  // @TODO - remove when legacyTaxes are removed - # taxes taxRate wereLegacyTaxesIncluded

  constructor( private adminPricingService: AdminPricingService ) { }

  transform(number : number): number {
    return (number / ( 1 - (this.adminPricingService.creditPriceAdminCosts / 100) * (1 - (this.adminPricingService.taxRate / 100)) ) )
  }

}
