import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'getOrderFromPrice'
})

export class GetOrderFromPricePipe implements PipeTransform {

  transform( price ): any {
    if (price) {
      return price.toFixed() * -1
    } else {
      return 0
    }

  }

}
