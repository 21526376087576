import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'addCreditFees'
})
export class AddCreditFeesPipe implements PipeTransform {

  constructor( private adminPricingService: AdminPricingService ) {
  }

  transform(basePrice : number, creditFeeAtSign?:number): number {
    let feeToApply = creditFeeAtSign ? creditFeeAtSign : this.adminPricingService.creditPriceAdminCosts;
    return basePrice / ( 1 - ( feeToApply / 100) )
  }

}
