import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ProposalPricingService {

  constructor(
  ) {
  }


  getDisplayedPrice(cashPrice : number, psis : any) {
    const t = psis.meta.accounting.salesTax / 100;
    let f = 0;
    const b = cashPrice;

    if ( b == 0 ) {
      // If its 0
      return 0
    } else {
      if (psis.meta.accounting.feeModel == 1) {
        // Model 1 = shared fee
        let f = psis.meta.accounting.sharedFee;
        return this.addPercentage(b,f)
        // return this.buildInCreditAndTax(b,f,t)
      } else if (psis.meta.accounting.feeModel == 2) {
        // Model 2 = cash rebate
        let f = psis.meta.accounting.creditFee / 100;
        return this.addCompoundingPercentageWithTax(b,f,t)
      } else if (psis.meta.accounting.feeModel == 3) {
        // Model 3 = surcharge
        return b
      }
    }
  
  }

  addPercentage(base, percentage) {
    // adds true %
    // 10 + %1.5 = 11.5
    return base * (1 + percentage / 100);
  }

  addCompoundingPercentage(base, percentage) {
    // Adds compounding percentage 
    // Adds slightly more than the percentage so that you're still left with you're true base after removing it
    // (total of base + percentage) - percentage = base
    // I want 10,000. Credit card company wants 3.5%
    // We Show = 10,362.69 / Credit card company gets 3.5% = 362.69 / We get 10,000
    return base / ( 1 - ( percentage / 100) );
  }

  addCompoundingPercentageWithTax(b,f,t) {
    // Adds percentage + taxable portion of that percentage
    return b / (1 - f * (t + 1))
  }

  removeCompoundingPercentageWithTax(b,f,t) {
    // removes percentage + taxable portion of that percentage
    return b * (1 - f * (t + 1))
  }

  getPercentageOfNumber(number: number, percentage: number) {
    return percentage * ( number / 100 );
  }

}
