import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "@shared/services/auth.service";

@Component({
  selector: 'app-job-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  @Input() job;

  storedNotes: string;

  loading: boolean = true;
  success: boolean = false;
  error: string;


  @Output() notesDone = new EventEmitter<boolean>();

  constructor(
    private database: FirestoreService,
    public authService : AuthService) { }

  ngOnInit(): void {
    this.storedNotes = this.job.notes
  }

  async done() {
    if (this.job.notes == this.storedNotes) {
      this.closeNotes();
    } else {
      this.loading = true;
      try {
        await this.database.doc(`jobs/${this.job.meta.id}`).update({
          "notes": this.job.notes
        });
        this.success = true;
        this.loading = false;
        this.closeNotes();
      } catch (err) {
        this.error = err
      }
      this.loading = false;
    }

  }

  closeNotes() {
    this.notesDone.emit(true)
  }

}
