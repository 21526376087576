import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.scss']
})
export class LoaderOverlayComponent implements OnInit {

  @Input() loading : boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
