import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'optionFromID'
})

export class GetOptionDateFromIDPipe implements PipeTransform {

  transform(id: string, brands: any[]): any {

    for (let b in brands) {
      let options = brands[b].options;
      for(let option of options) {
          const optionMatchedID = option => option.id == id;
          let index = options.findIndex(optionMatchedID);
          if(index != -1) {
            let data = `
                  <div class="u-bold">
                    ${options[index]?.details.outdoorUnit}
                  </div>
                  <div class="u-font-size--small u-opacity--light">
                    ${options[index]?.details.indoorUnit}
                  </div>
                  <div class="u-font-size--small u-italic u-opacity--lighter">
                    ${options[index]?.details.thermostat}
                  </div>
            `;
            return data
          } else {
            // return null
          }

      }
    }

 

  }

}
