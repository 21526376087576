import { Injectable , OnDestroy } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { Observable, Subscription } from "rxjs";
import { shareReplay } from "rxjs/operators";

export interface Branding {
  colors: BrandingColors,
  company: Company,
  images: {
    proposalLogo: string
  },
  messages: {
    thanks: string;
    proposalDisclaimer: string;
    unsigned: string;
  }

}

interface BrandingColors {
  primary: string;
  primaryContrast: string;
  secondary: string;
  secondaryContrast: string;
}

interface Company {
  name: string;
  email: string;
  phone: string;
  adminCell: string;
}

@Injectable({
  providedIn: "root"
})

export class BrandingService implements OnDestroy {
  brandingSub: Subscription;

  brandingDoc: AngularFirestoreDocument;
  branding$: Observable<any>;
  primary: string;
  primaryContrast: string;
  secondary: string;
  secondaryContrast: string;
  proposalLogo: string;
  company: Company;
  colors: BrandingColors;
  messages: Branding["messages"];
  unsignText: Branding["messages"]["unsigned"];

  constructor(private afs: AngularFirestore) {

    this.branding$ = this.afs.collection("admin").doc("branding")
      .valueChanges()
      .pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );

    this.brandingSub = this.branding$.subscribe(data => {
      this.colors = data.colors;
      this.primary = data.colors.primary;
      this.primaryContrast = data.colors.primaryContrast;
      this.secondary = data.colors.secondary;
      this.secondaryContrast = data.colors.secondaryContrast;
      this.proposalLogo = data.images.proposalLogo;
      this.company = data.company;
      this.messages = data.messages;
      this.unsignText = data.messages.unsigned
    });
  }

  ngOnDestroy() {
    this.brandingSub.unsubscribe()
  }

}
