import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { FeatureService } from "@shared/services/features.service";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Feature } from '@shared/services/features.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit, OnDestroy {

  featureSub: Subscription;
  features: Feature[]
  loading: boolean = true;
  success: boolean = false;
  featuresForm : FormGroup;


  constructor(
    private featureService: FeatureService,
    private fb: FormBuilder,
    private afs: AngularFirestore
  ) { 
      this.featuresForm = this.fb.group({
        all: this.fb.array([])
      })
  }

  ngOnInit(): void {
    this.featureSub = this.featureService.features$.subscribe(
      data => (
        this.all.clear(),
        this.features = data,
        data.map( obj =>
          this.all.push(
            new FormGroup({
              name: new FormControl( obj['name'] ),
              description: new FormControl( obj['description'] ),
              shortname: new FormControl( obj['shortname'] ),
              isCheckbox: new FormControl( obj['isCheckbox'] ),
              id: new FormControl( obj['id'] ),
            })
          )
        ),
        this.loading = false
      )
    )
  }

  get all() {
    return this.featuresForm.get("all") as FormArray;
  }

  addFeature() {
    this.all.push(
      new FormGroup({
        name: new FormControl( null, Validators.required ),
        description: new FormControl( null ),
        shortname: new FormControl( null ),
        isCheckbox: new FormControl( true, Validators.required ),
        id: new FormControl( this.afs.createId(), Validators.required ),
      })
    )
  }

  deleteFeature(i:number) {
    this.all.removeAt(i)
    this.all.markAsDirty()
  }

  getSetFeatures() {
    
  }

  onSubmit() {
    this.submitHandler()
  }

  drop(event) {
    this.featuresForm.markAsDirty();
    moveItemInArray(this.all.controls, event.previousIndex, event.currentIndex);
    this.all.updateValueAndValidity()
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.featuresForm.value;
    try {
      await this.afs.doc("admin/features").update(formValue);
      this.onSuccess()
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  reset() {
    this.getSetFeatures()
    this.featuresForm.markAsPristine();
  }

  onSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
      this.featuresForm.markAsPristine()
    }, 3000);
  }


  ngOnDestroy() {
    this.featureSub.unsubscribe()
  }

}
