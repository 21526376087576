import { DateCalculationsService } from '@shared/services/date-calculations.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AddOn } from '@shared/services/add-on.service';
import { DatePipe } from '@angular/common';
import { ProposalPricingService } from '@shared/services/proposal-pricing-service';
import { ProposalChoicesService } from '../proposal-choices.service';

@Component({
  selector: 'app-proposal-choices-breakdown',
  templateUrl: './proposal-choices-breakdown.component.html',
  styleUrls: ['./proposal-choices-breakdown.component.scss']
})
export class ProposalChoicesBreakdownComponent implements OnInit {

  @Input() job;
  @Input() paymentOption;
  @Input() currentlySelectedSystem;
  @Input() enhancements : any[] = [];
  @Input() templatePromotions : any[] = [];
  @Input() customDiscounts : any[] = [];

  //////////////////////////////////////////////  
  psisAccountingDetails;
  discountsPercentagesArray = [];

  constructor(
    private dateCalculationsService : DateCalculationsService,
    private datePipe: DatePipe,
    private proposalPricingService : ProposalPricingService,
    public currentProposalChoicesService : ProposalChoicesService,
  ) { 
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    // @TODO - target changes
    this.psisAccountingDetails = this.job.psis.meta.accounting;
    this.getAllTotals()
  }


  async getAllTotals() {
    this.currentProposalChoicesService.prices$.next({})
    await this.getSetItemsTotal(); // b + a + e (in displayed price (fees) - not cash)
    await this.getSetPromotions()
    await this.getSetDiscountedItemsTotal()
    await this.getSetCFA()
    await this.getSetPretaxTotal() // 
    await this.getSetT() //
    await this.getSetNetTotal()
    await this.getSetTotal();
    await this.getSetTotalPromosAsCash() // (discountedItemsTotal - prePercentagesDiscountedItemsTotal) - d - r
  }

  // get base price total (displayed price)
  async getSetB() {
    const b = this.proposalPricingService.getDisplayedPrice(this.currentlySelectedSystem.cashPrice,this.job.psis)
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , b : b})
    return b
  }

  // required updates total (displayed price)
  async getSetA() {
    const a = await this.job?.addOns?.reduce(
      (total : number, addOn : AddOn) => {
        if ( addOn.quantity ) {
          return total += addOn.price * addOn.quantity
        }
        else {
          return total += addOn.price
        }
      }, 0
    );
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , a : this.proposalPricingService.getDisplayedPrice(a,this.job.psis)})
    return a
  }

  // selected enhancement total (displayed price)
  async getSetE() {
    const e = await this.enhancements?.reduce(
      (total : number, enhancement) => {
        return total += enhancement.price
      }, 0
    )
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , e : this.proposalPricingService.getDisplayedPrice(e,this.job.psis)})
    return e
  }

  // b + a + e (displayed price)
  async getSetItemsTotal() {
    const b = this.getSetB()
    const a = this.getSetA()
    const e = this.getSetE()

    Promise.all([b,a,e]).then( result => {
      const itemsTotal = this.currentProposalChoicesService.prices$.value.b + this.currentProposalChoicesService.prices$.value.a + this.currentProposalChoicesService.prices$.value.e;
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , itemsTotal : itemsTotal })
    })


  }
  
  async getSetPromotions() {
    this.discountsPercentagesArray = [];
    this.currentProposalChoicesService.currentlyAvailableDiscounts$.next([])
    this.currentProposalChoicesService.currentlyAvailableRebates$.next([])
    await this.getSetAutomaticDiscounts()
    await this.getSetAutomaticRebates()
    await this.getSetOptionalDiscountsRebates() // get total of custom and optional discounts
  }

  async getSetAutomaticDiscounts() {
    const automaticDiscounts = await this.templatePromotions?.reduce(
      (total : number, discount: any, index : number) => {
        if (discount.isRebate) return total // ignore it if it IS a rebate, only calculating discounts
        if (this.isDiscountCurrentlyAvailable(discount) && this.isDiscountApplicableToJobTonnage(discount)) {
          if (discount.appliesToOptions?.some(option => option == this.currentlySelectedSystem?.option)) {
            if (!discount?.isOptional) { 
              this.addCurrentlyAvailableDiscount(discount)
              if (discount.isPercentage) {
                this.discountsPercentagesArray.push(discount.amount)
                return total
              } else {
                return total += discount.amount
              }
            } else {
              // Optional, not applied
              return total += 0
            }
          } else {
            // not expired, but doesnt apply to current
            return total
          }
        } else {
          // Remove expired discount?
          // this.templatesService.removeExpiredDiscountFromTemplate(this.job.template,discount)
          return total
        }
      }, this.job?.customDiscount?.amount || 0
    );
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , d : automaticDiscounts })
    return automaticDiscounts
  }

  async getSetAutomaticRebates() {
    const automaticRebates = await this.templatePromotions?.reduce(
      (total : number, promo: any) => {
        if (!promo.isRebate) return total // ignore it if its a discount, only calculating rebates
        if (this.isDiscountCurrentlyAvailable(promo) && this.isDiscountApplicableToJobTonnage(promo)) {
          if (promo.appliesToOptions?.some(option => option == this.currentlySelectedSystem?.option)) {
            if (!promo?.isOptional) { 
              if (!promo.isPercentage) {
                this.addCurrentlyAvailableRebate(promo)
                return total += promo.amount
              } 
            } else {
              // Optional, not applied
              return total += 0
            }
          } else {
            // not expired, but doesnt apply to current
            return total
          }
        } else {
          // Remove expired discount?
          // this.templatesService.removeExpiredDiscountFromTemplate(this.job.template,discount)
          return total
        }
      }, 0
    );

    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , r : automaticRebates })
    return automaticRebates
  }

  async getSetOptionalDiscountsRebates() {

    let optionalDiscountsTotalAmount : number = 0;
    let optionalRebatesTotalAmount : number = 0;

    // Get Optional/Custom Discounts from JOB
    for await (const discount of this.customDiscounts) {
      if (this.isDiscountCurrentlyAvailable(discount) && this.isDiscountApplicableToJobTonnage(discount)) {
        // check to see if its applicable to the selected option
        if (discount.appliesToOptions?.some(option => option == this.currentlySelectedSystem?.option)) {
          if (discount?.isRebate == true) {
            if (!discount.isPercentage) {
              optionalRebatesTotalAmount += discount.amount
              this.addCurrentlyAvailableRebate(discount)
            }
          } else {
            if (discount.isPercentage) {
              this.discountsPercentagesArray.push(discount.amount)
            } else {
              optionalDiscountsTotalAmount += discount.amount
            }
            this.addCurrentlyAvailableDiscount(discount)
          }
        }
      }
    }

    const discountsTotalPercentagesAsDecimal = await this.getPercentagesArrayAsDecimal(this.discountsPercentagesArray);
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , dpad : parseFloat(discountsTotalPercentagesAsDecimal.toFixed(2)) })
    const totalDiscountsWithOptionalIncluded = this.currentProposalChoicesService.prices$.value.d += optionalDiscountsTotalAmount 
    const totalRebatesWithOptionalIncluded = this.currentProposalChoicesService.prices$.value.r += optionalRebatesTotalAmount 

    // Update d (was just automatic) with all optional discounts
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , d : totalDiscountsWithOptionalIncluded })
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , r : totalRebatesWithOptionalIncluded })
  }


  async getSetDiscountedItemsTotal() {

    const prePercentagesDiscountedItemsTotal = (this.currentProposalChoicesService.prices$.value.itemsTotal - this.currentProposalChoicesService.prices$.value.d)
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , prePercentagesDiscountedItemsTotal : prePercentagesDiscountedItemsTotal })

    const discountedItemsTotal = prePercentagesDiscountedItemsTotal * (1 - this.currentProposalChoicesService.prices$.value.dpad);
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , discountedItemsTotal : discountedItemsTotal })
  }


  async getSetCFA() {

    if (this.job.psis.meta.accounting.feeModel == 3) {
      // Surcharge model uses a different total to calculate CFA from
      const taxRate = (this.job.psis.meta.accounting.salesTax / 100)
      const creditRate = (this.job.psis.meta.accounting.creditFee / 100)
      const totalToBaseCFA = this.currentProposalChoicesService.prices$.value.discountedItemsTotal / (1 - (taxRate / (1 + taxRate) + creditRate))
      const cfa = await this.calcCFATotal(totalToBaseCFA)
      this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , cfa : cfa })
    } else {
      // Rebate and shared fee use taxedTotalNoCFA
      const taxedTotalNoCFA = this.currentProposalChoicesService.prices$.value.discountedItemsTotal * (1 + this.job.psis.meta.accounting.salesTax / 100)
      const cfa = await this.calcCFATotal(taxedTotalNoCFA)
      this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , cfa : cfa })
    }

  }

  async calcCFATotal(tot): Promise<number> {
    // CFA = Credit Fee Adjustment. This can be +/-/0 based on what the admin fee model is set to - surcharge, rebate, etc. Takes shared fee or true credit fee based on that as well

    if (this.job.psis.meta.accounting.feeModel == 3) {
      // Credit Surcharge Model (+)
      if (this.paymentOption == 'credit') {
        // if they pay credit, cfa = credit surcharge
        const cf = tot * (this.job.psis.meta.accounting.creditFee / 100)
        return cf

      } else {
        return 0
      }
    } else if (this.job.psis.meta.accounting.feeModel == 2) {
      // Cash Rebate Model (-)
      if (this.paymentOption == 'cash') {
        // if they pay or cash, it should be a cash rebate
        let cf = tot * (this.job.psis.meta.accounting.creditFee / 100)
        return cf *= -1
      } else {
        return 0
      }
    } else {
      // Fee model == 1, then there is no fee adjustment, the fee is built into the base prices
      return 0
    }
  }

  async getSetPretaxTotal() {
    const pretaxTotal = this.currentProposalChoicesService.prices$.value.discountedItemsTotal + this.currentProposalChoicesService.prices$.value.cfa;
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , pretaxTotal : pretaxTotal })
  }

  async getSetT() {
    const tax = this.proposalPricingService.getPercentageOfNumber(this.currentProposalChoicesService.prices$.value.pretaxTotal,this.job.psis.meta.accounting.salesTax)
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , t : tax})
  }

  async getSetNetTotal() {
    const netTotal = this.currentProposalChoicesService.prices$.value.pretaxTotal + this.currentProposalChoicesService.prices$.value.t 
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , netTotal : netTotal })
  }

  async getSetTotal() {
    const total = this.currentProposalChoicesService.prices$.value.pretaxTotal + this.currentProposalChoicesService.prices$.value.t - this.currentProposalChoicesService.prices$.value.r
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , total : total })
  }

  async getSetTotalPromosAsCash() {
    const totalPromosAsCash = (this.currentProposalChoicesService.prices$.value.discountedItemsTotal - this.currentProposalChoicesService.prices$.value.prePercentagesDiscountedItemsTotal) - this.currentProposalChoicesService.prices$.value.d - this.currentProposalChoicesService.prices$.value.r
    const totalDiscountsAsCash = this.currentProposalChoicesService.prices$.value.discountedItemsTotal * this.currentProposalChoicesService.prices$.value.dpad / (1 - this.currentProposalChoicesService.prices$.value.dpad) + this.currentProposalChoicesService.prices$.value.d

    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , totalPromosAsCash : totalPromosAsCash })
    this.currentProposalChoicesService.prices$.next({ ...this.currentProposalChoicesService.prices$.value , totalDiscountsAsCash : totalDiscountsAsCash })
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // Helpers
  
  async getPercentagesTotalProduct(percentages: number[]): Promise<number> {
    let product = 1;
    for (const percentage of percentages) {
      const decimalPercentage = percentage / 100;
      product *= 1 - decimalPercentage;
    }
    return product;
  }

  async getPercentagesArrayAsDecimal(percentages: number[]): Promise<number> {
    const product = await this.getPercentagesTotalProduct(percentages);
    const finalResult = 1 - product;
    return finalResult;
  }

  addCurrentlyAvailableDiscount(discount) {
    const currentDiscounts = this.currentProposalChoicesService.currentlyAvailableDiscounts$.value;
    const updatedDiscounts = [...(currentDiscounts || []), discount];
    this.currentProposalChoicesService.currentlyAvailableDiscounts$.next(updatedDiscounts);
  }

  addCurrentlyAvailableRebate(rebate) {
    const currentRebates = this.currentProposalChoicesService.currentlyAvailableRebates$.value;
    const updatedRebates = [...(currentRebates || []), rebate];
    this.currentProposalChoicesService.currentlyAvailableRebates$.next(updatedRebates);
  }

  isDiscountCurrentlyAvailable(discount) {
    const today = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    if (discount?.enabledDate && discount?.expirationDate) {
      return this.dateCalculationsService.isDateWithinRange(today,discount.enabledDate,discount.expirationDate)
    } else if (discount?.enabledDate == null && discount?.expirationDate == null) {
      return true
    } else if (discount?.enabledDate && !discount?.expirationDate){
      return this.dateCalculationsService.isDateGreaterThan(today,discount.enabledDate)
    } else if (discount?.expirationDate && !discount?.enabledDate ){
      return this.dateCalculationsService.isDateLessThan(today,discount.expirationDate)
    } else {
      return true
    }
  }

  isDiscountApplicableToJobTonnage(discount) {
    if (!discount?.compatibleOptionTonnages?.length) return true // No tonnage specific details, assumed to be applicable

    const compatibleOption = discount?.compatibleOptionTonnages.find(item => item.id == this.currentlySelectedSystem?.option)
    if (!compatibleOption) return false // Option isnt compatible at all, return false

    const jobTonnage = parseFloat(this.job.tonnage)
    const isExcluded = compatibleOption?.excludedTonnages.includes(jobTonnage)

    if (isExcluded) {
      return false // job tonnage is excluded
    } else {
      return true // job tonnage is not in excluded tonnages list, its applicable
    }
  }

}
