import { Component, OnInit, OnDestroy } from '@angular/core';
import { BrandingService } from '@shared/services/branding.service';
import { Observable,Subscription } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@shared/services/auth.service";

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit, OnDestroy {

  routeSub: Subscription;
  proposal : string;

  constructor(
    private route: ActivatedRoute,
    public authService : AuthService,
    public brandingService : BrandingService
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      if(this.route.snapshot.queryParamMap.get('proposal')||0) {
        this.proposal = this.route.snapshot.queryParamMap.get('proposal');
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe()
  }

}
