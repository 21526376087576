export default {

  apiKey: "AIzaSyBBOCcTlflZU1-ixG_dUdi3mtCz-zHXeMo",

  authDomain: "uba-mechanical-ef9j.firebaseapp.com",

  projectId: "uba-mechanical-ef9j",

  storageBucket: "uba-mechanical-ef9j.appspot.com",

  messagingSenderId: "252220165684",

  appId: "1:252220165684:web:fbf20daa4e6e3a5ea98461"

};
