import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { AddOnService } from "@shared/services/add-on.service";
import { take } from "rxjs/operators";
import { QueueToggleService } from '@shared/services/queue-toggle.service';
import { Subscription } from "rxjs";
import { AdminPricingService } from '@shared/services/admin-pricing.service';

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss']
})
export class AddOnsComponent implements OnInit, OnDestroy {

  addOnSub: Subscription;
  loading: boolean = true;
  success: boolean = false;
  addOnsForm : FormGroup;

  activeGroup: string = 'ductWork';

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private addOnService : AddOnService,
    public queueToggleService: QueueToggleService,
    public router : Router,
    public adminPricing : AdminPricingService
  ) {

    this.addOnsForm = this.fb.group({
      ductWork: this.fb.array([]),
      utilities: this.fb.array([]),
      equipmentInstallation: this.fb.array([]),
      structuralAndMisc: this.fb.array([])
    })
    this.getSetAddOns()
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.addOnSub.unsubscribe()
  }

  setActiveGroup(group:string) {
    this.activeGroup = group;
    this.queueToggleService.closeQueueIfMobile()
  }

  getSetAddOns() {
    this.addOnSub = this.addOnService.addOns$.pipe(take(1)).subscribe(
      addOns => (
        this.ductWork.clear(),
        this.utilities.clear(),
        this.structuralAndMisc.clear(),
        this.equipmentInstallation.clear(),

        addOns.ductWork.map( obj =>
          this.ductWork.push(
            new FormGroup({
              name: new FormControl( obj['name'] ),
              price: new FormControl( obj['price'] ),
              c: new FormControl( obj['c'] ),
              h: new FormControl( obj['h'] ),
              p: new FormControl( obj['p'] != null ? obj['p'] : this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
            })
          )
        ),
        addOns.utilities.map( obj =>
          this.utilities.push(
            new FormGroup({
              name: new FormControl( obj['name'] ),
              price: new FormControl( obj['price'] ),
              c: new FormControl( obj['c'] ),
              h: new FormControl( obj['h'] ),
              p: new FormControl( obj['p'] != null ? obj['p'] : this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
            })
          )
        ),
        addOns.structuralAndMisc.map( obj =>
          this.structuralAndMisc.push(
            new FormGroup({
              name: new FormControl( obj['name'] ),
              price: new FormControl( obj['price'] ),
              c: new FormControl( obj['c'] ),
              h: new FormControl( obj['h'] ),
              p: new FormControl( obj['p'] != null ? obj['p'] : this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
            })
          )
        ),
        addOns.equipmentInstallation.map( obj =>
          this.equipmentInstallation.push(
            new FormGroup({
              name: new FormControl( obj['name'] ),
              price: new FormControl( obj['price'] ),
              c: new FormControl( obj['c'] ),
              h: new FormControl( obj['h'] ),
              p: new FormControl( obj['p'] != null ? obj['p'] : this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
            })
          )
        ),
        this.loading = false
      )
    )
  }

  async getSetAddOnPrice(group: string, i:number) {
    const target = this.addOnsForm.get(`${group}.` + i) as FormGroup;
    const value = target.value
    const recommendedPrice = await this.adminPricing.getRecommendedItemPrice(value.c,value.h,value.p)
    target.patchValue( {
      price: recommendedPrice,
    })
  }

  get ductWork() {
    return this.addOnsForm.get("ductWork") as FormArray;
  }

  get utilities() {
    return this.addOnsForm.get("utilities") as FormArray;
  }

  get equipmentInstallation() {
    return this.addOnsForm.get("equipmentInstallation") as FormArray;
  }

  get structuralAndMisc() {
    return this.addOnsForm.get("structuralAndMisc") as FormArray;
  }

  addAddOn(group: string) {
    const array = this.addOnsForm.get(group) as FormArray;
    const newAddOn = new FormGroup({
      name: new FormControl( null, Validators.required ),
      price: new FormControl( null, Validators.required ),
      c: new FormControl(null),
      h: new FormControl(null),
      p: new FormControl(this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
    });
    array.insert(0,newAddOn)
    array.markAsDirty()
  }

  removeAddOn(group: string, i:number) {
    const array = this.addOnsForm.get(group) as FormArray;
    array.removeAt(i)
    array.markAsDirty()
  }

  reset() {
    this.getSetAddOns()
    this.addOnsForm.markAsPristine();
  }

  // Submit

  onSubmit() {
    this.submitHandler()
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.addOnsForm.value;
    try {
      await this.afs.doc("addOns/all").update(formValue);
      this.onSuccess()
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  onSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
      this.addOnsForm.markAsPristine()
    }, 3000);
  }


}
