
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DartboardComponent } from "./dartboard.component";
import { NewComponent } from "./new/new.component";
import { NewLeadComponent } from "./new-lead/new-lead.component";
import { AllJobsComponent } from './all/all-jobs.component';
import { TableComponent } from './table/table.component';

import { AuthGuard } from "@shared/guards/auth.guard";
import { CutsheetViewComponent } from "./cutsheet-view/cutsheet-view.component";

const routes: Routes = [
  {
    path: "",
    component: DartboardComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "all"
      },
      {
        path: "table",
        pathMatch: "full",
        component: TableComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "all",
        pathMatch: "full",
        component: AllJobsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "all/:id",
        pathMatch: "full",
        component: AllJobsComponent
      },
      {
        path: "cutsheet/:id",
        pathMatch: "full",
        component: CutsheetViewComponent
      },
      {
        path: "view/:id",
        pathMatch: "full",
        component: AllJobsComponent
      },
      {
        path: "new",
        pathMatch: "full",
        component: NewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "newLead",
        pathMatch: "full",
        component: NewLeadComponent,
        canActivate: [AuthGuard],
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DartboardRoutingModule {}
