import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "@angular/fire/firestore";
import { FirestoreService } from '@shared/services/firestore.service';

import { Observable, of } from "rxjs";
import { switchMap, tap, shareReplay } from "rxjs/operators";

// import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


export interface User {
  displayName: string;
  email: string;
  firstName: string;
  isOnline: boolean;
  lastName: string;
  phone: string;
  photoUrl: string;
  tier: string;
  title: string;
  uid: string;
}

@Injectable({ providedIn: "root" })
export class AuthService  {
  isLoggedIn: boolean = false;
  errorMessage;
  user$: Observable<any>;
  usersCollection: AngularFirestoreCollection<User>;
  users$: Observable<User[]>;

  user;
  isUserAdmin: boolean = false;
  isUserSuperAdmin: boolean = false;
  isUserAdminOrConsultant: boolean = false;
  isUserOfficeStaff: boolean = false;
  isUserConsultant: boolean = false;

  constructor(
    public firebaseAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private database : FirestoreService,
    private router: Router
  ) {
    // Get the auth state, then fetch the Firestore user document or return null
    this.users$ = this.database.col$('users');

    this.user$ = this.firebaseAuth.authState.pipe(
      switchMap(user => {
        // Logged in
        if (user) {
          return this.database.doc$(`users/${user.uid}`).pipe(
            shareReplay({bufferSize: 1, refCount: true}),
            tap( user =>  {
                this.user = user;
                this.isUserAdmin = this.isAdmin(this.user)
                this.isUserSuperAdmin = this.isSuperAdmin(this.user)
                this.isUserAdminOrConsultant = this.isAdminOrConsultant(this.user)
                this.isUserOfficeStaff = this.isOfficeStaff(this.user)
                this.isUserConsultant = this.isConsultant(this.user)
              }
            )
          );
        } else {
          // Logged out
          this.isLoggedIn = false;
          // this.signOut();
          return of(null);
        }
      })
    ), shareReplay({bufferSize: 1, refCount: true})
    ;
  }

  async signIn(email: string, password: string) {
    await this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        this.isLoggedIn = true;
        this.router.navigate(["/home"]);
      })
      .catch(error => {
        console.log(error);
        this.isLoggedIn = false;
        this.errorMessage = error.message;
      });
  }

  signOut() {
    this.firebaseAuth.signOut();
    this.router.navigate(["/login"]);
    this.isLoggedIn = false;
  }

  ///// Role-based Authorization //////

  isUser(user: User): boolean {
    const tiers = ["3", "2", "1", "0"];
    return this.checkAuthorization(user, tiers);
  }

  isAdmin(user: User): boolean {
    const tiers = ["1", "0"];
    return this.checkAuthorization(user, tiers);
  }

  isAdminOrConsultant(user: User): boolean {
    const tiers = ["2", "1", "0"];
    return this.checkAuthorization(user, tiers);
  }

  isConsultant(user: User): boolean {
    const tiers = ["2"];
    return this.checkAuthorization(user, tiers);
  }

  isOfficeStaff(user: User): boolean {
    const tiers = ["3"];
    return this.checkAuthorization(user, tiers);
  }

  isSuperAdmin(user: User): boolean {
    const tiers = ["0"];
    return this.checkAuthorization(user, tiers);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedTiers: string[]): boolean {
    if (!user) return false;
    for (const tier of allowedTiers) {
      if (user.tier == tier) {
        return true;
      }
    }
    return false;
  }

  // async createUser() {
  //   this.firebaseAuth.createUserWithEmailAndPassword('jcgilmore2@gmail.com','password1').then((userCredential) => {
  //     // Signed in 
  //     var user = userCredential.user;
  //     // ...
  //     console.log('Successfully created new user:', userCredential);

  //   })
  //   .catch((error) => {
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     console.log('Error creating new user:', error);

  //     // ..
  //   });

  //   // this.firebaseAuth.getAuth()
  //   // .createUser({
  //   //   email: 'user@example.com',
  //   //   emailVerified: false,
  //   //   phoneNumber: '+11234567890',
  //   //   password: 'secretPassword',
  //   //   displayName: 'John Doe',
  //   //   photoURL: 'http://www.example.com/12345678/photo.png',
  //   //   disabled: false,
  //   // })
  //   // .then((userRecord) => {
  //   //   // See the UserRecord reference doc for the contents of userRecord.
  //   //   console.log('Successfully created new user:', userRecord.uid);
  //   // })
  //   // .catch((error) => {
  //   //   console.log('Error creating new user:', error);
  //   // });
  //   }



}
