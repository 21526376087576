import { Component, OnDestroy } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { JobsService } from '@shared/services/jobs.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: {
    class: 'page-content-main'
  }
})

export class HomeComponent implements OnDestroy {
  jobsSub : Subscription;
  jobsWidgetItems: any[];

  constructor(
    public authService : AuthService,
    private jobsService : JobsService
  ) { 
    this.jobsSub = this.jobsService.viewableJobsCondensed$.subscribe( (jobs) : any => {
      if (jobs.length > 5) {
        this.jobsWidgetItems = jobs.slice(0,5);
      } else {
        this.jobsWidgetItems = jobs;
      }
    }
    )
  }

  ngOnDestroy(): void {
      this.jobsSub.unsubscribe()
  }


}
