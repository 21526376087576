import { ProposalPricingService } from '@shared/services/proposal-pricing-service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TemplatesService, Template, SelectedSystem } from "@shared/services/templates.service";
import{ DateCalculationsService } from "@shared/services/date-calculations.service";
import { DatePipe, DecimalPipe } from '@angular/common';
import { AdminPricingService } from "@shared/services/admin-pricing.service";
import { Observable, Subscription} from "rxjs";
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeatureDescriptionModalComponent } from '@shared/components/customer-comparison-grid/feature-description-modal/feature-description-modal.component';
@Component({
  selector: 'app-psis-comparison-grid-option',
  templateUrl: './psis-comparison-grid-option.component.html',
  styleUrls: ['./psis-comparison-grid-option.component.scss']
})
export class PsisComparisonGridOptionComponent implements OnInit {

  @Output() systemSelected = new EventEmitter<object>();
  @Input() system : SelectedSystem;
  @Input() psis;
  @Input() paymentOption;
  @Input() selectedFinancingOption;
  @Input() currentlySelectedSystemId;
  @Input() seerFilterFrom;
  @Input() seerFilterTo;
  @Input() features;
  @Input() o;
  @Input() d;

  public loading: boolean = true;

  pricingSub : Subscription;

  systemCashTotal: number;
  systemEstMonthlyPayment: number;

  r: number; // tax rate
  taxRate: number; // tax rate

  constructor(
    public adminPricingService : AdminPricingService,
    public templatesService : TemplatesService,
    private proposalPricingService : ProposalPricingService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.r = 0

      // this.pricingSub = this.adminPricingService.accounting$.subscribe( data => {
      //   // this.r = data.taxRate; // @TODO Florida tax is 0 atm # tax taxRate 
      //   this.r = 0; // @TODO Florida tax is 0 atm
      // });

    if (this.paymentOption == 'financing' && this.selectedFinancingOption) {
      'setting initial'
      this.getEstMonthlyPayment();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedFinancingOption']?.currentValue != changes['selectedFinancingOption']?.previousValue) {
      this.getEstMonthlyPayment();
    }
  }

  selectSystem() {
    this.systemSelected.emit(this.system)
  }

    // Calculate the monthly payment for a financing option
  async getEstMonthlyPayment() {
    let fin = this.selectedFinancingOption;
    const b = this.proposalPricingService.getDisplayedPrice(this.system.cashPrice, this.psis)
    const p = b; // p set to straight price, cant add fees anymore
    let finr = fin.apr / 1200; // divide by 100 to convert to decimal and by 12 to get monthly rate

    // 0% APR and equal payments
    if (fin.apr == 0 && !fin.min) {
      this.systemEstMonthlyPayment = p / fin.months;

    // Not 0% APR but equal payments
    } else if (!fin.min) {
      this.systemEstMonthlyPayment = p * finr * Math.pow(1 + finr,fin.months) / (Math.pow(1 + finr, fin.months) - 1);

    // Non-equal payments (ability to make smaller minimum payments)
    } else if (fin.min) {
      this.systemEstMonthlyPayment = p * fin.min / 100;
    }

  }


  openDialog(feature) {
    if (feature.description) {
      this.dialog.open( FeatureDescriptionModalComponent, {
        data: {
          description: feature.description,
          name: feature.name
        }
      });
    }
  }

  ngOnDestroy() {
    // this.pricingSub?.unsubscribe()
  }

}
