import { Injectable, OnDestroy  } from '@angular/core';
import { Observable, Subscription, of } from "rxjs";
import { tap , take, shareReplay} from 'rxjs/operators';
import { FirestoreService } from '@shared/services/firestore.service';

export interface Proposal {
  createdAt: {
    seconds: number,
    nanoseconds: number
  }
  createdBy: string;
  description: {
    brand: string,
    name: string,
    type: string
  }
  discounts? : any[],
  enhancements? : any[],
  files? : any[],
  options? : Option[],
  id? : string,
  status? : string,
  customerMessage? : string,
  tonnages? : any[],
  labels? : {
    a?: string,
    r2?: string,
    b?: string,
    c?: string,
    r1?: string
  }
  draft?: boolean;
}

export interface Option {
  details: {
    outdoorUnit: string;
    outdoorUnitBrochure?: string;
    indoorUnit: string;
    indoorUnitBrochure?: string;
    thermostat: string;
    thermostatBrochure?: string;
    warranty?: string;
    hoursToInstall: number;
    materialCost: number;
    netProfit?: number;
  },
  features? : any[],
  sizes: Tonnage[]
}

export interface Tonnage {
  isEnabled: boolean;
  tons: number;
  seer: number;
  cost: number;
  cashPrice: number;
}

export interface SelectedSystem {
    option: string,
    brand? : string,
    hoursToInstall: number,
    indoorUnit: string,
    indoorUnitBrochure?: string,
    materialCosts: string,
    netProfit?: string,
    outdoorUnit: string,
    outdoorUnitBrochure?: string,
    thermostat: string,
    thermostatBrochure?: string,
    warranty?: string,
    features?: any[],
    tonnage: string,
    cashPrice: number,
    seer: number,
    cost: number,
    label?: string,
    rebates?: any[],
    rebatesTotal?: number
}

@Injectable({
  providedIn: 'root'
})
export class ProposalsService implements OnDestroy {
  proposalsAllSub: Subscription;
  proposals$: Observable<any[]> = of([]);
  proposalsAll$: Observable<any[]> = of([]);
  proposalsArchived$: Observable<any[]> = of([]);

  proposals = [];

  constructor(
    private afs : FirestoreService
  ) {

    //TODO - read proposals all once, then filter to set the other two instead of reading again?

    this.proposals$ = this.afs.col$('proposals', ref => ref
      .where('status', '==', 'active')
    ).pipe( shareReplay({bufferSize: 1, refCount: true}) );

    this.proposalsAll$ = this.afs.col$('proposals')
    .pipe( shareReplay({bufferSize: 1, refCount: true}));

    this.proposalsArchived$ = this.afs.col$('proposals', ref => ref
      .where('status', '==', 'archived')
    ).pipe( shareReplay({bufferSize: 1, refCount: true}) );

    this.proposalsAllSub = this.proposalsAll$.subscribe(
      proposals => this.proposals = proposals
    ), shareReplay({bufferSize: 1, refCount: true})
  }


  getProposal$(id:string) {
    // const proposal = this.afs.doc$('proposals/' + id);
    const proposal = this.proposals.find(proposal => proposal.id == id);
    if (proposal) {
      return of(proposal);
    } else {
      console.log(`No proposal$ found with ID ${id}. It may have been deleted, or may be a new template. Checking new templates`)
      return of(false)
    }
  }

    ngOnDestroy() {
      this.proposalsAllSub.unsubscribe()
    }

}
