import { shareReplay } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { Enhancement } from './products.service';
import { FirestoreService } from '@shared/services/firestore.service';
import { Injectable } from '@angular/core';
import { SelectedSystem } from './templates.service';

interface BrandGroup {
  brand: string;
  formattedOptions: SelectedSystem[];
  positions: any[];
  labels: any;
}

interface JobProposalBlock {
  meta: {
    createdAt: number;
    expiresAt: number;
    template: string;
    owner: string;
    job: string;
    proposalGoodForDays: number;
    accounting: {
      feeModel : number;
      creditFee : number;
      sharedFee : number;
      salesTax : number;
    }
  };
  brands : BrandGroup[];
  enhancements : any[];
}


@Injectable({
  providedIn: 'root'
})



export class CustomerProposalService {
  proposalGoodForDays : number;
  accounting;

  constructor(
    private database: FirestoreService,
    private adminPricingService : AdminPricingService
  ) {

    this.adminPricingService.accounting$.pipe(
      shareReplay({bufferSize: 1, refCount: true})
      ).subscribe( data => {
        this.proposalGoodForDays = data.proposalGoodForDays;
        this.accounting = {
          feeModel : data.creditFeeSetting.applyAs,
          creditFee : data.creditPriceAdminCosts,
          sharedFee : data.creditFeeSetting.percentage,
          salesTax : data.proposalSalesTax
        }
      })
  }

  async createNewProposalAndUpdateJob(job, template) {

    if (!template) return

    const psis = await this.createNewProposal(job, template)
    try {
      this.database.doc(`jobs/${job.meta.id}`).update({
        psis : psis,
        system : null,
        enhancements: [],
      })
    } catch (err) {
    }
    

  }

  async renewProposalExpiration(job) {
    const date = new Date();
    try {
      this.database.doc(`jobs/${job.meta.id}`).update({
        'psis.meta.createdAt' : Date.now(),
        'psis.meta.expiresAt': date.setDate(date.getDate() + this.proposalGoodForDays),
      })
    } catch (err) {
    }
  }

 async createNewProposal(job,template) : Promise<JobProposalBlock> {

    const date = new Date();

    let newProposal = {
      meta : {
        createdAt : Date.now(),
        expiresAt: date.setDate(date.getDate() + this.proposalGoodForDays),
        proposalGoodForDays : this.proposalGoodForDays,
        template: job?.template,
        owner: job?.meta?.createdBy,
        job: job?.meta?.id,
        accounting: this.accounting
      },
      brands : [],
      enhancements: [...template.enhancements]
    }

    const tonnage = job.tonnage;

    // template.brands.forEach( async (brand, b) => {
    for await (const brand of template.brands) {

      let proposalBrand = {
        brand: brand.brand,
        labels : brand.labels,
        formattedOptions: [],
        positions: [...brand.positions],
      }
      
      for await (const position of brand.positions) {
        if (position == null) {
          proposalBrand.formattedOptions.push(null)
        } else {
          let option = brand.options.find(option => option.id == position)
          if ( option ) {
            const b = template.brands.findIndex( item => item.brand.toLowerCase() == brand.brand.toLowerCase())
            if(b != -1) {
              let formattedOption = await this.formatSystem(option, job, template, b);
              // newProposal.brands[b].options.push(formattedOption)     
              proposalBrand.formattedOptions.push(formattedOption)
            } else {
            }
          }
        }
      }

      newProposal.brands.push(proposalBrand)
    };

    return newProposal

  }


    async formatSystem(option, job, template, b) {

      const rebates = template.discounts.filter(
        discount => discount.appliesToOptions.includes(option.id)
      );

      let jobTonnage = parseFloat(job.tonnage);

      if (option.sizes.some(size => size.tons == jobTonnage)) {
        // console.log(`there is an exact tonnage for ${jobTonnage}`)
        const size = option.sizes.find(size => size.tons == jobTonnage);
        let item : SelectedSystem = {
          option: option.id,
          brand: template.brands[b].brand.toLowerCase(),
          hoursToInstall: option.details.hoursToInstall,
          indoorUnit:  size.override ? size.override.indoorUnit : option.details.indoorUnit,
          indoorUnitBrochure:  size.override ? size.override.indoorUnitBrochure : option.details.indoorUnitBrochure,
          materialCosts: option.details.materialCosts,
          outdoorUnit: size.override ? size.override.outdoorUnit : option.details.outdoorUnit,
          outdoorUnitBrochure: size.override ? size.override.outdoorUnitBrochure : option.details.outdoorUnitBrochure,
          thermostat:  size.override ? size.override.thermostat : option.details.thermostat,
          thermostatBrochure:  size.override ? size.override.thermostatBrochure : option.details.thermostatBrochure,
          warranty: option.details.warranty,
          links: option?.links || [],
          features: option.features,
          comparisonFeatures: option?.comparisonFeatures ? option?.comparisonFeatures : [],
          tonnage: job.tonnage,
          cashPrice: size.cashPrice,
          seer: size.seer,
          cost: size.cost,
          db: size?.db,
          hspf: size?.hspf,
          afue: size?.afue,
          label: this.getLabels(template, b, option.meta.row, option.meta.col),
          meta: option.meta
        }
        return item;
      } else if (!option?.isDisableRounding && option.sizes.some(size => size.tons == Math.ceil(jobTonnage))) {
        // console.log('We allow rounding!, and we found a match!')
        const size = option.sizes.find(size => size.tons == Math.ceil(jobTonnage));
        let item : SelectedSystem = {
          option: option.id,
          brand: template.brands[b].brand.toLowerCase(),
          hoursToInstall: option.details.hoursToInstall,
          indoorUnit:  size.override ? size.override.indoorUnit : option.details.indoorUnit,
          indoorUnitBrochure:  size.override ? size.override.indoorUnitBrochure : option.details.indoorUnitBrochure,
          materialCosts: option.details.materialCosts,
          outdoorUnit: size.override ? size.override.outdoorUnit : option.details.outdoorUnit,
          outdoorUnitBrochure: size.override ? size.override.outdoorUnitBrochure : option.details.outdoorUnitBrochure,
          thermostat:  size.override ? size.override.thermostat : option.details.thermostat,
          thermostatBrochure:  size.override ? size.override.thermostatBrochure : option.details.thermostatBrochure,
          warranty: option.details.warranty,
          links: option?.links || [],
          features: option.features,
          comparisonFeatures: option?.comparisonFeatures ? option?.comparisonFeatures : [],
          tonnage: Math.ceil(jobTonnage).toString(),
          cashPrice: size.cashPrice,
          seer: size.seer,
          cost: size.cost,
          db: size?.db,
          hspf: size?.hspf,
          afue: size?.afue,
          label: this.getLabels(template, b, option.meta.row, option.meta.col),
          meta: option.meta
        }
        return item;
      } else {
        // console.log('no option at this tonnage')
        // no option in this tonnage
        return null
      }

  }

  getLabels(template,b,r,c) {
    let rowLabel = template.brands[b].labels?.rows[r - 1];
    let columnLabel = template.brands[b].labels?.columns[c - 1];
    if (rowLabel && columnLabel) {
      return rowLabel + " | " + columnLabel
    } else if (rowLabel && !columnLabel) {
      return rowLabel
    } else if (!rowLabel && columnLabel) {
      return columnLabel
    } else {
      return 'Option'
    }

  }

}
