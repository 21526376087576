import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {

  userSub: Subscription;

  loginForm: FormGroup;
  errorMessage: string;
  isPeekPassword: boolean = false;
  isResetPassword: boolean = false;

  constructor(
    public authService: AuthService,
    public fb: FormBuilder,
    private router: Router
  ) {

    this.userSub = this.authService.user$.subscribe(
      user => {
        if (user) {
          setTimeout(() => {
            this.router.navigate(["/home"]);
            console.log('logged in, navigating home')
          }, 1500);
        }
      }
    )
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });
  }

  async onSubmit() {
    await this.authService.signIn(
      this.loginForm.value.email,
      this.loginForm.value.password
    );
    if (this.authService.isLoggedIn) {
      this.router.navigate(["/home"]);
    } else this.errorMessage = this.authService.errorMessage;
  }


  toggleResetPassword() {
    this.router.navigate(["/resetPassword"]);
  }

  togglePeekPassword() {
    this.isPeekPassword = !this.isPeekPassword
  }

  ngOnDestroy() {
    this.userSub.unsubscribe()
  }

}
