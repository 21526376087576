import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input() jobs : any[];
  soldJobs: any[] = []

  numberLost : number = 0;
  numberSold : number = 0;
  numberPending : number = 0;
  constructor() { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jobs'].currentValue != changes['jobs'].previousValue) {
      if (changes['jobs'].currentValue) this.setUp()
    }
  }

  async setUp() {
    this.resetTotals()
    await this.getTotals()
  }

  async getTotals() {
    this.jobs?.forEach( job => {
      if (job.status == 'sold') {
        this.numberSold += 1
        this.soldJobs.push(job)
      } else if (job.status == 'lost') {
        this.numberLost += 1
      } else if (job.status == 'pending') {
        this.numberPending += 1
      }
    })
    return true
  }

  resetTotals() {
    this.soldJobs = []
    this.numberSold = 0;
    this.numberLost = 0;
    this.numberPending = 0;
  }

}
