import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'IsOptionAddedToDiscount'
})

export class IsOptionAddedToDiscount implements PipeTransform {

  transform(optionId: any, discount): boolean {

    if (!discount || !discount?.compatibleOptionTonnages?.length ) return false

    if(discount?.compatibleOptionTonnages.some(i => i.id == optionId)) {
        return true
      } else {
        return false
      }

  }

}
