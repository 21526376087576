import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDisplayPriceFromPsis'
})
export class GetDisplayPriceFromPsis implements PipeTransform {

  transform(cashPrice : number, psis : any) {
    const t = psis.meta.accounting.salesTax / 100;
    let f = 0;
    const b = cashPrice;

    if (cashPrice == 0) {
      return 0
    } else {
      if (psis.meta.accounting.feeModel == 1) {
        // Model 1 = shared fee
        let f = psis.meta.accounting.sharedFee;
        return this.addPercentageToTotal(b,f)

      } else if (psis.meta.accounting.feeModel == 2) {
        // Model 2 = cash rebate
        let f = psis.meta.accounting.creditFee / 100;
        return b / (1 - f * (t + 1))

      } else if (psis.meta.accounting.feeModel == 3) {
        // Model 3 = surcharge
        return b
      }
    }
  }

  addPercentageToTotal(base, percentage) {
    return base * (1 + percentage / 100);
  }

}
