import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateCalculationsService {

  constructor() { }

  public isDateGreaterThan(a,b) {

    let D_1 = a.split("-");
    let D_2 = b.split("-");

    let d1 = new Date(D_1[0], parseInt(D_1[1]) - 1, D_1[2]);
    let d2 = new Date(D_2[0], parseInt(D_2[1]) - 1, D_2[2]);

    if (d1 > d2) {
      console.log(true)
      return true
    } else {
      console.log(false)
       return false
    }
  }

  public isDateLessThan(a,b) {

    let D_1 = a.split("-");
    let D_2 = b.split("-");

    let d1 = new Date(D_1[0], parseInt(D_1[1]) - 1, D_1[2]);
    let d2 = new Date(D_2[0], parseInt(D_2[1]) - 1, D_2[2]);

    if (d1 < d2) {
      return true
    } else {
       return false
    }
  }


  public isDateWithinRange(dateToCheck,startDate,endDate) {

    let D_1 = dateToCheck.split("-");
    let D_2 = startDate.split("-");
    let D_3 = endDate.split("-");

    let d1 = new Date(D_1[0], parseInt(D_1[1]) - 1, D_1[2]);
    let d2 = new Date(D_2[0], parseInt(D_2[1]) - 1, D_2[2]);
    let d3 = new Date(D_3[0], parseInt(D_3[1]) - 1, D_3[2]);

    if (d1 > d2 && d1 < d3) {
      return true
    } else if (d1.toString() === d2.toString() || d1.toString() === d3.toString()){
      return true
    } else {
       return false
    }
  }
}
