import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirestoreService } from "@shared/services/firestore.service"
import { finalize, tap, map, take, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  messageSub: Subscription;

  @Input() job;
  @Input() template;
  @Input() isOpen;
  @Output() done = new EventEmitter<any>();

  messageType: string = "text";

  currentJobId : string;
  // isReminder: boolean = false;

  to: string;
  subject: string;
  cc: string;
  body: string;

  constructor(
    private database: FirestoreService,
    private functions: AngularFireFunctions,
  ) {
  }

  ngOnInit(): void {
    this.to = this.formatPhoneNumber(this.job.info.phone) //this.formatPhoneNumber('304-543-6521') //this.formatPhoneNumber(this.job.info.phone)
    this.currentJobId = this.job.meta.id;
    this.setMessages()
  }

  ngOnDestroy() {
    this.messageSub?.unsubscribe()
  }

  formatPhoneNumber(number:string) {
    let primary = number.replace(/[-()' ']/g,"");
    return `+1${primary}`
  }

  setMessages() {
    if (this.job?.sent) {
      this.subject = 'REMINDER';
      this.body = `REMINDER: Don't forget to view your proposal. Your proposal can be seen at ${window.location.href}?presenting='true' - Please do not reply to this message.`
    } else {
      this.subject = 'PROPOSAL';
      this.body = `PROPOSAL: View your proposal at ${window.location.href}?presenting='true' - Please do not reply to this message.`
    }
  }

  async setJobAsSent() {
    try {
      this.database.doc(`jobs/${this.currentJobId}`).update({
        'sent': true,
        'meta.proposalSent': Date.now()
      });
    } catch (err) {
      console.log(err)
    }
  }

  close() {
    this.isOpen = false;
    this.done.emit(false)
  }

  send() {
    const callable = this.functions.httpsCallable('textMessage');
    callable({
        to : this.to, //this.formatPhoneNumber(this.job.info.phone)
        body: this.body
      }).pipe(take(1)).subscribe();
      this.setJobAsSent()
      this.done.emit(true)
  }

  async sendEmail() {
    await this.setJobAsSent()
    this.done.emit(true)
  }

}
