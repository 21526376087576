import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlliSn9TckFiXXtddHE=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpSdURjXXtfdHBRQWU=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0ZiWXtacXBRQGFZ;MTcxMjIzM0AzMjMxMmUzMTJlMzMzNVNuU05GOXlrNEplODhjem5WUmZRb1RtQmpiOEYzNEYxejd4LzFuNG5yZEE9;MTcxMjIzNEAzMjMxMmUzMTJlMzMzNWtRRjBGRlpybDBaUnl4aHNDYkVNVmN3WmdJaXR0ZWZrRGd5clRjWWU2T1k9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckRgWH9adHVRQ2NdVA==;MTcxMjIzNkAzMjMxMmUzMTJlMzMzNW4wbjdFY3Jma21SYXZGL1F0eVBJWUMxQ1RGYTlMRG1xdnZsMXM3TTB0bTA9;MTcxMjIzN0AzMjMxMmUzMTJlMzMzNVBLZXFyM0RBVFRqMHQ0T1pNbGhGd1d5SjQ1TmROY0I5dnZtaFRldDZPdU09;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0ZiWXtacXBTRmFZ;MTcxMjIzOUAzMjMxMmUzMTJlMzMzNWtDNUF5UkVjQzdHTENXVzNkcW4wWFJzTWMwdXdybzI3a2VXeWlrVjMrR2c9;MTcxMjI0MEAzMjMxMmUzMTJlMzMzNUk3MjNqZXNjcTBGSEpvb0lmUUJVSHBrZkpiYitabHlhOU56Tm5LTFhaMWs9;MTcxMjI0MUAzMjMxMmUzMTJlMzMzNW4wbjdFY3Jma21SYXZGL1F0eVBJWUMxQ1RGYTlMRG1xdnZsMXM3TTB0bTA9')

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
