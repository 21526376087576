import { Component, AfterViewInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { AuthService } from "@shared/services/auth.service";

import { Overlay } from "@angular/cdk/overlay";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements AfterViewInit {
  title = "dartbid";
  route: string = this.router.url;
  public isQueueOpen$: BehaviorSubject<boolean>;
  public isQueueOpen;

  isProposalsView: boolean = false;
  isLoginView: boolean = false;

  constructor(
    public router: Router,
    private _queueToggleService: QueueToggleService,
    public overlay: Overlay,
    public authService: AuthService
  ) {

    this.isQueueOpen$ = this._queueToggleService.isQueueOpen$;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        // Check if the current route or any of its ancestors contains "/proposals"
        this.isProposalsView = this.isRouteProposals();
        this.isLoginView = this.isRouteLogin();
      });
  }

  ngAfterViewInit() {
    this.isQueueOpen = this.isQueueOpen$;
  }

  toggleQueue(): void {
    this._queueToggleService.toggleQueueState();
  }

  isUserDropdownOpen: boolean = false;

  toggleUserDropdown(): void {
    this.isUserDropdownOpen = !this.isUserDropdownOpen;
  }

  isRouteProposals(): boolean {
    const path = this.router.url;
    return path.includes('proposals');
  }
  
  isRouteLogin(): boolean {
    const path = this.router.url;
    return path.includes('login');
  }
}
