import { Injectable , OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { shareReplay, map, switchMap } from 'rxjs/operators';
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from "./auth.service";

export interface Job {
  meta: {
    id: string
  }
  info: {
    project: string;
    firstName: string;
    lastName: string;
  };
}

@Injectable({
  providedIn: "root"
})
export class JobsService implements OnDestroy {
  viewableJobs$: Observable<any[]>;
  viewableJobsCondensed$: Observable<any[]>;
  jobsDeleted$: Observable<any[]>;

  constructor(
    public db: AngularFirestore,
    public database: FirestoreService,
    private authService : AuthService
  ) {

    this.viewableJobs$ = this.authService.user$.pipe(
      switchMap((user: any) => {
        if (user.tier == 2) {
          return this.database.col$('jobs', ref => ref
            .where('meta.created', '!=', '')
            .orderBy('meta.created', 'desc')
          ).pipe(
            map((jobs: any[]) => {
              return jobs.filter(job => job.meta.createdBy === user.uid);
            })
          );
        } else {
          return this.database.col$('jobs', ref => ref
            .where('meta.created', '!=', '')
            .orderBy('meta.created', 'desc')
          ).pipe(
            map((jobs: any[]) => {
              return jobs
            })
          );
        }

      }),
      shareReplay({bufferSize: 1, refCount: true}),
    )

    this.viewableJobsCondensed$ = this.viewableJobs$.pipe(
      map((documents: any[]) => {
        // Map Firestore documents to your desired format (selecting only certain fields)
        return documents.map((job: any) => ({
         info: {
          project: job?.info?.project,
          firstName: job?.info?.firstName,
          lastName: job?.info?.lastName,
          isSystemWorking: job?.info?.isSystemWorking,
         },
          sent: job?.sent,
          system: job?.system ? {
            outdoorUnit: job?.system?.outdoorUnit,
            indoorUnit: job?.system?.indoorUnit,
            thermostat: job?.system?.thermostat,
          } : '',
          customerSelectedSystem: job?.customerSelectedSystem,
          template: job?.template,
          templateDescription: job?.templateDescription ? {
            type : job?.templateDescription?.type
          } : {},
          psis: job?.psis ? {
            meta : {
                createdAt: job.psis.meta.createdAt
              }
          } : {},
          confirmation: job?.confirmation ? {
            'signedAt' : job?.confirmation?.signedAt,
            'prices' : job?.confirmation?.prices
          } : {},
          deposits: job?.deposits ?  true : false,
          status: job?.status,
          price: job?.price,
          depositsTotal: job?.depositsTotal,
          balance: job?.balance,
          tonnage: job?.tonnage,
          notes: job?.notes,
          meta: {
            created: job?.meta?.created,
            createdBy: job?.meta?.createdBy,
            id: job?.meta?.id,
            jobsGroupId: job?.meta?.jobsGroupId
          },
        }));
      })
    ),
    shareReplay({bufferSize: 1, refCount: true});

    this.jobsDeleted$ = this.database.col$('archivedJobs', ref => ref
      .orderBy('meta.created', 'desc')
      ), shareReplay({bufferSize: 1, refCount: true});

  }

  ngOnDestroy() {
  }

  public watchJob(id:string) {
    const job = this.database.doc$(`jobs/${id}`)
    if (job) {
      return job
    } else {
      return null
    }
  }

}
