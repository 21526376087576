import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { tap , shareReplay } from "rxjs/operators";

export interface File {
  name: string;
  downloadUrl : string;
  uploaded : string;
  type : string;
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class MiscFilesService {

  sharedFiles$: Observable<File[]>;
  sharedFiles: File[];

  constructor(
    public database: FirestoreService
  ) {

      this.sharedFiles$ = this.database.col$('files');
      this.sharedFiles$.pipe(
        tap( files =>  {
          this.sharedFiles = files
          }
        ),
        shareReplay({bufferSize: 1, refCount: true})
      );

    }
}
