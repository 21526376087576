import { Injectable , OnDestroy} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service'
import { Observable,Subscription, of } from 'rxjs';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {

  routeSub: Subscription;

  isPresenting : boolean;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    public router: Router) {
      this.routeSub = this.route.params.subscribe(params => {
        if (this.route.snapshot.queryParamMap.get('presenting') == 'true') {
          this.isPresenting = true;
        } else {
          this.isPresenting = false;
        }
      });
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      if(this.route.snapshot.queryParamMap.get('presenting')||0) {
        return of(true)
      } else {
        return this.auth.user$.pipe(
             take(1),
             map(user => !!user), // <-- map to boolean
             tap(loggedIn => {
               if (!loggedIn) {
                 this.router.navigate(['/login']);
               }
           })
         )
      }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.auth.user$.pipe(
           take(1),
           map(user => !!user), // <-- map to boolean
           tap(loggedIn => {
             if (!loggedIn) {
               this.router.navigate(['/login']);
             }
         })
       )
     }

     ngOnDestroy() {
       this.routeSub.unsubscribe()
     }


}
