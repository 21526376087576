import { AuthService } from '@shared/services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { filter, map } from "rxjs/operators";
import { switchMap } from 'rxjs/operators';
import { AngularFirestore } from "@angular/fire/firestore";
import { DatePipe } from '@angular/common';

export interface Appointment {
  id: string,
  consultant: string,
  eventName: string,
  startTime: any,
  endTime: any,
  isAllDay: boolean,
  isSystemWorking: boolean,
  lead_address1: string,
  lead_address2: string,
  lead_city: string,
  lead_email: string,
  lead_firstName: string,
  lead_lastName: string,
  lead_phone: string,
  lead_state: string,
  lead_zip: string,
  meta: {
    created: number,
    createdBy: string,
    job?: string
  }
  description: string;
  type: 'quote' | 'followup' | 'other' | 'block'
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  viewableAppointments$: Observable<any[]>;
  companyWideAppointments$: Observable<any[]>;
  allAppointments$: Observable<any[]>;
  viewableTodayAppointments$: Observable<any[]>;

  constructor(
    public database: FirestoreService,
    public afs: AngularFirestore,
    private authService : AuthService,
    private datePipe: DatePipe
  ) {

    this.viewableAppointments$ = this.authService.user$.pipe(
      switchMap( (user:any) => {
        if (this.authService.isConsultant(user)) {
          // if they're a normal user, calendar data should be theirs only
          let currentUserID = user.uid;
          return this.database.col$( 'appointments', ref => ref
            .where('consultant', "==", currentUserID)
            // .where('consultant', "==", '0000')
            .orderBy('startTime', 'desc')
          );
        } else {
          return this.database.col$('appointments', ref => ref
            .orderBy('startTime', 'desc')
          );
        }
      })
    );

    this.companyWideAppointments$ = this.database.col$('appointments', ref => ref
      .where('consultant', "==", '0000')
      .orderBy('startTime', 'desc')
    );

    this.allAppointments$ = this.database.col$('appointments', ref => ref
      .orderBy('startTime', 'desc')
    );

    this.viewableTodayAppointments$ = this.viewableAppointments$.pipe(
      map(appts => {
        const dateToday = this.datePipe.transform(new Date(), 'shortDate');
        return appts.filter(appt => {
          const apptDate = this.datePipe.transform(appt?.startTime, 'shortDate');
          if (appt?.startTime) {
            if (dateToday == apptDate) {
              return appt
            }
          }
        });
      }),
      filter(appts => appts.length > 0)
    );

  }

  async updateAppointment(appointment : Appointment) {
    try {
      await this.database.col("appointments").doc(appointment.id.toString()).set(appointment, {merge: true});
    } catch (err) {
      console.log(err)
    }
  }

  async deleteAppointment(id:string) {
    try {
      await this.database.col("appointments").doc(id).delete();
      return true
    } catch (err) {
      console.log(err)
    }
  }

}