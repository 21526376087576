import { Component, OnInit, Input } from '@angular/core';
import { Discount } from "@shared/services/rebates.service";

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})


export class PromotionComponent implements OnInit {

  @Input() promotion: Discount;

  constructor() { }

  ngOnInit(): void { }

}
