import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

import { DartboardRoutingModule } from './dartboard-routing.module';
import { ListComponent } from './all/list/list.component';
import { DartboardComponent } from './dartboard.component';
import { NewComponent } from './new/new.component';
import { JobDetailsComponent } from './all/job-details/job-details.component';
import { AllJobsComponent } from './all/all-jobs.component';
import { ChooseProposalComponent } from './all/job-details/choose-proposal/choose-proposal.component';
import { CutsheetImagesComponent } from './all/job-details/cutsheet-images/cutsheet-images.component';
import { TableComponent } from './table/table.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MessageComponent } from './message/message.component';
import { DeleteComponent } from './delete/delete.component';
import { NotesComponent } from './notes/notes.component';
import { DepositsComponent } from './all/job-details/choose-proposal/financing/deposits/deposits.component';
import { UnsignComponent } from './unsign/unsign.component';
import { ProposalChoicesComponent } from './all/job-details/proposal-choices/proposal-choices.component';
import { NewLeadComponent } from './new-lead/new-lead.component';
import { LeadModalComponent } from '@shared/components/lead-modal/lead-modal.component';
import { TotalsComponent } from './table/table-header/totals/totals.component';
import { ProposalChoicesEnhancementsComponent } from './all/job-details/proposal-choices/proposal-choices-enhancements/proposal-choices-enhancements.component';
import { ProposalChoicesBreakdownComponent } from './all/job-details/proposal-choices/proposal-choices-breakdown/proposal-choices-breakdown.component';
import { SystemProposalModalComponent } from '@shared/components/system-proposal-modal/system-proposal-modal.component';
import { LinkedJobsComponent } from './all/job-details/detail-view/linked-jobs/linked-jobs.component';
import { AddEditDiscountComponent } from './all/job-details/detail-view/add-edit-discount/add-edit-discount.component';
import { KpisComponent } from '@shared/components/kpis/kpis.component';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { CutsheetViewComponent } from './cutsheet-view/cutsheet-view.component';
@NgModule({
  declarations: [ ListComponent, DartboardComponent, NewComponent, AllJobsComponent, JobDetailsComponent, ChooseProposalComponent, CutsheetImagesComponent, TableComponent, MessageComponent, DeleteComponent, NotesComponent, DepositsComponent, UnsignComponent, ProposalChoicesComponent, NewLeadComponent, LeadModalComponent, TotalsComponent, ProposalChoicesEnhancementsComponent, ProposalChoicesBreakdownComponent, SystemProposalModalComponent, LinkedJobsComponent, AddEditDiscountComponent,KpisComponent, TableHeaderComponent, CutsheetViewComponent ],
  imports: [
    CommonModule,
    DartboardRoutingModule,
    SharedModule,
    NgxDatatableModule  ], 
  exports: [ProposalChoicesComponent, ProposalChoicesEnhancementsComponent]
})
export class DartboardModule { }
