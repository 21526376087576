import { FirestoreService } from '@shared/services/firestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from "@angular/forms";
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { Router } from '@angular/router';
import { Observable, Subscription, timer , of } from "rxjs";
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-new-lead',
  templateUrl: './new-lead.component.html',
  styleUrls: ['./new-lead.component.scss']
})
export class NewLeadComponent implements OnInit, OnDestroy {

  customerForm: FormGroup;
  consultant;
  additionalNotes;
  id : string;
  userSub: Subscription;

  loading: boolean = false;
  success: boolean = false;
  error: boolean = false;

  constructor(
    private formBuilder : FormBuilder,
    public authService : AuthService,
    public userService : UserService,
    private db : AngularFirestore,
    private afs : FirestoreService,
    public router : Router
    ) { 

      this.id = this.db.createId();
      this.customerForm = this.formBuilder.group({
       meta: this.formBuilder.group({
        created: Date.now(),
        id: this.id,
        createdBy: [ null ]
      }),
        notes : [null],
        expectedCompletion : [null],
        consultant : [ null , Validators.required],
        info: this.formBuilder.group({
          firstName: [ null , Validators.required],
          lastName: [ null ],
          phone: [ null, [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]],
          email: [ null,
            [
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
            ]
          ],
          address1: [ null ],
          address2: [ null],
          city: [ null ],
          state: [ null ],
          zip: [ null ],
        })
      })
    }

  ngOnInit(): void {
    this.userSub = this.authService.user$.pipe(take(1)).subscribe(
      user =>
        this.customerForm.get('meta').patchValue({
          createdBy: user.uid
        })
    )
  }

  onSubmit() {
    if(this.customerForm.valid) {
      this.submitHandler();
    } else {
      this.customerForm.markAllAsTouched()
    }
  }

  async submitHandler() {
    let formValue = this.customerForm.value;
    this.loading = true;
    try {
      await this.afs.col("leads").doc(this.id).set(formValue);
      this.router.navigateByUrl(`/home`)
      this.success = true;
      this.loading = false;
    } catch (err) {
      console.log(err)
      this.error = true
    }
    this.loading = false;
  }

   ngOnDestroy() {
    this.userSub.unsubscribe();
  }


}
