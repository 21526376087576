import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'initials'
})
export class GetInitialsFromNamePipe implements PipeTransform {
  transform(name: string): string {
    if (name && name.length) {
      return name.split("")[0] + name.split(" ").pop().split("")[0];
    } else {
      return null;
    }
  }
}
