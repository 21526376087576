import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-tonnage-calculator',
  templateUrl: './tonnage-calculator.component.html',
  styleUrls: ['./tonnage-calculator.component.scss']
})
export class TonnageCalculatorComponent implements OnInit {
  tonnageMathForm : NgForm;
  btu: number;
  tonn: number;
  sqft: number;
  ceil: number;
  wind: number;
  door: number;
  occp: number;

  constructor() { }


  ngOnInit(): void {
  }

  calculate() {
    this.btu = this.sqft * this.ceil + this.wind * 1000 + this.door * 1000 + this.occp * 100;
    this.tonn = Math.ceil(2 * this.btu / 12000) / 2;
  }
}
