import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'removeCreditFees'
})
export class RemoveCreditFeesPipe implements PipeTransform {

  constructor( private adminPricingService: AdminPricingService ) { }

  transform(number:number): unknown {
    return (number * (this.adminPricingService.creditPriceAdminCosts / 100))
  }

}
