import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-feature-description-modal',
  templateUrl: './feature-description-modal.component.html',
  styleUrls: ['./feature-description-modal.component.scss'],
  host: {
    class: 'modal u-max-height--largest u-max-width--larger'
  }
})
export class FeatureDescriptionModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
  }

}
