import { Observable, of } from 'rxjs';
import { take, map, shareReplay } from 'rxjs/operators';
import { Pipe, PipeTransform, OnInit } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'getDisplayPrice'
})
export class GetFinalPrice implements PipeTransform {

  private method : number;
  private sharedFee : number;
  private creditFee : number;

  constructor( private adminPricingService: AdminPricingService ) { 

  }

    transform(cashPrice : number): Observable<any> {

      if (cashPrice == 0) {
        return of(0)
      } else {
        return this.adminPricingService.accounting$.pipe(
          shareReplay(1),
          map( (data : any) => {
              const t = data.proposalSalesTax / 100;
              let f = 0;
              const b = cashPrice;

              if (data.creditFeeSetting.applyAs == 1) {
                // Model 1 = shared fee
                let f = data.accounting.sharedFee;
                return this.addPercentageToTotal(b,f)

              } else if (data.creditFeeSetting.applyAs == 2) {
                // Model 2 = cash rebate
                let f = data.creditPriceAdminCosts / 100;
                return b / (1 - f * (t + 1))

              } else if (data.creditFeeSetting.applyAs == 3) {
                // Model 3 = Surcharge
                return b
              }
          }
        
        ))
      }
  }

  addPercentageToTotal(base, percentage) {
    return base * (1 + percentage / 100);
  }

}
