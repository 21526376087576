import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'cashDiscount'
})
export class CashDiscountPricePipe implements PipeTransform {

  constructor( private adminPricingService: AdminPricingService ) {

  }

  transform(job: any, proposal: any): unknown {
    const discountsTotal = proposal.discounts.reduce(
      (total : number, discount) => {
        if(discount.appliesToOptions.some(option => option == parseInt(job.system?.option))) {
          return total += discount.value
        } else {
          return total += 0
        }
      }, job.customDiscount.amount
    );

    const addOnsTotal = job.addOns.reduce(
      (total : number, addOn) => {
        if ( addOn.quantity ) {
          return total += addOn.price * addOn.quantity
        }
        else {
          return total += addOn.price
        }
      }, 0
    );

    const enhancementsTotal = job.enhancements.reduce(
      (total : number, enhancement) => {
        return total += enhancement.price
      }, 0
    );

return ((job.system?.cashPrice + addOnsTotal + enhancementsTotal - discountsTotal) * (this.adminPricingService.creditPriceAdminCosts / 100))
    // return (job.system?.cashPrice + addOnsTotal + enhancementsTotal - discountsTotal) * (this.adminPricingService.creditPriceAdminCosts / 100)
  }

}
