import { Component, OnInit, Input} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: 'app-cutsheet-images',
  templateUrl: './cutsheet-images.component.html',
  styleUrls: ['./cutsheet-images.component.scss']
})
export class CutsheetImagesComponent implements OnInit {

  @Input() images: any[];
  @Input() imagePaths: any[];
  @Input() currentJobId: any[];

  constructor(
    private storage: AngularFireStorage,
    private afs: AngularFirestore,
    ) { }

  ngOnInit(): void {
    if (this.imagePaths) {
      if(this?.imagePaths?.length != this?.images?.length && navigator.onLine) {
        this.buildAndUpdateImagesFromPaths()
      }
    }
  }

  async buildAndUpdateImagesFromPaths() {
    const images = await this.returnNewImagesArray()
    try {
      this.afs.doc(`jobs/${this.currentJobId}`).update({
        'cutsheet.images': images
      });

    } catch (err) {
      console.log(err)
    }
  }

  async returnNewImagesArray() {
    let images = []
    for (const path of this.imagePaths) {
      let ref = this.storage.ref(path);
      let downloadURL = await ref.getDownloadURL().toPromise();
      images.push(downloadURL)
    }
    return images
  }

}
