import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {

  transform(value: any): any {
    if (value == null) {
      return 'string'
    } else if (value == true || value == 'true' || value == false || value == 'false') {
      return 'boolean'
    } else {
      return typeof value;    
    }
  }

}
