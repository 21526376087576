import { Injectable, OnDestroy } from "@angular/core";
import { FirestoreService } from "@shared/services/firestore.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Subscription } from "rxjs";
import { ProposalsService } from "@shared/services/proposals.service";
import { AdminPricingService } from '@shared/services/admin-pricing.service'

@Injectable({
  providedIn: "root"
})
export class TemplatePricingUpdates implements OnDestroy{
  proposalsSub: Subscription;
  templates;
  accounting;

  constructor(
    public afs: AngularFirestore,
    public database: FirestoreService,
    public proposalsService : ProposalsService,
    public adminPricingService : AdminPricingService ) {
      this.templates = this.proposalsService.proposals
      this.accounting = this.adminPricingService.accounting
  }

  ngOnDestroy() {
    this.proposalsSub.unsubscribe()
  }

  async savePricingToolChanges(updatedTemplates) {
    updatedTemplates.forEach( async (template,t) => {
      const updateDatedTemplateObject = updatedTemplates.find(t => t.id == template.id);
      await this.updatedTemplate(updateDatedTemplateObject)
    })
  }

  async updatedTemplate(template) {
    const templateDoc = this.database.col("templates").doc(template.id);
    if (!templateDoc) return false
    try {
      await templateDoc.update(template)
      return true
    } catch(err) {
      console.log(`Error updating template #${template.id}. Error = `, err)
      return false
    }
  }


}
