import { Injectable } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from "rxjs/operators";

export interface Discount {
  name: string;
  amount: number;
  value: number;
  description?: string;
  enabledDate?: any;
  expirationDate?: any;
  type?: string;
  link?: string;
}

@Injectable({
  providedIn: 'root'
})
export class RebatesService {

  promos$ : Observable<any[]>
  promotionsDoc$ : Observable<any>

  constructor(private database : FirestoreService) {
    this.promotionsDoc$ = this.database.doc$('promotions/all').pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.promos$ = this.promotionsDoc$.pipe(
      map(
        data => data.promotions
      ),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }
}
