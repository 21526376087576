import { Injectable , OnDestroy } from "@angular/core";
import { FirestoreService } from "@shared/services/firestore.service";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "@angular/fire/firestore";
import { tap , shareReplay} from 'rxjs/operators';
import { Observable, Subscription } from "rxjs";

export interface AdminPricing {
  taxRate: number;
  commissionRate: number;
  laborRate: number;
  profitRate: number;
  overheadRate: number;
  overhead: number;
  creditPriceAdminCosts: number;
  depositPercentage: number;
}

export interface FinancingOption {
  name: string,
  months: number,
  apr: number,
  fee: number,
  min: number,
  terms?: string
}

@Injectable({
  providedIn: "root"
})
export class AdminPricingService implements OnDestroy {

  accountingSub: Subscription;
  pricingFormula : number;
  accountingDoc : AngularFirestoreDocument;
  accounting$ : Observable<any>;
  accounting;
  taxRate : number;
  commissionRate : number;
  laborRate : number;
  profitRate: number;
  overheadRate: number;
  creditFeeSetting;
  financingApplicationURL : string;
  creditPriceAdminCosts : number;
  depositPercentage: number;
  financingOptions : FinancingOption[];
  proposalGoodForDays : number;

  constructor(
    public afs: AngularFirestore,
    public database: FirestoreService) {

    this.accountingDoc = this.database.doc("admin/accounting");

    this.accounting$ = this.accountingDoc.valueChanges().pipe(
      shareReplay({bufferSize: 1, refCount: true})
    );

    this.accountingSub = this.accounting$.subscribe(data => {
      this.pricingFormula = data?.pricingFormula || 0;
      this.accounting = data;
      this.taxRate = data.taxRate;
      this.commissionRate = data.commissionRate;
      this.laborRate = data.laborRate;
      this.profitRate = data.profitRate;
      this.overheadRate = data.overheadRate;
      this.creditFeeSetting = data.creditFeeSetting;
      this.creditPriceAdminCosts = data.creditPriceAdminCosts;
      this.depositPercentage = data.depositPercentage;
      this.financingOptions = data.financingOptions;
      this.financingApplicationURL = data.financingApplicationURL;
      this.proposalGoodForDays = data.proposalGoodForDays;
    });
  }

  ngOnDestroy() {
    this.accountingSub.unsubscribe()
  }

  getRecommendedPrice(option,size) {
    if (this.pricingFormula == 1) {
      return this.getRecommendedPriceOldFormula(option,size)
    } else if (this.pricingFormula == 0) {
      return this.getRecommendedPriceNewFormula(option,size)
    } else if (this.pricingFormula == 2) {
      return this.getRecommendedPriceAdditionalFormula1(option,size)
    }
  }

  // Original Formula
  getRecommendedPriceOldFormula(option,size) {
    const e = size.cost;
    const m = option.details.materialCosts;
    const t = 1 + (this.taxRate / 100);
    const l = this.laborRate;
    const o = this.overheadRate;
    const h = option.details.hoursToInstall;
    const p = option.details.netProfit || 0;
    const c = 1 + (this.commissionRate / 100);
    const recommendedPrice = c ? +((( ((e + m) * t) + ((l + o) * h) + p ) * c).toFixed(2)):+(( ((e + m) * t) + ((l + o) * h) + p ).toFixed(2)) 
    
    return recommendedPrice
  }

  // Revision 2022 (Abandoned by Phillip)
  getRecommendedPriceNewFormula(option,size) {
    let e = size.cost || 0;
    let m = option.details.materialCosts || 0;
    let h = option.details.hoursToInstall || 0;
    const E = e * (1 + (this.taxRate / 100))
    const M = m * (1 + (this.taxRate / 100))
    const I = this.laborRate * h * 2
    const DC = E + M + I;
    const OR = this.overheadRate / 100;
    const PR = this.profitRate / 100;
    const C = this.commissionRate / 100
    const recommendedPrice =  DC / (1 - OR - PR - C);

    return +(recommendedPrice).toFixed(2)
  }

  // Version made for Yarbrough and Sons. Commission is only paid on pre-commission price.
  getRecommendedPriceAdditionalFormula1(option, size) {
    let e = size.cost || 0;
    let m = option.details.materialCosts || 0;
    let h = option.details.hoursToInstall || 0;
    const E = e * (1 + (this.taxRate / 100))
    const M = m * (1 + (this.taxRate / 100))
    const I = this.laborRate * h * 2
    const DC = E + M + I;
    const OR = this.overheadRate / 100;
    const PR = this.profitRate / 100;
    const C = this.commissionRate / 100
    const preCommissionPrice = DC / (1 - OR - PR);
    const recommendedPrice = preCommissionPrice * (1 + C);

    return +(recommendedPrice).toFixed(2)
  }

  ////////////////////////////////////////////////////////////
  // Enhancements

  async getRecommendedItemPrice(preTaxCost:number,hoursToInstall:number,desiredProfit:number) {
    const c = preTaxCost;
    const h = hoursToInstall;
    const p = desiredProfit;

    if (this.pricingFormula == 1) {
      return this.getRecommendedItemPriceFormula1(c,h,p)
    } else if (this.pricingFormula == 0) {
      return this.getRecommendedItemPriceFormula0(c,h,p)
    } else if (this.pricingFormula == 2) {
      return this.getRecommendedItemPriceFormula2(c,h,p)
    }
  }

  // Original Formula
  getRecommendedItemPriceFormula1(preTaxCost = 0,hoursToInstall = 0,desiredProfit = 0) {
    const t = 1 + (this.taxRate / 100);
    const l = this.laborRate;
    const o = this.overheadRate;
    const h = hoursToInstall;
    const p = desiredProfit;
    const c = 1 + (this.commissionRate / 100);
    const recommendedPrice = c ? +((( ((preTaxCost) * t) + ((l + o) * h) + p ) * c).toFixed(2)):+(( ((preTaxCost) * t) + ((l + o) * h) + p ).toFixed(2)) 
    
    return recommendedPrice
  }

  // Revision 2022 (Abandoned by Phillip)
  getRecommendedItemPriceFormula0(preTaxCost = 0,hoursToInstall = 0,desiredProfit = 0) {
    const percentage = this.pricingFormula == 1 ? false : true
    const E = preTaxCost * (1 + (this.taxRate / 100))
    const I = this.laborRate * hoursToInstall * 2
    const DC = E + I;
    const OR = this.overheadRate / 100;
    const C = this.commissionRate / 100;
    
    let recommendedPrice = 0;
    if (percentage) {
      recommendedPrice = DC / (1 - OR - (desiredProfit / 100) - C);
    } else {
      recommendedPrice = (DC + desiredProfit) / (1 - OR - C);
    }

    return +(recommendedPrice).toFixed(2)
  }
 
  // Version made for Yarbrough and Sons. Commission is only paid on pre-commission price.
  getRecommendedItemPriceFormula2(preTaxCost = 0,hoursToInstall = 0,desiredProfit = 0) {
    const percentage = this.pricingFormula == 1 ? false : true
    const E = preTaxCost * (1 + (this.taxRate / 100))
    const I = this.laborRate * hoursToInstall * 2
    const DC = E + I;
    const OR = this.overheadRate / 100;
    const C = this.commissionRate / 100;

    let preCommissionPrice = 0;
    if (percentage) {
      preCommissionPrice = DC / (1 - OR - (desiredProfit / 100));
    } else {
      preCommissionPrice = (DC + desiredProfit) / (1 - OR);
    }

    const recommendedPrice = preCommissionPrice * (1 + C);

    return +(recommendedPrice).toFixed(2)
  }

  addPercentage(base, percentage) {
    return base / ( 1 - ( percentage / 100) );
  }

}
