import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DartboardFilterService {

  public dartboardFilter$: BehaviorSubject<string>;

  constructor() {
    this.dartboardFilter$ = new BehaviorSubject('pending');}

    public setDartboardFilter(state: string) {
      if (state != this.dartboardFilter$.value) {
        console.log(`service changing state to ${state}`)
        this.dartboardFilter$.next(state)
      }
    }

}
