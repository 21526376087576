import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'getBrandLogo'
})

export class GetBrandLogoPipe implements PipeTransform {

  transform(brand: string): string {
    let b = brand.replace(/\s/g, "").toLowerCase();
    return './assets/logos/' + b + '.png'
  }

}
