import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { User } from "@shared/services/auth.service";
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import {
  AngularFirestore,
  AngularFirestoreDocument } from "@angular/fire/firestore";
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable, Subscription } from "rxjs";
import { FirestoreService } from "@shared/services/firestore.service";
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize } from 'rxjs/operators';

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit, OnDestroy {

  routeSub : Subscription;
  userSub : Subscription;

  selectedUser: User;
  usersArray: User[] = [];
  usersSubscription: Subscription;

  userDoc: AngularFirestoreDocument;

  editUserForm: FormGroup;

  editing: boolean = false;
  creating: boolean = false;
  loading: boolean = true;
  success: boolean = false;
  isConfirmDelete: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;


  constructor(
    public queueToggleService: QueueToggleService,
    public database: FirestoreService,
    private formBuilder: FormBuilder,
    public afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private storage: AngularFireStorage,
    public authService : AuthService,
    public userService : UserService
  ) {
    this.editUserForm = this.formBuilder.group({
      uid: [],
      tier: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      displayName: [""],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
        ]
      ],
      phone: [ null, [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),Validators.required]],
      title: [""],
      photoUrl: [""]
    });

    this.userSub = this.userService.users$.subscribe(users => {
      this.usersArray = users;
      this.loading = false
    });

  }

  ngOnInit() {

    setTimeout(() => {
      this.routeSub = this.route.params.subscribe(params => {
        if (params.uid) {
          const user = this.getUserById(params.uid);
          if (user) {
            this.selectUser(user);
          }
        } else {
          // this.selectUser(this.usersArray[0])
        }
      });
    }, 200);

  }

  getUserById(id: string) {
    return this.usersArray.find(user => user.uid == id);
  }

  resetStates() {
    this.editing = false;
    this.creating = false;
    this.loading = false;
    this.success = false;
    this.isConfirmDelete = false;
  }

  selectUser(user) {
    this.resetStates();
    if (window.innerWidth <= 800) {
      this.queueToggleService.toggleQueueState();
    }
    this.selectedUser = user;
    this.setUserDoc(user.uid);
    this.editUserForm.reset();
    this.editUserForm.patchValue(this.selectedUser, { onlySelf : true });
  }

  toggleEditUser() {
    this.editing = !this.editing;
  }

  async setUserDoc(id) {
    this.userDoc = await this.afs.collection("users").doc(id);
  }

  createUser() {
    if (window.innerWidth <= 800) {
      this.queueToggleService.toggleQueueState();
    }
    this.router.navigateByUrl(`admin/users/new`)
    this.creating = true;
    this.editing = true;

    this.selectedUser = {
      uid: "",
      isOnline: false,
      tier: "",
      title: "",
      firstName: "",
      lastName: "",
      displayName: "",
      phone: "",
      email: "",
      photoUrl: ""
    };
    this.editUserForm.patchValue(this.selectedUser);
  }

  onCancel() {
    if (this.creating) {
      this.selectedUser = null;
    } else {
      this.editUserForm.patchValue(this.selectedUser);
      this.editUserForm.updateValueAndValidity();
    }
    this.resetStates();
  }

  get f() {
    return this.editUserForm.controls;
  }

  onFileSelected(event, id) {
    const file = event.target.files[0];

    if (file) {
      this.uploadFile(file,id)
    }
  }

  resetUpload() {
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadURL = null;
  }

  uploadFile(file: File, id: string) {
    const path = 'users/'+id;
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.editUserForm.patchValue(
          { photoUrl: this.downloadURL }
        )
        this.editUserForm.updateValueAndValidity()
        this.editUserForm.markAsDirty()
      }),
    );
  }

  onSubmit() {
    this.submitHandler();
  }

  async submitHandler() {
    this.loading = true;
    let editedUser = this.editUserForm.value;

    try {
      if (this.creating) {
        await this.afs
          .collection("users")
          .add(editedUser)
          .then(docRef => {
            this.afs
              .collection("users")
              .doc(docRef.id)
              .update({
                uid: docRef.id
              });
            editedUser.uid = docRef.id;
            this.selectedUser = editedUser;
          });
          this.router.navigateByUrl(`admin/users/${this.selectedUser.uid}`)
      } else {
        await this.userDoc.update(editedUser);
        this.selectedUser = editedUser;
        // this.editUserForm.patchValue(this.selectedUser);
        this.router.navigateByUrl(`admin/users/${this.selectedUser.uid}`)
      }
      this.success = true;
      this.loading = false;
    } catch (err) {
      console.log(err);
    }
    this.resetStates();
    this.resetUpload();
  }

  confirmDelete() {
    if (this.selectedUser.tier == "0") {
      alert("cannot delete super admin");
      this.isConfirmDelete = false;
    } else {
      this.isConfirmDelete = true;
    }
  }

  async deleteUser(id) {
    this.loading = true;
    this.isConfirmDelete = false;
    try {
      await this.afs
        .collection("users")
        .doc(id)
        .delete();
      this.success = true;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.log(err);
    }
    this.resetStates();
    this.selectedUser = null;
    this.router.navigateByUrl(`admin/users`)
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.userSub.unsubscribe();
  }
}
