import { Component, OnInit, OnDestroy, ViewChild, ElementRef  } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable,Subscription } from "rxjs";
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { ProductsService } from "@shared/services/products.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { take, finalize, shareReplay } from "rxjs/operators";
import { QueueToggleService } from '@shared/services/queue-toggle.service';
import { AdminPricingService } from '@shared/services/admin-pricing.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy  {
  productsSub: Subscription;
  loading: boolean = true;
  success: boolean = false;
  productsForm: FormGroup;

  activeView: string = 'Brands';

  editing: boolean = false;
  creating: boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  loadingState = 0;

  linksToRemoveFromStorage = [];

  constructor(
    private fb: FormBuilder,
    private productsService : ProductsService,
    private afs: AngularFirestore,
    public queueToggleService: QueueToggleService,
    private storage: AngularFireStorage,
    public adminPricing : AdminPricingService
  ) {

    this.productsForm = this.fb.group({
      brands: this.fb.array( [] , Validators.minLength(1)),
      types: this.fb.array( [], Validators.minLength(1)),
      enhancements: this.fb.array( [], Validators.minLength(1))
    });

    this.productsSub = this.productsService.products$.pipe(take(1)).subscribe(
      settings => (
        this.brands.clear(),
        this.types.clear(),
        this.enhancements.clear(),
        settings.brands.sort().map( brand =>
          this.brands.push( new FormControl(brand) )
        ),
        settings.types.map( type =>
          this.types.push( new FormControl(type) )
        ),
        settings.enhancements.forEach( (enhancement,e) =>
          {
            const linksFormArray = enhancement?.links ? enhancement?.links.map( (link,i) => {
              return this.fb.group({
                'name' : new FormControl( '', Validators.required),
                'url' : new FormControl( '', Validators.required),
                'storageId' : new FormControl( ''),
              })
            }) : [];

          let enhancementForm = this.fb.group({
            name: new FormControl( enhancement['name'] ),
            price: new FormControl( enhancement['price'] ),
            description: new FormControl( enhancement['description'] ),
            image: new FormControl( enhancement['image'] ),
            brochure: new FormControl( enhancement['brochure'] ),
            links: new FormArray( linksFormArray || [] ),
            isCalculated: new FormControl(  enhancement['isCalculated'] || false ),
            c: new FormControl( enhancement['c'] ),
            h: new FormControl( enhancement['h'] ),
            p: new FormControl( enhancement['p'] != null ? enhancement['p'] : this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
          })

          this.enhancements.push(enhancementForm)

          if (enhancement?.links) {
          enhancement?.links.forEach( (link,l) => {
            let linkOnForm = this.productsForm.get("enhancements." + e + ".links." + l) as FormGroup
            linkOnForm?.patchValue(link);
          });
        }
          // this.enhancements[e].patchValue(enhancement)
          }
        ),
        this.loading = false
      )
    )

  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.productsSub.unsubscribe()
  }

  setActiveView(view:string) {
    this.activeView = view;
    this.queueToggleService.closeQueueIfMobile()
  }

  // Enhancement

  get enhancements() {
    return this.productsForm.get("enhancements") as FormArray;
  }

  addEnhancement() {
    this.enhancements.push( new FormGroup({
      name: new FormControl(),
      price: new FormControl(),
      description: new FormControl(),
      image: new FormControl(),
      brochure: new FormControl(),
      links: this.fb.array([]),
      isCalculated: new FormControl(false),
      c: new FormControl(null),
      h: new FormControl(null),
      p: new FormControl(this.adminPricing.pricingFormula != 1 ? this.adminPricing.profitRate : null),
    }) )

    setTimeout( () => {
      document.getElementById("enhancementsEnd").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100)
  }

  removeEnhancement(e:number) {
    const target = this.getEnhancementControls(e) as FormGroup
    if (target.value.storageId || target.value.brochure) this.linksToRemoveFromStorage.push(target.value.brochure)
    target.value.links.forEach( (link,l) => {
      this.removeLink(e,l)
    })
    this.enhancements.removeAt(e)
    this.productsForm.markAsDirty()
  }

   getEnhancementControls(e: number) {
    return this.productsForm.get('enhancements.' + e) as FormGroup;
  }

  async getSetEnhancementPrice(e:number) {
    const target = this.getEnhancementControls(e) as FormGroup
    const value = target.value
    const recommendedPrice = await this.adminPricing.getRecommendedItemPrice(value.c,value.h,value.p)
    target.patchValue( {
      price: recommendedPrice,
      isCalculated: true
    })
  }

  toggleEnhancementCalculated(e:number, value:boolean) {
    const target = this.getEnhancementControls(e) as FormGroup
    target.patchValue( {
      isCalculated: value
    })

    // if( value == false) {
    //   target.patchValue( {
    //     c: null,
    //     h: null,
    //     p: null
    //   })
    // }
  }

  addLink(e:number) {
    const target = this.getEnhancementControls(e).get("links") as FormArray
    const link = new FormGroup({
      'name' : new FormControl( '', Validators.required),
      'url' : new FormControl( '', Validators.required),
      'storageId' : new FormControl( ''),
    });

    if (!target) {
      this.getEnhancementControls(e).addControl('links', new FormArray([]))
      const newlyAddedLinks = this.getEnhancementControls(e).get("links") as FormArray
      newlyAddedLinks.push(link)
    } else {
      target.push(link)
    }
  }

  removeLink(e:number, l) {
    const target = this.getEnhancementControls(e).get("links") as FormArray
    if (target.value[l]?.storageId) this.linksToRemoveFromStorage.push(target.value[l]?.url)
    // if (target.value[l]?.storageId) this.storage.refFromURL(target.value[l]?.url).delete();
    target.removeAt(l);
    this.productsForm.markAsDirty()
  }

    async uploadEnhancementLinkFile(event, enhancement : number, link : number) {
    const id = await this.afs.createId();
    const file = event.target.files[0];
    if (!file) return
    
    const path = `enhancements/${enhancement}/links/${id}`;
    const ref = this.storage.ref(path);
    const task = this.storage.upload(path, file);
    let taskSub : Subscription;
    let urlSub : Subscription;

    taskSub = task?.percentageChanges().pipe(
      shareReplay({ bufferSize: 1, refCount: true })
    ).subscribe( per => {
      this.loadingState = per
      if (per == 100) {
        this.loadingState = 0;
        taskSub.unsubscribe()
      }
    })

    urlSub = task?.snapshotChanges().pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {
        ref.getDownloadURL().subscribe(downloadURL => {
          this.getEnhancementControls(enhancement).get('links.'+ link).patchValue({
            [`url`]: downloadURL,
            ['storageId']: id
          });
          this.productsForm?.updateValueAndValidity()
          this.productsForm?.markAsDirty();
          urlSub.unsubscribe()
        });
      })
    ).subscribe();
  }


  // Brand

  get brands() {
    return this.productsForm.get("brands") as FormArray;
  }

  addBrand() {
    const newBrand = new FormControl('', Validators.required);
    this.brands.push(newBrand)
  }

  removeBrand(i:number) {
    this.brands.removeAt(i)
    this.productsForm.markAsDirty()
  }


  // Type

  get types() {
    return this.productsForm.get("types") as FormArray;
  }

  addType() {
    const newType = new FormControl('', Validators.required);
    this.types.push(newType)
  }

  removeType(i:number) {
    this.types.removeAt(i)
    this.productsForm.markAsDirty()
  }

  onFileSelected(event, id) {
    const file = event.target.files[0];

    if (file) {
      this.uploadFile(file,id)
    }
  }

  onBrochureSelected(event, id) {
    const file = event.target.files[0];
    if (file) {
      this.uploadBrochure(file,id)
    }
  }

  resetUpload() {
    this.task = null;
    this.percentage = null;
    this.snapshot = null;
    this.downloadURL = null;
  }

  waitThenResetUpload() {
    setTimeout(() => {
      this.resetUpload()
    }, 1500);
  }

  uploadFile(file: File, id: string) {
    const path = 'enhancements/'+id;
    const ref = this.storage.ref(path);

    this.task = this.storage.upload(path, file);
    this.percentage = this.task.percentageChanges();

    this.snapshot   = this.task.snapshotChanges().pipe(
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        this.enhancements.controls[id].patchValue(
          { image: this.downloadURL }
        )
        this.productsForm.updateValueAndValidity()
        this.productsForm.markAsDirty();
        this.waitThenResetUpload();
      }),
    );
  }

    uploadBrochure(file: File, id: string) {
      const path = 'enhancements/brochure/'+id;
      const ref = this.storage.ref(path);

      this.task = this.storage.upload(path, file);
      this.percentage = this.task.percentageChanges();

      this.snapshot   = this.task.snapshotChanges().pipe(
        // The file's download URL
        finalize( async() =>  {
          this.downloadURL = await ref.getDownloadURL().toPromise();

          this.enhancements.controls[id].patchValue(
            { brochure: this.downloadURL }
          )
          this.productsForm.updateValueAndValidity()
          this.productsForm.markAsDirty();
          this.waitThenResetUpload();
        }),
      );
    }

    deleteExistingBrochure(enhancement:number) {
      const target = this.getEnhancementControls(enhancement)
      if (target.value.brochure) {
        this.linksToRemoveFromStorage.push(target.value.brochure)
        target.patchValue({ brochure: null})
        this.enhancements.markAsDirty()
      }
    }

    deleteLinksFromStorage() {
      if (this.linksToRemoveFromStorage) this.linksToRemoveFromStorage.forEach( (link) => {
        this.storage.refFromURL(link).delete()
      })
    }

  // Submit

  onSubmit() {
    this.submitHandler()
  }

  async submitHandler() {
    this.loading = true;
    const formValue = this.productsForm.value;
    try {
      // this.deleteLinksFromStorage() turning this off for now, we may not want to delete the files right away, because the templates and proposals will still link to it
      await this.afs.doc("products/settings").update(formValue);
      this.showSuccessMessage();
      this.loading = false;
      this.productsForm.markAsPristine();
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  showSuccessMessage() {
    this.success = true;
    setTimeout(()=>{
      this.success = false;
    }, 3000);
  }

}
