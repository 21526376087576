import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  OnDestroy
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@shared/services/auth.service";
import { ComponentPortal } from "@angular/cdk/portal";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";

import { UserDropdownComponent } from "./user-dropdown/user-dropdown.component";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { CalendarService } from "@shared/services/calendar.service";
import { JobsService } from "@shared/services/jobs.service";

@Component({
  selector: "app-primary-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
  host: {
    class: "banner"
  },
  providers: [Overlay]
})
export class BannerComponent implements OnInit, OnDestroy {
  overlaySub: Subscription;
  navMobileOption : string = 'home';
  @ViewChild("userDropdownOrigin") userDropdownOrigin: ElementRef;

  isUserDropdownOpen: boolean = false;
  userDropdownPortal: ComponentPortal<UserDropdownComponent>;
  private overlayRef: OverlayRef;

  constructor(
    private router: Router,
    public authService: AuthService,
    public overlay: Overlay,
    private vcRef: ViewContainerRef,
    public calendarService : CalendarService,
    public jobsService : JobsService
  ) {
  }

  ngOnInit() {}

  public openUserDropdown() {
    const userDropdownPortal = new ComponentPortal(
      UserDropdownComponent,
      this.vcRef
    );
    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-transparent-backdrop",
      positionStrategy: this.overlay
        .position()
        .connectedTo(
          this.userDropdownOrigin,
          { originX: "end", originY: "bottom" },
          { overlayX: "end", overlayY: "top" }
        )
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(userDropdownPortal);
    this.overlaySub = this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => {
      this.overlayRef.detach();
    });
  }

  navigateTo(value) {
    if (value) {
      this.router.navigate([value]);
      this.navMobileOption = value;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.overlaySub?.unsubscribe();
  }
}
