import { Component, OnInit, OnDestroy  } from "@angular/core";
import { AuthService } from "@shared/services/auth.service";
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueueToggleService } from "@shared/services/queue-toggle.service";
import { ActivatedRoute } from "@angular/router";
import { JobsService } from "@shared/services/jobs.service";
@Component({
  selector: "app-jobs",
  templateUrl: "./all-jobs.component.html",
  styleUrls: ["./all-jobs.component.scss"]
})
export class AllJobsComponent implements OnInit, OnDestroy  {

  userSub: Subscription;
  routeSub: Subscription;
  isCustomer: boolean = true;
  loading: boolean = true;

  constructor(
    public queueToggleService : QueueToggleService,
    public authService : AuthService,
    private route: ActivatedRoute,
    public jobsService : JobsService
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      if(this.route.snapshot.queryParamMap.get('presenting')||0) {
        this.queueToggleService.toggleQueueState(false);
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    });
  }

  ngOnInit(): void {
    this.userSub =this.authService.user$.pipe(
         map(user => {
           if(!user) {
             this.isCustomer = true;
             setTimeout(() => {
               this.loading = false;
             }, 1000);
           } else {
             this.isCustomer = false;
             this.loading = false;
           }
         })
     ).subscribe();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe()
    this.routeSub.unsubscribe()
  }

}
