import { shareReplay,switchMap } from 'rxjs/operators';
import { AuthService } from '@shared/services/auth.service';
import { Injectable, OnDestroy } from '@angular/core';
import { FirestoreService } from '@shared/services/firestore.service';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LeadsService implements OnDestroy {

  leads$: Observable<any[]>;
  currentUserLeads$: Observable<any[]>;

  constructor(
    private database : FirestoreService,
    public router : Router,
    private authService : AuthService
    ) {
      this.leads$ = this.database.col$('leads', ref => ref
        .orderBy('expectedCompletion', 'asc')
      );
      
      this.currentUserLeads$ = this.authService.user$.pipe(
        switchMap( user => {
          let currentUserID = user.uid;
          return this.database.col$( 'leads', ref => ref
            .where('consultant', "==", currentUserID)
            .orderBy('expectedCompletion', 'asc')
          );
        })
      ), shareReplay({bufferSize: 1, refCount: true})
    
    }

    async deleteLead(id) {
      try {
        await this.database.col("leads").doc(id).delete();
        return true
      } catch (err) {
        console.log(err)
      }
    }

    ngOnDestroy() {
    }
}
