import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "@shared/services/auth.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  success: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router
  ) {}


  ngOnInit(): void {
  }

  sendResetPasswordEmail(email) {
    this.authService.firebaseAuth.sendPasswordResetEmail(email)
    this.showSuccessMessageThenLeave()
  }

  showSuccessMessageThenLeave() {
    this.success = true;
    setTimeout(() => {
      this.router.navigate([`/login`]);
    }, 3500)
  }

}
