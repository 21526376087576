import { ActionLinkComponent } from './core/action-link/action-link.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./core/home/home.component";
import { UiComponent } from "./core/ui/ui.component";
import { LoginComponent } from "./core/login/login.component";

import { AuthGuard } from "./shared/guards/auth.guard";
import { AdminGuard } from "./shared/guards/admin.guard";
import { ThanksComponent } from '@shared/components/thanks/thanks.component';
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login"
  },
  {
    path: "login",
    pathMatch: "full",
    component: LoginComponent,
    data: {
      label: "Login"
    }
  },
  {
    path: "resetPassword",
    pathMatch: "full",
    component: ResetPasswordComponent,
    data: {
      label: "Login"
    }
  },
  {
    path: "home",
    pathMatch: "full",
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      label: "Dartboard"
    }
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./core/admin/admin.module").then(m => m.AdminModule),
    canActivate: [AdminGuard],
    data: {
      label: "Settings"
    }
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./customer/customer.module").then(m => m.CustomerModule),
    data: {
      label: "Customer"
    }
  },
  {
    path: "jobs",
    redirectTo: "dartboard"
  },
  {
    path: "dartboard",
    loadChildren: () => import("./core/dartboard/dartboard.module").then(m => m.DartboardModule),
    data: {
      label: "Jobs"
    }
  },
  {
    path: "calendar",
    loadChildren: () => import("./core/calendar/calendar.module").then(m => m.CalendarModule),
    canActivate: [AuthGuard],
    data: {
      label: "Calendar"
    }
  },
    {
    path: "proposals",
    loadChildren: () => import("./proposal/proposal.module").then(m => m.ProposalModule),
    data: {
      label: "Proposals"
    }
  },
  {
    path: "ui",
    pathMatch: "full",
    component: UiComponent,
  },
  {
    path: 'thanks',
    pathMatch: 'full',
    component: ThanksComponent
  },
  {
    path: "actionLink",
    pathMatch: "full",
    component: ActionLinkComponent
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "login"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
