import { Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from "@shared/services/admin-pricing.service";

@Pipe({
  name: 'getPR'
})
export class GetPRPipe implements PipeTransform {
  constructor(
    private adminPricingService: AdminPricingService ) {
  }

  transform(recommendedPrice:number, e:number, m:number, h:number): number {

    const PR = this.adminPricingService.profitRate / 100;
    return recommendedPrice * PR
    
  }

}
