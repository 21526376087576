import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-job-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss']
})
export class TotalsComponent implements OnInit {
  
  @Input() jobs: any[];
  @Input() numberSold: number;
  @Input() numberLost: number;
  @Input() numberPending: number;

  constructor() { }

  ngOnInit(): void {
  }


}
