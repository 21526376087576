import { NgModule } from '@angular/core';

import { GetInitialsFromNamePipe } from './initials.pipe';
import { GetOptionDateFromIDPipe } from './option-data-from-id.pipe';
import { IsInArray } from './option-enhancement-check.pipe';
import { ClassesJoinTrimPipe } from './classes-join-trim.pipe';
import { UserFromIdPipe } from './user-from-id.pipe';
import { CashPricePipe } from './pricing/cash-price.pipe';
import { CreditPricePipe } from './pricing/credit-price.pipe';
import { DepositDiscrepancyFeesPipe } from './pricing/deposit-check.pipe';
import { AddCreditFeesPipe } from './pricing/credit-fees.pipe';
import { AddCreditFeesWithTaxes } from './pricing/credit-fees-with-taxes.pipe';
import { RemoveCreditFeesPipe } from './pricing/remove-credit-fees.pipe';
import { CashDiscountPricePipe } from './pricing/cash-discount.pipe';
import { AddTaxPipe } from './pricing/add-tax.pipe';
import { GetOrderFromPricePipe } from './order-from-price.pipe';
import { TypeofPipe } from './type-of.pipe';
import { CutsheetSectionOrderPipe } from './cutsheet-get-section-order.pipe';
import { GetBrandLogoPipe } from './get-brand-logo.pipe';
import { GetFinalPrice } from './pricing/display-price.pipe';
import { GetDisplayPriceFromBaseAndSettings } from './pricing/display-price-from-settings.pipe';
import { GetDisplayPriceFromPsis } from './pricing/display-price-from-psis.pipe';
import { GetPRPipe } from './pricing/profit-from-dc.pipe';
import { GetRecommendedItemPrice } from './pricing/enhancement-price';
import { IsOptionAddedToDiscount } from './promo-option-enables-check.pipe';
import { IsTonnageExcludedFromOption } from './promo-tonnage-excluded-from-option-check.pipe';

@NgModule({
  declarations:[
    GetInitialsFromNamePipe,
    GetBrandLogoPipe,
    GetOptionDateFromIDPipe,
    IsInArray,
    ClassesJoinTrimPipe,
    UserFromIdPipe,
    CashPricePipe,
    CreditPricePipe,
    DepositDiscrepancyFeesPipe,
    AddCreditFeesPipe,
    AddCreditFeesWithTaxes,
    AddTaxPipe,
    RemoveCreditFeesPipe,
    CashDiscountPricePipe,
    GetOrderFromPricePipe,
    TypeofPipe,
    CutsheetSectionOrderPipe,
    GetFinalPrice,
    GetDisplayPriceFromBaseAndSettings,
    GetDisplayPriceFromPsis,
    GetPRPipe,
    GetRecommendedItemPrice,
    IsOptionAddedToDiscount,
    IsTonnageExcludedFromOption
  ],
  imports:[],
  exports:[
    GetInitialsFromNamePipe,
    GetOptionDateFromIDPipe,
    GetBrandLogoPipe,
    IsInArray,
    ClassesJoinTrimPipe,
    UserFromIdPipe,
    CashPricePipe,
    CreditPricePipe,
    DepositDiscrepancyFeesPipe,
    AddCreditFeesPipe,
    AddCreditFeesWithTaxes,
    AddTaxPipe,
    RemoveCreditFeesPipe,
    CashDiscountPricePipe,
    GetOrderFromPricePipe,
    TypeofPipe,
    CutsheetSectionOrderPipe,
    GetFinalPrice,
    GetDisplayPriceFromBaseAndSettings,
    GetDisplayPriceFromPsis,
    GetPRPipe,
    GetRecommendedItemPrice,
    IsOptionAddedToDiscount,
    IsTonnageExcludedFromOption
  ],
  providers: [
    GetInitialsFromNamePipe,
    GetOptionDateFromIDPipe
  ]
})

export class PipesModule { }
