import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'isInArray'
})

export class IsInArray implements PipeTransform {

  transform(object: any, arrayToCheck: any[], objectField? : string): boolean {

    if (!arrayToCheck) return false

    if (!objectField) {

      if(arrayToCheck.some(i => i == object)) {
        return true
      } else {
        return false
      }
    } else {

      if(arrayToCheck.some(i => i[objectField] == object[objectField])) {
        return true
      } else {
        return false
      }
    }


  }

}
