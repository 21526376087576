import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-link',
  templateUrl: './action-link.component.html',
  styleUrls: ['./action-link.component.scss']
})
export class ActionLinkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    	const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const client = urlParams.get("client");
			const protocol = "https://";
			const tld = ".dartbid.com/__/auth/action";
			const newUrl = protocol + client + tld + queryString;
			window.location.href = newUrl;
  }

}
