import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { JobsService } from "@shared/services/jobs.service";
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/user.service';
import { AuthService } from '@shared/services/auth.service';
import { Subscription, of } from "rxjs";
import { shareReplay, take } from 'rxjs/operators';
import { DateCalculationsService } from '@shared/services/date-calculations.service';
import { DatePipe } from '@angular/common';
import { FirestoreService } from '@shared/services/firestore.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  host: {
    class: 'page-content-main'
  }
})
export class TableComponent implements OnInit, OnDestroy {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  jobSub: Subscription;

  isConfirmDelete: boolean = false;
  jobToDelete;

  loading: boolean = true;
  updatingTable: boolean = true;

  allJobs = [];
  dateRangeJobs = [];
  displayedJobs = [];

  recentJobs = [];
  monthsJobs = [];
  yearsJobs = [];

  columns = [
    { name: 'index' }, 
    { prop: 'sent' }, 
    { name: 'status' }, 
    { name: 'price', sortable: true }
  ];

  temp = [];
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  // Filters
  thresholdForRecentDays = 14;
  filteredCustomer = '';
  filteredStatus = '';
  filteredConsultant = '';
  filteredDateStart;
  filteredDateEnd;
  filteredDatePreset = 'all'
  today;
  currentDates = {
    'today' : '',
    'year' : '',
    'month' : '',
    'day' : '',
  }

  isNotesOpen: boolean = false;
  selectedJob;

  constructor(
    public jobsService : JobsService,
    public userService : UserService,
    public authService : AuthService,
    private router : Router,
    private dateCalculationsService : DateCalculationsService,
    private datePipe : DatePipe,
    private database: FirestoreService
  ) {

    let todayFormatted = this.datePipe.transform(new Date(),"yyyy-MM-dd");
    this.today = todayFormatted;
    this.currentDates.month = this.datePipe.transform(todayFormatted, "MM")
    this.currentDates.year = this.datePipe.transform(todayFormatted, "yyyy")
    this.currentDates.day = this.datePipe.transform(todayFormatted, "dd")
    this.filteredDateEnd = todayFormatted;

    this.jobSub = this.jobsService.viewableJobsCondensed$.pipe(
      shareReplay({bufferSize: 1, refCount: true})
    ).subscribe(
      jobs => {
        this.allJobs = [...jobs];
        this.updatingTable = false; 
        this.loading = false;
        this.filterDisplayedJobsByDateRange(this.filteredDatePreset)
      }
    ), shareReplay({bufferSize: 1, refCount: true})
  }

  ngOnInit(): void {
  }

  removeSelectedJob() {
    this.selectedJob = null
  }

   toggleIsConfirmDelete(state?:boolean) {
    if(state) {
      this.isConfirmDelete = state
    } else {
      this.isConfirmDelete = !this.isConfirmDelete
    }
  }

  confirmDeleteJob(job) {
    this.toggleIsConfirmDelete(true)
    this.selectedJob = job
  }

  async deleteJob(job) {
    const id = job?.meta?.id;
    if (!id) return
    this.database.doc$(`jobs/${id}`).pipe(take(1)).subscribe( async(fullJobData) => {
      try {
        // Add job to other archivedJobs array
        await this.database.doc(`archivedJobs/${id}`).set(fullJobData);
        // Delete the job from the "active" jobs
        await this.database.doc(`jobs/${id}`).delete();
      } catch (err) {
        console.log(err)
      }
    })
    this.selectedJob = null;
    this.toggleIsConfirmDelete(false)
  }

  onSelect($event) {
    this.router.navigateByUrl(`/dartboard/all/${$event.selected[0].id}`)
  }

  onOpenJob(id) {
    this.router.navigateByUrl(`/dartboard/all/${id}`)
  }

  openNotes(value) {
    this.selectedJob = value;
    this.isNotesOpen = true;
  }

  closeNotes() {
    this.isNotesOpen = false
    this.selectedJob = null;
  }

  saveNotes() {
    this.selectedJob = null;
  }

  getNotesDone($event) {
    this.closeNotes()
  }

  resetDateRange() {
    this.filteredDateEnd = this.today
    this.filteredDateStart = '';
    this.dateRangeJobs = this.allJobs;
    this.applyTableColumnFilters()
  }

  async filterDisplayedJobsByDateRange(setting?) {

    if (setting) {
      this.filteredDatePreset = setting
    }
    this.updatingTable = true;
    
    switch (this.filteredDatePreset) {
      case 'all' : {
        await this.resetDateRange()
        this.updatingTable = false; 
        break
      }
      case 'recent' : {
        await this.getJobsRecent()
        this.updatingTable = false;
        break
      }
      case 'month' : {
        await this.getJobsLast30()
        this.updatingTable = false;
        break
      }
      case 'year' : {
        await this.getJobsInYear()
        this.updatingTable = false;
        break
      }
      case 'range' : {
        this.filteredDateEnd = this.today
        this.filteredDateStart = '';
        this.updatingTable = false;
        break
        
      }
    }
  }

  async getJobsInDateCurrentFilterRange() {
    let jobsFilteredByDate = this.allJobs.filter((job : any) => {
      let createdFormatted = job?.psis?.meta?.createdAt ? this.datePipe.transform(job.psis.meta.createdAt,"yyyy-MM-dd") : this.datePipe.transform(job.meta.created,"yyyy-MM-dd");
      return this.dateCalculationsService.isDateWithinRange(createdFormatted, this.filteredDateStart, this.filteredDateEnd)
    })
    this.dateRangeJobs = jobsFilteredByDate;
    this.applyTableColumnFilters()
  }

  async getJobsRecent() {
    if (this.recentJobs.length) {
      this.dateRangeJobs = this.recentJobs;
      this.applyTableColumnFilters()
    } else {
      let d = new Date()
      let stamp = d.setDate(d.getDate()- this.thresholdForRecentDays)
      let stampFormatted = this.datePipe.transform(stamp,"yyyy-MM-dd");
      this.filteredDateStart = stampFormatted
      await this.getJobsInDateCurrentFilterRange();
      this.recentJobs = this.dateRangeJobs
    }
  }

  async getJobsLast30() {
    if (this.monthsJobs.length) {
      this.dateRangeJobs = this.monthsJobs;
      this.applyTableColumnFilters()
    } else {
      let d = new Date()
      let stamp = d.setDate(d.getDate()- 30)
      let stampFormatted = this.datePipe.transform(stamp,"yyyy-MM-dd");
      this.filteredDateStart = stampFormatted
      await this.getJobsInDateCurrentFilterRange();
      this.monthsJobs = this.dateRangeJobs
    }
  }

  async getJobsInYear() {
    if (this.yearsJobs.length) {
      this.dateRangeJobs = this.yearsJobs;
      this.applyTableColumnFilters()
    } else {
      this.filteredDateStart = `${this.currentDates.year}-01-01`
      this.filteredDateEnd = `${this.currentDates.year}-12-31`
      await this.getJobsInDateCurrentFilterRange();
      this.yearsJobs = this.dateRangeJobs
    }
  }

  async applyTableColumnFilters() {
    this.updatingTable = true;

    const customer = this.filteredCustomer.toLowerCase();
    const consultant = this.filteredConsultant;
    const status = this.filteredStatus.toLowerCase();

    const updatedFilteredJobs = this.dateRangeJobs.filter(
      job => {

        let customerName = job.info.lastName + ', ' + job.info.firstName;
        
        if (customer && consultant && status) {
         return job.meta.createdBy == consultant && job.status.toLowerCase() == status && customerName.toLowerCase().indexOf(customer) !== -1 || !customer
        }

        // All empty, we reset back to unfiltered
        else if (!customer && !consultant && !status) {
          return this.allJobs
        }

        // Two Fields
        else if (customer && consultant && !status) {
          return job.meta.createdBy == consultant && customerName.toLowerCase().indexOf(customer) !== -1 || !customer
        } else if (customer && !consultant && status) {
          return job.status.toLowerCase() == status && customerName.toLowerCase().indexOf(customer) !== -1 || !customer
        } else if (!customer && consultant && status) {
          return job.meta.createdBy == consultant && job.status.toLowerCase() == status
        }

        // One Field only searching
        else if (customer && !consultant && !status) {
          return customerName.toLowerCase().indexOf(customer) !== -1 || !customer
        }
        else if (consultant && !customer && !status) {
          return job.meta.createdBy == consultant
        }
        else if (status && !customer && !consultant) {
          return job.status.toLowerCase() == status
        }

      }
    );

    this.displayedJobs = [...updatedFilteredJobs];
    this.updatingTable = false;

  }

  resetFilters() {
    this.updatingTable = true;
    this.displayedJobs = this.allJobs;
    this.dateRangeJobs = this.allJobs;
    
    this.filteredCustomer = '';
    this.filteredStatus = '';
    this.filteredConsultant = '';
    this.filteredDateStart = '';
    this.filteredDatePreset = 'all';

    this.updatingTable = false;
    this.filteredConsultant = '';
    this.filterDisplayedJobsByDateRange(this.filteredDatePreset)
  }

  ngOnDestroy() {
    this.jobSub?.unsubscribe()
  }

}
