import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-proposal-choices-enhancements',
  templateUrl: './proposal-choices-enhancements.component.html',
  styleUrls: ['./proposal-choices-enhancements.component.scss']
})
export class ProposalChoicesEnhancementsComponent implements OnInit {

  @Input() job;
  @Input() currentlySelectedSystem;
  @Input() currentlySelectedEnhancements;
  @Output() selectedEnhancement = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  selectEnhancement(e) {
    this.selectedEnhancement.emit(e)
  }

}
