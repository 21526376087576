import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin.component';
import { BrandingComponent } from './branding/branding.component';
import { PricingComponent } from './pricing/pricing.component';
import { SurveysComponent } from './surveys/surveys.component';
import { UsersComponent } from './users/users.component';
import { ProductsComponent } from './products/products.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { PromotionsComponent } from "./promotions/promotions.component";
import { FilesComponent } from './files/files.component';
import { ArchivesComponent } from './archives/archives.component';
import { FeaturesComponent } from './features/features.component';
import { TemplatesComponent } from './templates/templates.component';
import { CutsheetComponent } from './cutsheet/cutsheet.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'users'
      },
      {
        path: 'branding',
        pathMatch: 'full',
        component: BrandingComponent
      },
      {
        path: 'addOns',
        pathMatch: 'full',
        component: AddOnsComponent
      },
      {
        path: 'add-ons',
        pathMatch: 'full',
        redirectTo: 'addOns'
      },
      {
        path: 'promotions',
        pathMatch: 'full',
        component: PromotionsComponent
      },
      {
        path: 'features',
        pathMatch: 'full',
        component: FeaturesComponent
      },
      {
        path: 'pricing',
        pathMatch: 'full',
        component: PricingComponent
      },
      {
        path: 'surveys',
        pathMatch: 'full',
        component: SurveysComponent
      },
      {
        path: "templates",
        loadChildren: () =>
          import("./templates/templates.module").then(m => m.TemplatesModule)
      },
      {
        path: 'products',
        pathMatch: 'full',
        component: ProductsComponent
      },
      {
        path: 'users',
        pathMatch: 'full',
        component: UsersComponent
      },
      {
        path: 'files',
        pathMatch: 'full',
        component: FilesComponent
      },
      {
        path: 'users/:uid',
        pathMatch: 'full',
        component: UsersComponent,
      },
      {
        path: 'archives',
        pathMatch: 'full',
        component: ArchivesComponent
      },
      {
        path: 'cutsheet',
        pathMatch: 'full',
        component: CutsheetComponent,
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
