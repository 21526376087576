import { Component, OnInit, OnDestroy  } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdminPricingService, FinancingOption } from '@shared/services/admin-pricing.service'
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { take } from "rxjs/operators";
import { QueueToggleService } from '@shared/services/queue-toggle.service';
import { Subscription } from "rxjs";

import { TemplatePricingUpdates } from '@shared/services/template-pricing-updates.service'
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {

  pricingSub: Subscription;
  pricingDataRef;

  activeView: string = 'Internal';

  loading: boolean = true;
  success: boolean = false;
  confirm: boolean = false;

  isPricingToolOpen: boolean = false;

  confirmDeleteFinancingOption: boolean = false;
  confirmDeleteFinancingOptionIndex : number;

  accountingForm: FormGroup;

  taxRate : number;
  commission : number;
  laborRate : number;
  overhead : number;
  creditPriceAdminCosts : number;
  depositPercentage : number;
  e : number = 0;
  m : number = 0;
  h : number = 0;
  rp : number = 0;

  constructor(
    private formBuilder: FormBuilder,
    public afs: AngularFirestore,
    public queueToggleService: QueueToggleService,
    public templatePricingUpdates : TemplatePricingUpdates,
    public adminPricingService : AdminPricingService,
    public authService : AuthService
    ) {
    this.accountingForm = this.formBuilder.group({
        taxRate: [ '', Validators.required ],
        proposalSalesTax: [ '', Validators.required ],
        commissionRate: [ '', Validators.required ],
        profitRate: [ '', Validators.required ],
        overheadRate: [ '', Validators.required ],
        laborRate: [ '', Validators.required ],
        pricingFormula: [ 0 ],
        overhead: [ {value:'', disabled: true}, Validators.required ],
        creditPriceAdminCosts: [ '', Validators.required ],
        creditFeeSetting : this.formBuilder.group({
          applyAs : [ 2, Validators.required ],
          percentage : [ 1.5 , Validators.required ]
        }),
        depositPercentage: [ '', Validators.required ],
        financingOptions : this.formBuilder.array([]),
        financingApplicationURL: [ '', Validators.required ],
        proposalGoodForDays: [ 14, Validators.required ],
        proposalHidePriceOnOption: [ false, Validators.required ],
        proposalDefaultPaymentMethod: [ 'Credit', Validators.required ],
      });
  }

  ngOnInit() {
    this.pricingSub = this.adminPricingService.accounting$.pipe(take(1))
    .subscribe(data => {
      this.pricingDataRef = data,
      this.accountingForm.patchValue(data),
      data.financingOptions.map( option => {
          this.financingOptions.push(
            new FormGroup({
              name: new FormControl( option['name'] ),
              months: new FormControl( option['months'] ),
              apr: new FormControl( option['apr'] ),
              fee: new FormControl( option['fee']),
              min: new FormControl( option['min'] ),
              terms: new FormControl( option['terms'] )
            })
          ),
          this.loading = false
        }
      )
    }
  );
  }

  setActiveView(view:string) {
    this.activeView = view;
    this.queueToggleService.closeQueueIfMobile()
  }

  selectPricingModel(option) {
    this.accountingForm.markAsDirty()
    this.accountingForm.patchValue(
      {
        pricingFormula: option
      }
    )
  }

  selectCreditModel(option) {
    this.accountingForm.markAsDirty()
    this.accountingForm.get('creditFeeSetting').patchValue(
      {
        applyAs: option
      }
    )
  }

  get financingOptions() {
    return this.accountingForm.get("financingOptions") as FormArray;
  }

  addFinancingOption() {
    const newfinancingOption = new FormGroup({
      name: new FormControl( null, Validators.required ),
      months: new FormControl( null, Validators.required ),
      apr: new FormControl( null, Validators.required ),
      fee: new FormControl( this.pricingDataRef.creditPriceAdminCosts, [Validators.required, Validators.max(this.accountingForm.value.creditPriceAdminCosts)] ),
      min: new FormControl( null, Validators.required ),
      terms: new FormControl( null )
    });
    this.financingOptions.push(newfinancingOption)
    this.accountingForm.markAsDirty()
  }

  removeFinancingOption(i:number) {
    this.financingOptions.removeAt(i)
    this.accountingForm.markAsDirty();
    this.onCancelConfirmDeleteFinancingOption();
    this.submitHandler()
  }

  onConfirm() {
    this.confirm = true;
  }

  onConfirmDeleteFinancingOption(i) {
    this.confirmDeleteFinancingOption = true;
    this.confirmDeleteFinancingOptionIndex = i;
  }

  onCancelConfirmDeleteFinancingOption() {
    this.confirmDeleteFinancingOption = false;
    this.confirmDeleteFinancingOptionIndex = null;
  }

  onCancelConfirm() {
    this.confirm = false;
  }

  showSuccessMessage() {
    this.success = true;
    setTimeout(()=>{
      this.success = false;
    }, 3000);
  }

  onSubmit() {
    this.submitHandler()
  }

  async submitHandler() {
    this.confirm = false;
    this.loading = true;
    const formValue = this.accountingForm.value;
    try {
      await this.adminPricingService.accountingDoc.update(formValue)
      this.loading = false;
      this.showSuccessMessage();
    } catch(err) {
      console.log(err)
    }
    this.loading = false;
    this.accountingForm.markAsPristine()
  }

  togglePricingTool() {
    this.isPricingToolOpen = !this.isPricingToolOpen
  }

  ngOnDestroy() {
    this.pricingSub.unsubscribe();
  }

}
