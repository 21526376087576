
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AdminPricingService } from '@shared/services/admin-pricing.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'getRecommendedItemPrice'
})
export class GetRecommendedItemPrice implements PipeTransform, OnDestroy {
  pricingSub: Subscription;
  formula;
  constructor( private adminPricingService: AdminPricingService ) { 

    this.pricingSub = this.adminPricingService.accounting$.subscribe( data => {
      this.formula = data.pricingFormula
    })
  }

  ngOnDestroy(): void {
    this.pricingSub?.unsubscribe()
  }

  transform( preTaxCost : number, hoursToInstall : any, desiredProfit : number) {
    if( !preTaxCost ) return 0
    if (this.formula == 1) {
      return this.adminPricingService.getRecommendedItemPriceFormula1(preTaxCost,hoursToInstall,desiredProfit)
    } else if (this.formula == 0) {
return this.adminPricingService.getRecommendedItemPriceFormula0(preTaxCost,hoursToInstall,desiredProfit)
    } else if (this.formula == 2) {
      return this.adminPricingService.getRecommendedItemPriceFormula2(preTaxCost,hoursToInstall,desiredProfit)
    }
  }


}
