import { Component, OnInit, Input , OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-financing-deposits',
  templateUrl: './deposits.component.html',
  styleUrls: ['./deposits.component.scss']
})
export class DepositsComponent implements OnInit, OnChanges {

  @Input() job;
  depositsAdjustedForFinancing: number;
  loading: boolean = true;

  constructor() {
  }

  ngOnInit(): void {
    this.setUp();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setUp();
  }

  async setUp() {
    const depositsAdjustedForFinancing = await this.job.deposits.reduce(
      (total : number, deposit) => {
        if (deposit.type == 'cash') {
          let adjustedAmount = deposit.amount + deposit.cr;
          return total += adjustedAmount;
        } else {
          return total += deposit.amount;
        }
      }, 0
    );
    await depositsAdjustedForFinancing;
    this.depositsAdjustedForFinancing = depositsAdjustedForFinancing;
    this.loading = false;
  }

}
